<template>
  <div class="payment-form-item">
    <BaseFormItem
      ref="baseFieldRef"
      v-model.trim="fieldValue"
      inputmode="numeric"
      placeholder="000"
      :label="label"
      :maxlength="cvvMaxLenth"
      :show-tips="true"
      :required="isRequired"
      @focus="handleFocus"
      @change="handleValueInput"
      @blur="handleValueBlur"
      @click-tips="emits('click-tips')"
    />
    <div
      v-show="errorTips"
      class="error-invalid"
    >
      {{ errorTips }}
    </div>
  </div>
</template>

<script setup name="CvvFormItem" lang="ts">
import { ref, computed } from 'vue'
import BaseFormItem from './BaseFormItem.vue'
import { CARD_TYPE_ENUM } from '../utils/constants'
import { cvvValidator } from '../utils/validator'

interface Props {
  modelValue: string; // 值
  errorMessage?: string; // 错误提示信息
  required?: boolean; // 是否必填
  label?: string; // 标签文本
  placeholder?: string; // 输入框占位文本
  clearable?: boolean; // 是否显示清除按钮
  formatter?: (value: string) => string; // 指定输入值格式化函数
  validator?: (value: string) => boolean; // 自定义校验规则
  cardType?: string; // 卡类型
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  cardType: '', // cpf规则
  paymentCode: '',
})
const emits = defineEmits(['click-tips', 'update:modelValue'])

const baseFieldRef = ref()
const fieldValue = ref('')
const errorTips = ref('')

const lowerCardType = computed(() => props.cardType?.toLowerCase?.() || '')

const cvvMaxLenth = computed(() => !fieldValue.value || lowerCardType.value === CARD_TYPE_ENUM.AMEX ? 4 : 3)
const isRequired = computed(() => !!cvvValidator({ cardType: lowerCardType.value, cvv: '' }))

const handleValueInput = ev => {
  let txt = ev.target.value
  //格式化
  let num = txt.replace(/\s/g, '') || ''
  fieldValue.value = ev.target.value = num
  emits('update:modelValue', num)
}

const handleFocus = () => {
  errorTips.value = ''
}

const handleValueBlur = () => {
  validator()
  emits('update:modelValue', fieldValue.value)
}

const validator = () => {
  const cvv = fieldValue.value?.replace?.(/\s/g, '') || ''
  const valid = cvvValidator({
    cvv: cvv,
    cardType: lowerCardType.value,
  })
  // errorTips.value = !valid ? '' : props.language?.BS_KEY_PAY_1143
  errorTips.value = !valid ? '' : props.errorMessage || ''
  return {
    result: !valid,
    failType: valid,
    failReason: null,
    reasonType: 'cvv',
  }
}

const reset = () => {
  fieldValue.value = ''
  errorTips.value = ''
  emits('update:modelValue', '')
}

defineExpose({
  validator,
  reset,
  focus: () => baseFieldRef.value.focus(),
})

</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
  font-size: @font-size * 0.5;
}

.payment-form-item {
  padding: 24/75rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24/75rem;
    right: 0;
    height: 1px;
    background-color: var(--sui_color_gray_light2, #e5e5e5);
  }
  &.no-underline {
    &:after {
      display: none;
    }
  }
  .error-invalid {
    font-size: 12px;
    line-height: 1;
    color: var(--sui_color_promo, #FA6338);
    margin-top: 8/75rem;
    transition: height 0.8s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
