<template>
  <!-- APPLE pay 二次确认弹窗 -->
  <SDialog
    :visible="show"
    :append-to-body="true"
    :show-close="true"
    :close-on-click-modal="false"
    @close-from-icon="cancelApplePay"
  >
    <div
      class="apple-pay-c"
      style="margin: 0 auto;"
    >
      <img
        src="https://img.ltwebstatic.com/images3_ccc/2024/08/27/dd/17247267129483c43e1f677c962a8b2363e51cdb2a.png"
        style="height: 100%"
      />
    </div>
    <template
      #footer
    >
      <button
        v-enterkey
        class="applepaybtn"
        @click="toApplePay"
      ></button>
    </template>
  </SDialog>
</template>

<script name="ApplePayDialog" setup lang="ts">
import { SDialog } from '@shein-aidc/sui-dialog/mobile'

let applePayTwiceFlag = false

interface IApplePayDialogProps {
  show: boolean
}

withDefaults(defineProps<IApplePayDialogProps>(), {
  show: false,
})

const emits = defineEmits(['close', 'start-pay'])

const cancelApplePay = () => {
  emits('close')
}

const toApplePay = () => {
  if (applePayTwiceFlag) return
  applePayTwiceFlag = true
  setTimeout(() => {
    applePayTwiceFlag = false
  }, 800)
  emits('start-pay')
}
</script>

<style scoped lang="less">
.apple-pay-c{
	width: 2.04rem;
	margin: .48rem auto .52rem;
	height: 1.27rem;
}
.applepaybtn{
	background: #000;
	color: #fff;
	background-image: url(https://img.ltwebstatic.com/images3_ccc/2025/01/21/d8/17374432870c2ffc080665926f943a072c9a1f63d1.png);
	background-repeat: no-repeat;
	width: 5.1rem;
	height: 0.96rem;
	border: 0;
	background-position: center;
	background-size: 1.4rem;
	margin: 0 auto;
	display: block;
}
</style>

