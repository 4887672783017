import { type PrePayStore } from './usePrePay'
import { debuggerLog } from '../utils'
import useApis from './useApis'
import { template } from '@shein/common-function'
import { usePaymentsStore, type PaymentsStore } from './usePayments'
import { useAppConfigs, type Libs } from '@shein-aidc/bs-sdk-libs-manager'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { computed } from 'vue'


const getOrderCardBinDiscountInfo = ({ paymentsStore, originCardBinDiscountInfo, cardBin, project }: { paymentsStore: PaymentsStore; originCardBinDiscountInfo: Record<string, any>; cardBin: string; project: Libs.Envs['project'] } ): Trade_PayToolKit.PaymentInfoItem['binDiscountCardToken'] => {
  const getTokenRandomBinDiscountDesc = () => {
    const {
      max_random_amount = {},
      min_random_amount = {},
      min_amount = {},
      issuer_bank_name = '',
    } = originCardBinDiscountInfo || {}

    if (min_amount?.amount > 0) {
      if (issuer_bank_name?.length > 0) {
        return template(
          min_random_amount?.amountWithSymbol,
          max_random_amount?.amountWithSymbol,
          min_amount?.amountWithSymbol,
          issuer_bank_name,
          project === 'pwa' ? paymentsStore.language.BS_KEY_PAY_1049 : paymentsStore.language.SHEIN_KEY_PC_28783 || '',
        )
      }

      return template(
        min_random_amount?.amountWithSymbol,
        max_random_amount?.amountWithSymbol,
        min_amount?.amountWithSymbol,
        project === 'pwa' ? paymentsStore.language.BS_KEY_PAY_1023 : paymentsStore.language.SHEIN_KEY_PC_26181 || '',
      )
    }

    if (issuer_bank_name?.length > 0) {
      return template(
        min_random_amount?.amountWithSymbol,
        max_random_amount?.amountWithSymbol,
        issuer_bank_name,
        project === 'pwa' ? paymentsStore.language.BS_KEY_PAY_1048 : paymentsStore.language.SHEIN_KEY_PC_28784 || '',
      )
    }

    return template(
      min_random_amount?.amountWithSymbol,
      max_random_amount?.amountWithSymbol,
      project === 'pwa' ? paymentsStore.language.BS_KEY_PAY_1022 : paymentsStore.language.SHEIN_KEY_PC_26118 || '',
    )
  }

  const getCardBinDiscountDesc = () => {
    const { total_discount_amount, type = 0 } = originCardBinDiscountInfo || {}

    // 优惠类型 1：满减 2：折扣 3 随机立减
    if ([1, 2].includes(type) && total_discount_amount?.amount > 0) {
      return template(
        total_discount_amount?.amountWithSymbol,
        project === 'pwa' ? paymentsStore.language.BS_KEY_PAY_1108 : paymentsStore.language.SHEIN_KEY_PC_25787 || '',
      )
    }

    if (type === 3) {
      return getTokenRandomBinDiscountDesc()
    }
  }

  return {
    binDiscountTip: getCardBinDiscountDesc() || '',
    is_selected: '1',
    cardBin,
    type: originCardBinDiscountInfo?.type,
  }
}

export const usePrePayRearOrderFlow = ({ prePayStore }: { prePayStore: PrePayStore }) => {
  const { getOrderDetailApi, queryUnPayPreRoutingInfoApi } = useApis()
  const paymentsStore = usePaymentsStore()
  const appConfigs = useAppConfigs()
  const project = computed(() => appConfigs.$envs.project)

  const handleQueryUnPayPreRoutingInfo = async ({
    bin = '',
    billno = '',
    routeCode = '',
    orderCurrency = '',
    countryCode = '',
    paymentMethod = '',
    queryCardBin = 1,
  } = {}) => {
    // const queryCardInstallment = routeCode === 'routepay-cardinstallment' ? 1 : 0
    const queryCardInstallment = 0
    return queryUnPayPreRoutingInfoApi({
      bin,
      billno,
      routeCode,
      orderCurrency,
      countryCode,
      paymentMethod,
      queryCardBin,
      queryCardInstallment,
    }).then(data => {
      return {
        cardBinDiscountInfo: data?.cardBinDiscountInfo || {},
        installments: data?.installments || [],
        routingInfo: data?.info || {},
      }
    })
  }

  // 转换成单后的卡bin信息数据格式为成单前的
  const changeCardBinDiscountInfoData = (bin = '', cardBinDiscountInfo = {} as any) => {
    let originCardBinDiscountInfo = {}
    if (Object.keys(cardBinDiscountInfo).length > 0 && cardBinDiscountInfo?.card_bin_discount_info?.discount_type != null) {
      originCardBinDiscountInfo = {
        card_bin: bin,
        max_amount: cardBinDiscountInfo?.card_bin_discount_info?.max_price,
        min_amount: cardBinDiscountInfo?.card_bin_discount_info?.min_price,
        min_random_amount: cardBinDiscountInfo?.card_bin_discount_info?.min_random_price,
        max_random_amount: cardBinDiscountInfo?.card_bin_discount_info?.max_random_price,
        total_discount_amount: cardBinDiscountInfo?.card_pin_total_discount_price,
        type: cardBinDiscountInfo?.card_bin_discount_info?.discount_type,
        issuer_bank_name: cardBinDiscountInfo?.card_bin_discount_info?.issuer_bank_name,
      }
      const orderCardBinDiscountInfo = getOrderCardBinDiscountInfo({
        paymentsStore,
        originCardBinDiscountInfo: originCardBinDiscountInfo,
        cardBin: bin,
        project: project.value,
      })
      debuggerLog('changeCardBinDiscountInfoData=== >>>>', originCardBinDiscountInfo, orderCardBinDiscountInfo)
      prePayStore.updateState('orderCardBinDiscountInfo', orderCardBinDiscountInfo)
    } else {
      prePayStore.updateState('orderCardBinDiscountInfo', {})
    }
  }

  const handlePrePayBinChangeForUnPay = async (bin: string) => {
    // const totalPrice = prePayStore.orderInfo.no_card_bin_payable_price?.amount > 0 ? prePayStore.orderInfo.no_card_bin_payable_price : prePayStore.orderInfo.totalPrice
    const [
      unPayRoutingInfoResult,
      installmentInfoResult,
    ] = await Promise.allSettled([
      handleQueryUnPayPreRoutingInfo({
        bin,
        routeCode: prePayStore.orderInfo.payment_method,
        paymentMethod: prePayStore.orderInfo.payment_method,
        orderCurrency: prePayStore.orderInfo.currency_code,
        countryCode: prePayStore.orderInfo.country_code || prePayStore.prePayUnPayParams?.countryCode,
        billno: prePayStore.prePayCheckoutInfo?.billno || '',
      }),
      prePayStore.getInstallmentInfo({
        cardBin: bin,
        cardType: prePayStore.prePayUnPayParams?.cardType,
        billno: prePayStore.orderInfo?.relation_billno || prePayStore.orderInfo?.billno || '',
        countryCode: prePayStore.orderInfo.country_code || prePayStore.prePayUnPayParams?.countryCode,
      }),
    ])

    if (unPayRoutingInfoResult.status === 'fulfilled' && unPayRoutingInfoResult.value) {
      prePayStore.updateState('cardBinRoutingInfo', unPayRoutingInfoResult.value.routingInfo || {})
      changeCardBinDiscountInfoData(prePayStore.cardBinRoutingInfo?.bin, unPayRoutingInfoResult.value.cardBinDiscountInfo)
    }
    if (installmentInfoResult.status === 'fulfilled' && installmentInfoResult.value?.installments) {
      prePayStore.updateState('installmentList', installmentInfoResult.value?.installments || [])
      prePayStore.updateState('initInstallmentList', installmentInfoResult.value?.installments || [])
    }

    prePayStore.mergeState('prePayUnPayParams', {
      cardBin: prePayStore.cardBinRoutingInfo?.bin,
      routeId: prePayStore.cardBinRoutingInfo?.routeId,
      paymentCode: prePayStore.cardBinRoutingInfo?.payMethod,
      countryCode: prePayStore.cardBinRoutingInfo?.orderCountry || prePayStore.orderInfo.country_code,
      cardType: prePayStore.cardBinRoutingInfo?.cardType,
    })
  }

  const handlePrePayRearOrderFlow = async (payload: { billno: string }) => {
    prePayStore.mergeState('prePayCheckoutInfo', { billno: payload.billno })
    const { info: orderInfo } = await getOrderDetailApi(payload)
    debuggerLog('startRearOrderFlow===res===', orderInfo)
    prePayStore.updateState('orderInfo', orderInfo)

    const bin = prePayStore.prePayUnPayParams?.cardBin?.substring?.(0, 8) || ''
    handlePrePayBinChangeForUnPay(bin)
  }

  // 注册事件到store
  prePayStore.updateState('handlePrePayBinChangeForUnPay', handlePrePayBinChangeForUnPay)

  return {
    handlePrePayRearOrderFlow,
  }
}
