<template>
  <div class="payment-tags-wrapper">
    <span
      v-for="tag in tags"
      :key="`tag:${tag}`"
      class="payment-tag"
    >
      {{ tag }}
    </span>
  </div>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  tags?: string[]
}>(), {
  tags: () => [] as string[],
})

</script>

<style lang="less" scoped>
.payment-tag {
  padding: 2/75rem 8/75rem;
  font-family: SF Pro;
  font-size: 24/75rem;
  line-height: 28/75rem;
  color: #666;
  background-color: @sui_color_prompt_common;
  border-radius: 4/75rem;
}
</style>