<template>
  <SDialog
    :visible.sync="isShow"
    :append-to-body="true"
    class="src-request-time-out-dialog"
  >
    <div class="content">
      <div class="title"> {{ languageInfo.BS_KEY_SRC_1049 }} </div>
      <div class="tip"> {{ languageInfo.BS_KEY_SRC_1050 }} </div>
    </div>

    <template #footer>
      <SButton
        class="confirm-btn"
        :type="['primary', 'H72PX']"
        :width="'100%'"
        @click="handlerConfirm">
        {{ languageInfo.BS_KEY_SRC_1009 }}
      </SButton>
      <div class="logo-list">
        <SrcCardList />
      </div>
    </template>
  </SDialog>
</template>

<script setup lang="ts">
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { useGetLang } from '../../hooks/useGetLang'
import SrcCardList from '../src-card-list/index.vue'
import { ref, watch } from 'vue'

const { languageInfo } = useGetLang()

const props = defineProps({
  isShowDialog: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['closeDialog', 'handlerConfirm'])

const isShow = ref(false)

const closeDialog = () => {
  isShow.value = false
  emits('closeDialog', false)
}

const handlerConfirm = () => {
  closeDialog()
  emits('handlerConfirm')
}

watch(
  () => props.isShowDialog,
  val => {
    isShow.value = val
  },
)
</script>

<style lang="less">
.src-request-time-out-dialog {
  .sui-dialog__top-holder {
    height: 22*2/75rem;
    background-image: url('https://img.ltwebstatic.com/v4/p/ccc/2025/02/18/28/1739850952efb274b382cf5b847a8e681baf75436e.webp');
    background-size: 100% 100%;
    border-radius: 8px 8px 0 0;
  }
  .sui-dialog__body {
    background-image: url('https://img.ltwebstatic.com/v4/p/ccc/2025/02/18/16/173984966180eb80a564d11960daefaa2751a23cc3.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .content {
    .title {
      color: black;
      font-size: 16px;
      font-weight: 700;
      word-wrap: break-word;
      text-align: center;
      margin-bottom: 12 * 2/75rem;
    }

    .tip {
      color: black;
      font-size: 14px;
      font-weight: 400;
      word-wrap: break-word;
      text-align: center;
      margin-bottom: 8 * 2/75rem;
    }
  }

  .confirm-btn {
    margin-bottom: 12 * 2/75rem;
  }

  .logo-list {
    display: flex;
    justify-content: center;
  }
}
</style>
