<template>
  <SLazyMount>
    <SDialog
      class="cod-tip__dialog"
      :visible="showCodTipDialog"
      :show-close="true"
      append-to-body
      @open="handleDialogOpen"
      @close="handleDialogClose"
    >
      <img
        class="cod-tip-dialog__logo"
        :src="transformImg({ img: replaceUrlProtocol(descPopup.logo || '') })"
      />
      <div class="cod-tip-dialog__title">
        {{ descPopup.title }}
      </div>
      <div class="cod-tip-dialog__tips">
        <div
          v-for="(tip, index) in codTips"
          :key="tip.title"
          class="cod-tip-dialog__tip"
        >
          <div class="cod-tip-dialog__order">
            {{ index + 1 }}
          </div>
          <div class="cod-tip-dialog__desc">
            <p class="cod-tip-dialog__desc-title">
              {{ tip.title }}
            </p>
            <p
              v-for="desc in tip.desc"
              :key="desc"
              class="cod-tip-dialog__desc-content"
            >
              {{ desc }}
            </p>
          </div>
        </div>
      </div>
      <div class="cod-tip-dialog__btn">
        <SButton
          :type="['H44PX', 'primary']"
          @click="showCodTipDialog = false"
        >
          {{ descPopup.confirmText }}
        </SButton>
      </div>
    </SDialog>
  </SLazyMount>
</template>

<script name="CodTipsDialog" setup lang="ts">
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { transformImg } from '@shein/common-function'
import { replaceUrlProtocol } from '../../../../utils'
import { ref, computed, inject } from 'vue'
import type { AS_PayToolkit } from '../../../../types'
const { triggerNotice } = (inject('analysisInstance', null) || {}) as AS_PayToolkit.AnalysisInstance

interface CodTipsDialogProps {
  descPopup: {
    logo?: string
    title?: string
    contentList?: { subTitle?: string; content1?: string; content2?: string; }[]
    confirmText?: string
  }
}

const props = defineProps<CodTipsDialogProps>()

const showCodTipDialog = ref(false)

const codTips = computed(() => {
  return props.descPopup.contentList?.map?.((item) => {
    return {
      title: item.subTitle,
      desc: [item.content1, item.content2].filter(Boolean),
    }
  })
})

const openDialog = () => {
  showCodTipDialog.value = true
}

const handleDialogClose = () => {
  triggerNotice({
    id: 'click_cod_detail.comp_cod_tip',
  })
  showCodTipDialog.value = false
}

const handleDialogOpen = () => {
  triggerNotice({
    id: 'expose_cod_detail.comp_cod_tip',
  })
}

defineExpose({
  openDialog,
})
</script>

<style lang="less" scoped>
.cod-tip-dialog__logo {
  width: 45/37.5rem;
  height: 30/37.5rem;
  border-radius: 4/37.5rem;
  margin: 8/37.5rem auto;
  display: block;
}
.cod-tip-dialog__title {
  font-size: 14/37.5rem;
  font-weight: 700;
  margin: 12/37.5rem 0;
  line-height: 17/37.5rem;
  color: @sui_color_gray_dark1;
  text-align: center;
  font-family: SF UI Text;
}
.cod-tip-dialog__tip {
  display: flex;
  align-items: center;
  position: relative;
  font-family: SF UI Text;

  &:not(:first-child) {
    padding-top: 6/37.5rem;

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 1px;
      height: 50%;
      background: #000;
      left: 11/37.5rem;
      bottom: 50%;
      z-index: -1;
    }
  }

  &:not(:last-child) {
    padding-bottom: 6/37.5rem;

    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 1px;
      height: 50%;
      background: #000;
      left: 11/37.5rem;
      top: 50%;
      z-index: -1;
    }
  }
}
.cod-tip-dialog__order {
  width: 24/37.5rem;
  height: 24/37.5rem;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #000;
  font-size: 14/37.5rem;
  font-weight: 700;
  text-align: center;
  line-height: 24/37.5rem;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-right: 12/37.5rem;
}
.cod-tip-dialog__desc-title {
  font-size: 14/37.5rem;
  font-weight: 400;
  line-height: 17/37.5rem;
  color: @sui_color_gray_dark1;
  margin-bottom: 4/37.5rem;
}
.cod-tip-dialog__desc-content {
  font-size: 12/37.5rem;
  line-height: 14/37.5rem;
  font-weight: 400;
  color: @sui_color_gray_dark3;
}
.cod-tip-dialog__btn {
  margin-top: 28/37.5rem;
  display: flex;
  justify-content: center;
  padding-bottom: 20/37.5rem;
  width: 100%;

  button {
    width: 100%;
  }
}
</style>

