<template>
  <div class="payments-main-container">
    <PaymentItems
      :payments="payments"
      :language="language"
      :selected-payment="paymentState && paymentState.selectedPayment"
      :selected-token-info="selectedTokenInfo"
      :sign-up-info="editablePaymentInfo"
      :paymentItemsConfig="paymentItemsConfig"
      :c2p-info="c2pInfo"
      :payment-info="paymentInfo"
      :payment-config="paymentConfig"
      :checkout-info="checkoutInfo"
      :bsPaymentAction="bsPaymentAction"
      @editable-info-change="handleChangeEvt"
      @select="handleSelectEvt"
    />
  </div>
</template>

<script name="MainPayments" setup lang="ts">
import { computed } from 'vue'
import { usePaymentsStore } from '../../../hooks/usePayments'
import PaymentItems from '../payment-items/PaymentItems.vue'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import type { PaymentItemSelectType, PaymentItemsConfig } from '../../../types'
import { useEditablePaymentInfo } from '../../../hooks/useEditablePaymentInfo'
import { debuggerLog } from '../../../utils'
import type { PaymentItemProps } from '../../../types/props'

interface MainPaymentsProps {
  language: Record<string, string>;
  payments: Trade_PayToolKit.PaymentInfoItem[];
  paymentItemsConfig: PaymentItemsConfig;
  c2pInfo?: Record<string, any>;
  paymentInfo?: {
    fpxShowUpgradationBank?: string[];
    paySafeIconTip?: string;
    paySafeIcons?: string[];
    payments: PaymentItemProps[];
    is_has_oceapay?: number | null;
    paymentSuggestion?: string;
    isUsedThisPayment?: number | null;
    folded_position?: string;
    finalSelectPaymentCode?: string;
    pay_with_title?: string | null;
    force_remember_card?: string | null;
    force_remember_card_tip?: string | null;
    checkout_force_remember_card_tip?: string | null;
  },
  paymentConfig?: Trade_PayToolKit.PaymentConfig;
  checkoutInfo?: Trade_PayToolKit.CheckoutInfo;
  bsPaymentAction: (info: { action: Trade_PayToolKit.BsPaymentAction; payload: Record<string, any> }) => void;
}

const props = withDefaults(defineProps<MainPaymentsProps>(), {
  payments: () => ([]),
  language: () => ({}),
  paymentItemsConfig: () => ({}),
  c2pInfo: () => ({}),
})

// const emit = defineEmits<{
//   select: [info: AssembledToOutsidePaymentInfo], // named tuple syntax
//   'payment-info-change': [info: AssembledToOutsidePaymentInfo]
// }>()

const emit = defineEmits<{
  (event: 'select', info: {
    type: PaymentItemSelectType;
    paymentItem?: Trade_PayToolKit.PaymentInfoItem,
    tokenItem?: Record<string, any>,
    triggerSelectHook: boolean
  }): void
}>()

const { handleEditableInfoChange, editablePaymentInfo } = useEditablePaymentInfo()

const paymentState = usePaymentsStore()

const c2pTokenList = computed(() => props?.c2pInfo?.c2pTokenList || [])

const isHadC2pToken = (pay) => {
  return pay?.code === 'routepay-card' && c2pTokenList.value?.length > 0
}
const selectedTokenInfo = computed(() => paymentState?.selectedTokenInfo || {})

const changeCardTokenInfo = (pay) => {
  if (paymentState?.editablePaymentInfo?.[pay?.code]?.card_token_info && (pay?.card_token_list || []).length === 0) {
    if (isHadC2pToken(pay)) return
    handleEditableInfoChange({
        changeInfo: {
            [pay?.code]: {
                ...(paymentState?.editablePaymentInfo?.[pay?.code] || {}),
                card_token_info: {},
            },
        },
    })
  }
}

const handleSelectEvt = ({ type, paymentItem, tokenItem }: {
  type: PaymentItemSelectType;
  paymentItem?: Trade_PayToolKit.PaymentInfoItem;
  tokenItem?: Record<string, any>;
}) => {
  changeCardTokenInfo(paymentItem)
  emit('select', { type, paymentItem, triggerSelectHook: true, tokenItem })
}

const handleChangeEvt = (info: { [key: Trade_PayToolKit.PAYMENT_CODE_KEY]: Trade_PayToolKit.EditablePaymentItem }) => {
  debuggerLog('handleChangeEvt', info)
  handleEditableInfoChange({
    changeInfo: info,
    needEmitChangeEvent: true,
  })
}

// onMounted(() => {
//   paymentState.paymentEventBus.selectedFinish.on(() => {
//     // emit('select', handleAssembledToOutsidePaymentInfo())
//     emit('payment-info-change', handleAssembledToOutsidePaymentInfo())
//   })
// })

defineExpose({
  handleChangeEvt,
})
</script>

<style scoped lang="less">
// .payments-main-container {
//   padding: 0 var(--bs-payments-container-main-padding);
// }
</style>
