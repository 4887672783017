// useScroll.ts
import { ref } from 'vue'

interface ScrollOptions {
  // 滚动行为配置
  behavior?: ScrollBehavior // 'auto' | 'smooth' | 'instant'
  block?: ScrollLogicalPosition // 'start' | 'center' | 'end' | 'nearest'
  inline?: ScrollLogicalPosition
  // 偏移量配置
  offset?: number
}

export function useScroll() {
  // 容器引用
  const containerRef = ref<HTMLElement | null>(null)

  /**
   * 滚动到指定元素
   * @param selector 目标元素的选择器
   * @param options 滚动配置选项
   */
  const scrollToElement = (
    selector: string,
    options: ScrollOptions = {
      behavior: 'smooth',
      block: 'start',
      offset: 0,
    },
  ) => {
    if (!containerRef.value) return

    const container = containerRef.value
    const targetElement = container.querySelector(selector)

    if (!targetElement) {
      console.warn(`Element with selector "${selector}" not found`)
      return
    }

    if (options.offset) {
      // 使用 scrollTop 实现带偏移量的滚动
      const targetPosition = targetElement.getBoundingClientRect().top +
                           container.scrollTop -
                           container.getBoundingClientRect().top -
                           (options.offset || 0)

      container.scrollTo({
        top: targetPosition,
        behavior: options.behavior,
      })
    } else {
      // 使用 scrollIntoView 实现标准滚动
      targetElement.scrollIntoView({
        behavior: options.behavior,
        block: options.block,
        inline: options.inline || 'nearest',
      })
    }
  }

  /**
   * 滚动到指定索引的元素
   * @param index 目标元素的索引
   * @param itemSelector 列表项的选择器
   * @param options 滚动配置选项
   */
  const scrollToIndex = (
    index: number,
    itemSelector: string,
    options?: ScrollOptions,
  ) => {
    const elements = containerRef.value?.querySelectorAll(itemSelector)
    if (!elements || index >= elements.length) {
      console.warn(`Invalid index: ${index}`)
      return
    }

    const targetElement = elements[index]
    targetElement.scrollIntoView({
      behavior: options?.behavior || 'smooth',
      block: options?.block || 'start',
      inline: options?.inline || 'nearest',
    })
  }

  return {
    containerRef,
    scrollToElement,
    scrollToIndex,
  }
}
