import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

export interface IGetTelCountryListRes {
    code: string;
    msg: string;
    info: {
      country?: {
        item_cates?: {
          id: string;
          value: any;
          countrynum: string;
        }[]
      }
    }
}

export const useGetAllCountryInfo = async () => {
    const appConfigs = useAppConfigs()
    const result = await appConfigs?.$schttp<IGetTelCountryListRes>({
        url: '/user/address/get_country_site_list',
        method: 'GET',
        headers: {
            AppCurrency: appConfigs?.$envs?.currency,
            AppLanguage: appConfigs?.$envs?.appLanguage,
        },
    })

    return result.data || result
}

// 获取电话国家区号
export const useGetPhoneCountryNum = async ({ countryId }) => {
    const res = await useGetAllCountryInfo()

    if (res?.code == '0' && res?.info?.country?.item_cates?.length) {
        const countryInfo = res.info.country.item_cates.find(item => item.id === countryId)

        if (countryInfo) {
          const phoneCountryNum = `${countryInfo.value}+${countryInfo.countrynum}`
          return {
            phoneCountryNum,
          }
        }
    }
    return {
        phoneCountryNum: '',
    }
}
