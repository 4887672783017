<template>
  <SDialog
    class="upgrade-app-tips__dialog"
    :visible="visible"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close-from-icon="emits('cancel')"
  >
    <template #title></template>
    <div
      class="upgrade-app-tips__content"
      v-html="tips"
    >
    </div>
    <template #footer>
      <SButton
        :type="['primary']"
        :width="'100%'"
        @click="emits('confirm')"
      >
        {{ confirmText }}
      </SButton>
    </template>
  </SDialog>
</template>

<script name="PayUpgradeAppTips" setup lang="ts">
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'

interface BPayUpgradeAppTipsProps {
  visible: boolean;
  tips: string;
  confirmText: string;
}

withDefaults(defineProps<BPayUpgradeAppTipsProps>(), {
  visible: false,
  tips: '',
  confirmText: 'CONTINUE',
})

const emits = defineEmits(['confirm', 'cancel'])

</script>

<style lang="less">
.upgrade-app-tips__dialog {
  .upgrade-app-tips__content {
    // word-break: break-word;
    text-align: center;
  }
}
</style>
