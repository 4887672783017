<template>
  <div class="form-item-comp card-logo">
    <img
      :src="src"
      alt=""
    />
  </div>
</template>

<script name="CardLogo" setup lang="ts">
defineProps({
  src: {
    type: String,
    default: '',
  },
})
</script>

<style lang="less" scoped>
.card-logo {
  width: 48/75rem;
  height: auto;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
}
</style>
