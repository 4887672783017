<template>
  <component
    :is="currentComponent"
    v-bind="attrs"
  />
</template>

<script setup lang="ts">
import { defineAsyncComponent, computed, useAttrs, type Component } from 'vue'
import useCapitecDialog from '../../../hooks/useCapitecDialog'

const CapitecDialog = defineAsyncComponent(() => import('./CapitecDialog.vue'))
const CapitecDrawer = defineAsyncComponent(() => import('./CapitecDrawer.vue'))
// import CapitecDialog from './CapitecDialog.vue'
// import CapitecDrawer from './CapitecDrawer.vue'

const attrs = useAttrs()

const { dialogConfig } = useCapitecDialog()

const currentComponent = computed(() => {
  if (dialogConfig.value.type === 'dialog') {
    return CapitecDialog as Component
  } else if (dialogConfig.value.type === 'drawer') {
    return CapitecDrawer as Component
  } else {
    return null
  }
})
</script>
