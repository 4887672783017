import { computed, watch, ref } from 'vue'
import { useItemSelectBank } from './useItemSelectBank'
import { usePaymentsStore } from './usePayments'
import { usePaymentConfig } from './usePaymentsConfig'
import { useEditablePaymentInfo } from './useEditablePaymentInfo'
import type { BPayPaymentItemProps } from '../types/props'
import { debuggerLog } from '../utils'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

export const usePaymentItem = (props: BPayPaymentItemProps) => {
  const paymentStore = usePaymentsStore()
  const { getPaymentConfig } = usePaymentConfig()
  const {
    visibleBankSelect,
    selectedDisPlayBankInfo,
    handleToSelectBank,
    isHadSelectedBank,
    isSelectedBankError,
  } = useItemSelectBank(props, paymentStore)

  const hasSelected = ref(false)

  const paymentConfig = getPaymentConfig()
  const appConfigs = useAppConfigs()

  const project = computed(() => appConfigs.$envs.project)

  const isShowToken = computed(() => Object.keys(cardTokenInfo.value || {}).length > 0)

  const isSelectedC2pToken = computed(() => !(props.paymentItem?.card_token_list || [])?.some(item => item?.id === cardTokenInfo.value?.id))

  const c2pTokenList = computed(() => props?.c2pInfo?.c2pTokenList || [])

  const isC2pToken = computed(() => cardTokenInfo.value?.srcDigitalCardId)

  const isC2pTokenInSheinTokenList = computed(() => c2pTokenList.value?.some(item => (props.paymentItem?.card_token_list || [])?.some(token => token.last_four_no === item.last_four_no && token.card_type.toLocaleLowerCase() === item.paymentCardDescriptor.toLocaleLowerCase())))

  const isHadC2pToken = computed(() => props.paymentItem?.code === 'routepay-card' && c2pTokenList.value?.length > 0)

  const isInstallment = computed(() => props.paymentItem?.code === 'routepay-cardinstallment')

  const nowPayTokenList = computed(() => props.paymentItem?.card_token_list || [])

  const nowTokenPayIsHadToken = computed(() => {
    return nowPayTokenList.value?.length > 0
  })

  const isHomoGenizationPay = computed(() => (props.paymentItem?.payments || [])?.length > 0)

  const signAccountInfo = computed(() => props.paymentItem.paymentSignUp?.[0] || {})

  // 会员、超省卡随单购
  const isPrimeOrSavePopup = computed(() => paymentConfig.forceSignUp.value)

  const showSignup = computed(() => {
    return props.paymentItem.needToSign === '1'
  })

  const ppgaTitle = computed(() => {
    // 非ppga支付方式
    if (props.paymentItem.code !== 'PayPal-GApaypal') return ''
    // 快捷支付展示
    const text = project.value === 'pwa' ? paymentStore.language.BS_KEY_PAY_1027 || props.language.BS_KEY_PAY_1027 : paymentStore.language.SHEIN_KEY_PC_29438
    if (props.signUpInfo?.use_one_time_sign || showSignup.value) return text
    return ''
  })

  const paymentTags = computed(() => {
    const tags: string[] = []
    if (props.paymentItem?.last_used) {
      // TODO 待修改 BS_KEY_PAY_1209对应SHEIN_KEY_PWA_34952 没有对应的pc端文案，需产品提供
      tags.push(paymentStore.language.BS_KEY_PAY_1209 || props.language.BS_KEY_PAY_1209 || paymentStore.language.SHEIN_KEY_PC_35213 || props.language.SHEIN_KEY_PC_35213)
    }
    return tags
  })

  const isShowTokenDrawer = computed(() => paymentStore.isShowTokenDrawer)

  const paymentItemsConfig = computed(() => props?.isOnlyPayItem ? (props?.nowPaymentItemsConfig || {}) : paymentStore.paymentItemsConfig)

  const { updateInfo, editablePaymentInfo } = useEditablePaymentInfo()

  const isShowTokenList = computed(() => !!editablePaymentInfo.value?.[props?.paymentItem?.code]?.isShowTokenList)

  const nowUseSelectedDisPlayBankInfo = computed(() => (props?.isOnlyPayItem && Object.keys(props?.nowSelectedDisPlayBankInfo || {}).length > 0)
  ? props?.nowSelectedDisPlayBankInfo
  : selectedDisPlayBankInfo.value)

  const nowUseEditablePaymentInfoData = computed(() => {
    return (props?.isOnlyPayItem && Object.keys(props?.nowEditablePaymentInfoData || {}).length > 0)
    ? props?.nowEditablePaymentInfoData
    : editablePaymentInfo.value?.[props.paymentItem.code]
  })

  const isAutoRenewal = computed(() => {
    return isPrimeOrSavePopup.value && (paymentItemsConfig.value?.checkoutForceRememberCardTip || paymentItemsConfig.value?.forceRememberCardTip)
  })

  const cardTokenInfo = computed(() => nowUseEditablePaymentInfoData.value?.card_token_info)
  const isUseNewCard = computed(() => nowUseEditablePaymentInfoData.value?.isUseNewCard)

  const paymentTitle = computed(() => {
    props?.isOnlyPayItem && debuggerLog('paymentTitle >>>>>>>', props?.nowSelectedDisPlayBankInfo)
    if (+props.paymentItem.show_title !== 1) return ''
    if (props.signUpInfo?.use_one_time_sign) return props.paymentItem?.oncePay_paytitle
    if (isShowToken.value && isInstallment.value) {
      if (isUseNewCard.value) {
        const text = project.value === 'pwa' ? props.language.BS_KEY_PAY_1030 : props.language.SHEIN_KEY_PC_30111
        return text
      }
      return `${cardTokenInfo.value?.card_no || ''}${project.value === 'pwa' ? props.language.BS_KEY_PAY_1031 : props.language.SHEIN_KEY_PC_31500 || ''}`
    }
    if (isShowToken.value && isUseNewCard.value) {
      return project.value === 'pwa' ? props.language.BS_KEY_PAY_1030 : props.language.SHEIN_KEY_PC_30111
    }
    if (isShowToken.value && cardTokenInfo.value?.card_no) return cardTokenInfo.value?.card_no
    // if (!isShowToken.value && isHadC2pToken.value) return cardTokenInfo.value?.card_no
    if (nowUseSelectedDisPlayBankInfo.value?.title) return nowUseSelectedDisPlayBankInfo.value?.title
    if (ppgaTitle.value) return ppgaTitle.value
    return props.paymentItem?.title || ''
  })

  const paymentLogo = computed(() => {
    if (isShowToken.value) {
      if (isUseNewCard.value) {
        return 'https://img.ltwebstatic.com/images3_ccc/2024/06/14/aa/1718352340ff2421c5efcedb6a17bb10bd997ca733.png'
      }

      // 联名卡显示联名卡logo，PC端没有联名卡逻辑
      if(project.value === 'pwa' && cardTokenInfo.value?.co_brand_card_tag === '1' && cardTokenInfo.value?.tokenCoBrandedLogoUrl){
        return cardTokenInfo.value?.tokenCoBrandedLogoUrl
      }
    }
    if (isShowToken.value && cardTokenInfo.value?.web_logo) return cardTokenInfo.value?.web_logo
    // if (!isShowToken.value && isHadC2pToken.value) return cardTokenInfo.value?.web_logo
    if (nowUseSelectedDisPlayBankInfo.value?.logo) return nowUseSelectedDisPlayBankInfo.value?.logo
    if (props.paymentItem.enabled !== '1') return props.paymentItem?.gray_logo_url || ''
    return props.paymentItem?.logo_url || ''
  })

  const forceRememberText = computed(() => {
    const { forceRememberCardTip, checkoutForceRememberCardTip } = paymentItemsConfig.value
    return props.forceSignupTip || forceRememberCardTip || checkoutForceRememberCardTip
  })

  const isForceRemember = computed(() => {
    return !!forceRememberText.value
  })

  const paymentItemExposeAnalysis = computed(() => {
    const paymentCode = props.paymentItem.code
    const isDefault = paymentStore?.paymentSuggestion === paymentCode
    let isVaulting = '-'
    if (paymentCode === 'PayPal-GApaypal') {
      isVaulting = signAccountInfo.value?.signUpEmail || props.paymentItem?.needToSign === '1' ? '1' : '0'
    }
    // 是否存在在线支付方式优惠 discountType=2
    const isPayPromotion = props.paymentItem?.paymentsPreferentialTips?.some?.(item => +item.type === 2)
    return {
      payment_method: paymentCode,
      is_vaulting: isVaulting,
      is_folded: props.isFolded ? '1' : '0',
      is_pay_promotion: isPayPromotion ? '1' : '0',
      is_default: isDefault ? '1' : '0',
      default_type: isDefault ? '1' : '0',
      sequence: (props?.paymentIndex || -1) + 1,
    }
  })

  watch([
    paymentTitle,
    paymentLogo,
  ], ([title, logo], [oldTitle, oldLogo]) => {
    // 标题和logo变化时，更新支付方式信息
    if (title !== oldTitle || logo !== oldLogo) {
      updateInfo(props.paymentItem.code, {
        display_title: title,
        display_logo: logo,
      })
    }
  })

  return {
    hasSelected,
    paymentTitle,
    paymentLogo,
    isShowToken,
    paymentTags,
    signAccountInfo,
    showSignup,
    visibleBankSelect,
    handleToSelectBank,
    isHadSelectedBank,
    isSelectedBankError,
    isHomoGenizationPay,
    paymentItemsConfig,
    isShowTokenDrawer,
    isShowTokenList,
    isAutoRenewal,
    isPrimeOrSavePopup,
    paymentItemExposeAnalysis,
    nowUseEditablePaymentInfoData,
    isHadC2pToken,
    isForceRemember,
    forceRememberText,
    isC2pTokenInSheinTokenList,
    isC2pToken,
    nowPayTokenList,
    isSelectedC2pToken,
    nowTokenPayIsHadToken,
  }
}
