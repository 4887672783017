<template>
  <div class="payment-form-item">
    <BaseFormItem
      ref="baseFieldRef"
      v-model.trim="fieldValue"
      :inputmode="inputmode"
      :placeholder="placeholder"
      :label="label"
      :required="required"
      :clearable="clearable"
      :maxlength="maxlength"
      @change="handleValueInput"
      @blur="handleValueBlur"
    />
    <div
      v-show="errorTips"
      class="error-invalid"
    >
      {{ errorTips }}
    </div>
  </div>
</template>

<script setup lang="ts" name="BasicFormItem">
import { ref } from 'vue'
import BaseFormItem from './BaseFormItem.vue'

interface Props<V = boolean> {
  modelValue: string; // 值
  errorMessage?: string; // 错误提示信息
  required?: boolean; // 是否必填
  label?: string; // 标签文本
  placeholder?: string; // 输入框占位文本
  clearable?: boolean; // 是否显示清除按钮
  maxlength?: number; // 最大长度
  inputmode?: string; // 输入模式
  formatter?: (value: string) => string; // 指定输入值格式化函数
  validator?: (value: string) => V; // 自定义校验规则
}

const emits = defineEmits(['update:modelValue', 'change'])
const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  errorMessage: '',
  required: false,
  label: '',
  placeholder: '',
  clearable: false,
  inputmode: 'text',
  maxlength: 99,
})

const baseFieldRef = ref()
const fieldValue = ref(props.modelValue || '')
const errorTips = ref('')

const handleValueInput = ev => {
  let txt = ev.target.value || ''
  fieldValue.value = ev.target.value = txt.trim()
  emits('update:modelValue', fieldValue.value)
  emits('change', fieldValue.value)
}

const handleValueBlur = () => {
  validator()
  emits('update:modelValue', fieldValue.value)
}

const validator = () => {
  if (!props?.required) {
    errorTips.value = ''
    return {
      result: true,
      failType: null,
      failReason: null,
    }
  }
  const validRes = props.validator?.(fieldValue.value) as any
  const result = typeof validRes?.result !== 'undefined' ? validRes?.result : (validRes || !props.errorMessage)
  errorTips.value = !result ? validRes?.message || props.errorMessage : ''
  return {
    result,
    failType: validRes?.failType || '',
    reasonType: validRes?.reasonType || '',
  }
}

const reset = () => {
  fieldValue.value = ''
  errorTips.value = ''
  emits('update:modelValue', '')
}

defineExpose({
  validator,
  reset,
  focus: () => baseFieldRef.value.focus(),
})

</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
  font-size: @font-size * 0.5;
}

.payment-form-item {
  padding: 24/75rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24/75rem;
    right: 0;
    height: 1px;
    background-color: var(--sui_color_gray_light2, #e5e5e5);
  }
  &.no-underline {
    &:after {
      display: none;
    }
  }
  .error-invalid {
    font-size: 12px;
    line-height: 1;
    color: var(--sui_color_promo, #FA6338);
    margin-top: 8/75rem;
    transition: height 0.8s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
