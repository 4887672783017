<template>
  <div
    class="bs-payment-item__base"
    :class="{ 'bs-payment-item__base-disabled': enabled != 1 }"
    @click.prevent.stop="emits('select', paymentCode)"
  >
    <div class="payment-item__left">
      <SRadio
        :modelValue="checkSelect(paymentCode)"
        :label="true"
        :toggle="true"
        :disabled="enabled !== '1'"
        size="radio16"
        gap="0.4rem"
      />
    </div>
    <div class="payment-item__center">
      <div class="item-center-top">
        <BSrcCardList
          v-if="isC2pToken"
          :cardType="paymentCardDescriptor"
        />
        <div
          v-else
          class="payment-logo"
        >
          <img
            class="payment-logo-img"
            :class="{ 'empty-logo': !logoUrl }"
            :src="replaceUrlProtocol(logoUrl)"
            alt="logo"
          />
        </div>
        <div
          class="payment-title"
          :class="{ 'payment-disabled': enabled != 1 }"
        >
          <span v-html="title"></span>
        </div>
        <slot name="center-top-right"></slot>
      </div>
      <div
        v-if="showCenterBottomSlot"
        class="item-center-bottom">
        <slot name="center-bottom"></slot>
      </div>
    </div>
    <div
      v-if="showRightSlot"
      class="payment-item__right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script name="PaymentItemBase" setup lang="ts">
// type RefPaymentItem = Partial<PaymentItemProps>
import { computed, useSlots } from 'vue'
import { SRadio } from '@shein-aidc/sui-radio/mobile'
import { replaceUrlProtocol } from '../../../../utils'
import { BSrcCardList } from '@shein-aidc/bs-click-to-pay-mobile'

const slots = useSlots()


interface PaymentItemBaseProps {
  isSelected: boolean;
  paymentId?: string | number
  paymentCode?: string
  title: string
  description?: string
  logoUrl: string
  enabled: string | number
  nowUseEditablePaymentInfoData?: Record<string, any>
  c2pInfo?: Record<string, any>;
}

// const paymentItem = ref<RefPaymentItem>({
//   enabled: '1',
// })

const props = withDefaults(defineProps<PaymentItemBaseProps>(), {
  isSelected: false,
  paymentId: '',
  paymentCode: '',
  title: '',
  description: '',
  logoUrl: '',
  enabled: '',
  nowUseEditablePaymentInfoData: () => ({}),
})

const emits = defineEmits(['select'])

const showCenterBottomSlot = computed(() => !!slots?.['center-bottom']?.())
const showRightSlot = computed(() => !!slots?.['right']?.())

const checkSelect = (paymentCode) => {
  if (!paymentCode) return false
  return +props.enabled === 1 && props.isSelected
}

const cardTokenInfo = computed(() => props?.nowUseEditablePaymentInfoData?.card_token_info || {})

const isC2pToken = computed(() => cardTokenInfo.value?.srcDigitalCardId)

const paymentCardDescriptor = computed(() => cardTokenInfo.value?.paymentCardDescriptor)

</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
  font-size: @font-size * 0.5;
}
.bs-payment-item__base {
  padding: 20/75rem 0;
  border-bottom: 1px solid @sui_color_gray_weak1;
  display: flex;
  // stylelint-disable-next-line
  // &:last-child {
  //   border-bottom: none;
  // }


  &.bs-payment-item__base-disabled {
    /deep/ .extra-tip {
      .content {
        color: #999;
      }
    }
  }
}

.payment-item__left {
  /deep/ .S-radio_normal {
    margin-right: 0.4rem;
  }
}

.payment-item__center {
  // max-width: 312/37.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  .item-center-top {
    display: flex;
  }
  .payment-logo,
  .payment-logo-img {
    width: 60/75rem;
    height: auto;
    &.empty-logo {
      height: 40/75rem;
    }
  }
  .payment-title {
    margin-left: 12/75rem;
    .font-dpr(28px);
  }
  .payment-disabled {
    color: #222;
    opacity: 0.3;
  }
  .item-center-bottom {
    text-align: left;
    .card-logo-list {
      text-align: left;
      margin-top: 12/75rem;
    }
    .card-logo-img {
      width: auto;
      height: 32/75rem;
      margin-right: 8/75rem;
      margin-bottom: 8/75rem;
    }
    .desc-hint {
      padding-top: 8/75rem;
      .font-dpr(24px);
      // color: @sui_color_gray_light1;
      color: @sui_color_gray_light1;
    }
    .desc-hint-link {
      text-decoration: none;
    }
  }
}
.payment-item__right {
  display: flex;
  .selected-bank-text {
    word-break: break-word;
    margin-right: 0.0533rem;
    color: #959595;
    .font-dpr(24px);
    flex: 1;

  }
  .icon2 {
    margin-right: 0;
    .font-dpr(32px);
    color: @sui_color_gray_light1;
  }
  .txt-error {
      color: @sui_color_unusual;
  }
  // stylelint-disable-next-line
  .payment-item__right-icon {
    padding: 8/75rem;
    padding-right: 0;
    // stylelint-disable-next-line
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}
.payment-icon-tip {
  padding: 0 10/75rem;
  color: @sui_color_gray_light1;
  .font-dpr(28px);
}
</style>
