<template>
  <div
    class="otp-code-input"
    :class="{ error: errorTips }">
    <div>
      <div
        :class="{
          'otp-code-input__inner': true,
          'show-eye': showEye,
        }">
        <span
          v-for="(_, i) in digits"
          :key="i"
          :class="{
            'otp-code-input__digit': true,
            active: resolveActive(i),
            'secure-str': isEncrypted && innerValue.charAt(i),
          }">{{ modelValue.charAt(i) }}</span>
        <input
          ref="input"
          v-model.trim="innerValue"
          type="text"
          unselectable="on"
          :maxlength="digits"
          @focus="handleFocus"
          @input="handleInput"
          @blur="handleBlur"
          @keyup="updateCursorPosition"
          @touchend="updateCursorPosition" />
        <div
          v-if="showEye"
          class="otp-code-input__eye"
          @click.stop="handleClickEye">
          <Icon
            v-if="isEncrypted"
            key="close"
            name="sui_icon_eyeclosed_20px"
            size="16px"
            :is-rotate="cssRight" />
          <Icon
            v-else
            key="open"
            name="sui_icon_eyeopen_20px"
            size="16px"
            :is-rotate="cssRight" />
        </div>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, nextTick, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  // OTP码位数
  digits: {
    type: Number,
    default: 6,
  },
  // 是否显示为密文（如"*"）
  isEncrypted: {
    type: Boolean,
    default: false,
  },
  clickEyeEvent: {
    type: Function,
    default: undefined,
  },
  errorTips: {
    type: String,
    default: '',
  },
  // 是否自动聚焦
  autoFocus: {
    type: Boolean,
    default: false,
  },
  cssRight: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['update:modelValue','focus', 'blur', 'submit'])
const input = ref()
const isFocus = ref(false)
const innerValue = ref(props.modelValue)

const showEye = computed(() => {
  return typeof props.clickEyeEvent === 'function'
})

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue !== innerValue.value) {
      innerValue.value = newValue
    }
  },
)

const resolveActive = (i: number) => {
  return isFocus.value && i === innerValue.value.length
}

// 确保光标一直在最后
const updateCursorPosition = (event: Event) => {
  const inputElement = event.target as HTMLInputElement
  // 确保光标位置不超过文本长度
  const desiredPosition = Math.min(props.digits, inputElement.value.length)
  inputElement.setSelectionRange(desiredPosition, desiredPosition)
}

const handleFocus = (event: Event) => {
  isFocus.value = true
  updateCursorPosition(event)
  emits('focus', innerValue.value)
}

const handleInput = async (e: Event) => {
  const value = (e.target as HTMLInputElement).value
  const oldValue = props.modelValue
  isFocus.value = true
  let newValue = value?.replace(/\D/g, '')
  if (newValue?.length > props.digits) {
    newValue = newValue.slice(0, props.digits)
  }
  await nextTick()
  innerValue.value = newValue
  if (newValue !== oldValue) {
    emits('update:modelValue', newValue)

    // 输入完成
    if (newValue?.length === props.digits) {
      emits('submit', newValue)
      input.value && input.value.blur()
      return
    }
  }
}

const handleBlur = () => {
  isFocus.value = false
  emits('blur', innerValue.value)
}

const handleClickEye = () => {
  typeof props.clickEyeEvent === 'function' && props.clickEyeEvent(props.isEncrypted)
}

</script>

<style lang="less" scoped>
.otp-code-input {
  text-align: center;

  .otp-code-input__inner {
    max-width: 100%;
    position: relative;
    display: inline-flex;
    justify-content: center;
    column-gap: 16*2/75rem;
    vertical-align: bottom;

    &.show-eye {
      padding: 0 26*2/75rem;

      >input {
        left: 26*2/75rem;
        right: 26*2/75rem;
      }
    }

    .otp-code-input__digit {
      width: 40*2/75rem;
      height: 48*2/75rem;
      border: 1px solid #bbb;
      font-weight: bold;
      font-size: 18*2/75rem;
      color: #222;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        border-color: #666;

        &::after {
          content: '';
          width: 1px;
          height: 0.53rem;
          background: #222;
          animation: otp-code-pop-flash 1s linear infinite;
        }
      }

      &.secure-str {
        font-size: 0;

        &:after {
          content: '';
          width: 6*2/75rem;
          height: 6*2/75rem;
          background: #222;
          border-radius: 100px;
        }
      }
    }

    input {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0;
      background: transparent;
      outline: none;
      color: transparent;
      caret-color: transparent;
      border-color: transparent;
      opacity: 0.1;
    }

    .otp-code-input__eye {
      position: absolute;
      padding: 12*2/75rem;
      font-size: 0;
      right: -14*2/75rem;
      top: 24*2/75rem;
      transform: translateY(-50%);
    }
  }

  // 重复写以提升样式优先级
  &.error.error {
    .otp-code-input__digit {
      border-color: #BF4123;
    }
  }

  @keyframes otp-code-pop-flash {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
</style>
