import { createApp } from 'vue'

export default async () => {
  // const Container = await import(
  //   /* webpackChunkName: "cod-verify-free-popup-module" */ './index.vue'
  // ).catch(() => '')
  // if (typeof Container == 'string') return
  // const app = createApp(Container?.default)
  // const root = document.createElement('div')
  // root.setAttribute('class', 'cod-verify-free-popup-container')
  // document.body.appendChild(root)
  // const instance = app.mount(root)

  const Container = await import(
    /* webpackChunkName: "cod-verify-free-popup-module" */ './index.vue'
  ).catch(() => '')
  if (!Container?.default) return
  const appDom = document.createElement('div')
  const instance = createApp(Container?.default)
  const vm = instance.mount(appDom)
  document.body.appendChild(vm.$el)
  vm.$el.setAttribute('class', 'cod-verify-free-popup-container')

  return vm
}
