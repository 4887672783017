<template>
  <li
    :key="item.code"
    :class="['j-payment-item mcheo-payment__item j-' + item.code + '-method']"
    :data-method="item.code"
    :data-type="item.payment_type"
    @click="choosePayment"
  >
    <div class="mshe-flexbetween">
      <s-checkbox
        v-model="cardValue"
        class="payment-item-checkbox"
        :disabled="item.enabled == 0"
        name="paymentmethod"
        :data-id="item.id"
        :label="item.code"
        gap="`${16 / 75}rem`"
      />
      <div
        class="card-left"
        :class="[!isClient ? 'ssr-se' : '']">
        <img
          class="payment-src"
          :src="getImg(getPaymentLogoUrl)" />
        <span class="check-list">
          <span class="check-title">{{ getPaymentTitle }}</span>
        </span>
      </div>
    </div>
    <!-- 支付信息前置展示: 7+1 -->
    <div class="payment-footer">
      <!-- 区域:卡列表展示 -->
      <div
        v-if="item.card_logo_list && item.card_logo_list.length"
        class="card-logo">
        <ul>
          <li
            v-for="(img, i) in item.card_logo_list"
            :key="i"
            class="card-logo-item">
            <img :src="getImg(img)" />
          </li>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
import { transformImg } from '@shein/common-function'
import { replaceUrlProtocol } from '../../../utils'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    checkout: {
      type: Object,
      default: function () {
        return {}
      },
    },
    selectedPay: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['click-payment'],

  data: function () {
    return {
      status: {
        cardPay: '',
      },
    }
  },

  computed: {
    getPaymentTitle() {
      return this.item.show_title && this.item.title
    },
    getPaymentLogoUrl() {
      return this.item.logo_url
    },
    cardValue() {
      return !!(this.item?.enabled && this.status?.cardPay == this.item?.code)
    },
    isClient() {
      return typeof window != 'undefined'
    },
  },

  watch: {
    selectedPay: {
      handler(val) {
        if (val?.code !== this.status.cardPay) {
          this.status.cardPay = ''
        }
      },
    },
  },

  methods: {
    choosePayment() {
      this.status.cardPay = this.item?.code
      this.$emit('click-payment', this.item)
    },

    getImg(img) {
      return transformImg({ img: replaceUrlProtocol(img) })
    },
  },
}
</script>

<style lang="less" scoped>
.margin-r(@size) {
  margin-right: @size;
}
.font-dpr(@font-size) {
  font-size: @font-size * 0.5;
}
.padding-l(@size) {
  padding-left: @size;
}
.padding-r(@size) {
  padding-right: @size;
}
.margin-l(@size) {
  margin-left: @size;
}
.right(@size) {
  right: @size;
}
.align-center() {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  display: -ms-flexbox;
}
.fl() {
  float: left !important; /* stylelint-disable-line declaration-no-important */
}
.txt-r() {
  text-align: right;
}
.border-dpr(@border,@num,@color) {
  @{border}: @num * 0.5 solid @color;
}
.mshe-flexbetween {
  .flexbox();
  justify-content: space-between;
}

.card-logo {
  .card-logo-item {
    width: 42/75rem;
    height: 28/75rem;
    display: inline-block;
    .margin-r(12/75rem);
    margin-bottom: 12/75rem;
    img {
      width: 100%;
      height: 100%;
      vertical-align: baseline;
    }
  }
}

.mcheo-payment__item {
  position: relative;
  padding: 20/75rem 0;
  .padding-r(24/75rem);
  .align-center();
  .border-dpr(border-bottom,2px,#e5e5e5);
  &:last-child {
    border: none;
  }
  &.disabled {
    color: #999;
  }
  .payment-discount-zero {
    .margin-l(6px);
    border: 0.5px solid #ffe1db;
  }
  .payment-discount-tip {
    color: @sui_color_highlight;
    .font-dpr(24px);
    margin-top: 0;
  }
  .ppgv-arrow {
    height: 32px;
    position: absolute;
    margin-top: -16px;
    font-size: 16px;
    color: #999;
    top: 0.62rem;
    .right(0.32rem);
    .padding-l(0.8rem); // 增大点击区域
  }
  .payment-item-checkbox {
    width: 48/75rem;
    height: 48/75rem;
  }
}

.card-left {
  width: 100%;
  padding: 0;
  .flexbox();
  .align-center();
  .icon-pro-help {
    font-size: 16px;
    color: #999;
    vertical-align: middle;
  }
  .check-title {
    .fl();
  }
  .payment-src {
    .margin-r(0.2rem);
    width: 0.8rem;
    height: 0.52rem;
  }
  > span {
    display: block;
    font-size: 14px;
    /* stylelint-disable-next-line selector-class-pattern, selector-max-specificity, selector-max-type  */
    > i {
      .margin-l(0.2rem);
    }
    &.check-disabled {
      color: #222;
      opacity: 0.3;
    }
  }
  > i {
    // background-image: url(/pwa_dist/images/checkcard-e488f6ed3d.png);
    background-size: 2.8rem auto;
    display: block;
    .margin-r(0.2rem);
    &.check-disabled {
      filter: grayscale(1);
    }
  }
  .off-tips {
    color: #e64545;
    .font-dpr(22px);
  }
  .icon-tips {
    .padding-l(0.106666rem);
    color: @sui_color_gray_light1;
    text-decoration: none;
  }
  .bank-contain {
    flex: 1;
    .txt-r();
    .selected-bank-area {
      .flexbox();
      align-items: center;
      .selected-bank-text {
        word-break: break-word;
        .margin-r(0.0533rem);
        color: @sui_color_gray_dark2;
        .font-dpr(24px);
        flex: 1;
      }
      .icon2 {
        .margin-r(0);
        .font-dpr(32px);
        color: @sui_color_gray_light1;
      }
      .txt-error {
        color: @sui_color_unusual;
      }
    }
  }
  &.ssr-common {
    margin-left: 24/75rem;
  }
  &.ssr-se {
    margin-left: 16/75rem;
  }

  .text-error {
    color: @sui_color_unusual;
    .font-dpr(22px);
  }

  .left-drawer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #959595;

    .more-discount {
      color: #767676;
      font-size: 14px;
      font-family: SF UI Text;
      font-weight: 400;
      word-wrap: break-word;
    }

    .red-point {
      background: #fa6338;
      width: 14/75rem;
      height: 14/75rem;
      border-radius: 50%;
      margin: 0 4/75rem 0 8/75rem;
    }
  }
}

.sui-checkbox__label-select {
  border: 1.5px solid #ccc;
}

.mcheo-payment .payment-footer {
  .margin-l(0.853333rem);
}
</style>
