import { computed, toRefs, toRef, ref, onBeforeUnmount } from 'vue'
import { getDefaultPrePayUnPayParams, usePrePayStore } from './usePrePay'
import { usePaymentsStore } from './usePayments'
import useApis from './useApis'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { CO_BRAND_CARD_TIPS_TYPE, CO_BRAND_LEVEL_LIST, CO_BRAND_CARD_LOGO_THUMBNAIL } from '../common/constants'
// import { fetchPrePayRoutingForCheckout } from '../fetch'
// import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import { debuggerLog } from '../utils'

const CARD_BIN_COUPONS_TYPE = 4 // 卡bin优惠券

const usePrePayModalFecth = () => {
  const prePayStore = usePrePayStore()
  const appConfigs = useAppConfigs()
  const prePayCheckoutInfo = toRef(prePayStore, 'prePayCheckoutInfo')
  const { fetchPrePayRoutingForCheckoutApi } = useApis()

  const handlePrePayRouting = (bin = '') => {
    const {
      orderAttributesList,
      orderCurrency,
      orderUsdAmount,
      orderAmount,
      shipCountry,
      virtualOrderScene,
      routeCode,
    } = prePayCheckoutInfo.value || {}

    const params = {
      bin,
      orderAttributesList,
      orderCurrency,
      orderUsdAmount,
      orderAmount,
      shipCountry,
      routeCode,
    }
    if (virtualOrderScene) Object.assign(params, { virtualOrderScene })
    return fetchPrePayRoutingForCheckoutApi(params).then(data => {
      const routingInfo = data?.info || {}
      // mock
      // if (params.bin === '55554444') {
      //   data.info = { ...data.info, isCardHoldName: true, isRememberCard: false }
      // }
      debuggerLog('handlePrePayRouting===info==', data)

      // 修正联名卡类型的卡logo，不是中间层接口，所以写死logo，PC端没有联名卡需求
      if (appConfigs.$envs.project === 'pwa' && routingInfo?.coBrandLevel && CO_BRAND_LEVEL_LIST.includes(Number(routingInfo.coBrandLevel))) {
        routingInfo.webLogo = CO_BRAND_CARD_LOGO_THUMBNAIL
      }

      return routingInfo
    })
  }

  return {
    handlePrePayRouting,
  }
}

// eslint-disable-next-line max-lines-per-function
export const usePrePayModal = (props, { emit }) => {
  let cacheBin = ''

  const appConfigs = useAppConfigs()
  const prePayStore = usePrePayStore()
  const paymentsStore = usePaymentsStore()
  const { handlePrePayRouting } = usePrePayModalFecth()
  const { prePayCheckoutInfo, cardBinRoutingInfo, prePayUnPayParams, installmentList, initInstallmentList, recommendInstallments } = toRefs(prePayStore)
  const language = toRef(paymentsStore, 'language')
  const paySafeIconsList = computed(() => paymentsStore?.paySafeIconsList || [])
  // const prePayEventBus = prePayStore.prePayEventBus ?? {}
  const { payDomainLogoList: paymentPageLogoList } = toRefs(prePayStore.preFetchInfo)
  const project = computed(() => appConfigs.$envs.project)

  // 是否需要展示计价后的卡bin优惠信息
  const operVisibleCardBinDiscount = ref(false)
  const updateCheckoutComplete = ref(true)
  // 是否联名卡权益文案展示在卡号信息下方
  const isCoBrandedBenefitsUnderCardNo = ref(false)

  const showCouponsDrawer = ref(false)
  const billno = ref(prePayCheckoutInfo?.value?.billno || '')

  const visiblePrePayPopup = computed({
    get () {
      return props.visible
    },
    set (bool) {
      emit('update:visible', !!bool)
    },
  })

  const defaultBinDiscountInfo = computed(() => {
    const { binDiscountInfo = {} } = paymentsStore.selectedPayment || {}
    return operVisibleCardBinDiscount?.value ? {} : binDiscountInfo
  })

  const binDiscountCardTokenInfo = computed(() => {
    const preDiscountCardToken = paymentsStore.selectedPayment.binDiscountCardToken || {}
    // const { cardTokenList = [] } = paymentsStore.checkoutInfo || {}
    debuggerLog('binDiscountCardTokenInfo==binDiscountCardToken===', preDiscountCardToken, operVisibleCardBinDiscount.value)
    if (prePayStore.prePayCheckoutInfo.billno) {
      return operVisibleCardBinDiscount.value && prePayStore.orderCardBinDiscountInfo?.binDiscountTip ? prePayStore.orderCardBinDiscountInfo : {}
    }
    return operVisibleCardBinDiscount.value && updateCheckoutComplete.value && preDiscountCardToken ? preDiscountCardToken : {}
  })

  // 当前卡bin 支持的卡 bin 优惠券
  const paymentsPreferentialTipsItem = computed(() => paymentsStore?.selectedPayment?.paymentsPreferentialTips?.find(item => item?.type == CARD_BIN_COUPONS_TYPE))

  // 联名卡权益文案
  const coBrandCardPreferentialTips = computed(() => paymentsStore?.selectedPayment?.paymentsPreferentialTips?.find(item => item?.type == CO_BRAND_CARD_TIPS_TYPE))

  const paymentFormRef = ref()
  const installmentRef = ref()

  const handlePrePayBinChangeForCheckout = async (bin = '') => {
    prePayStore.updateState('cardBinRoutingInfo', {})
    prePayStore.mergeState('prePayUnPayParams', {
      cardBin: '',
      routeId: '',
      cardType: '',
      paymentCode: '',
    })
    await handlePrePayRouting(bin).then(routingInfo => {
      prePayStore.updateState('cardBinRoutingInfo', routingInfo || {})
      prePayStore.mergeState('prePayUnPayParams', {
        cardBin: routingInfo.bin,
        routeId: routingInfo.routeId,
        paymentCode: routingInfo.payMethod,
        countryCode: routingInfo.orderCountry || prePayCheckoutInfo.value?.shipCountry || '',
        cardType: routingInfo.cardType,
      })
    })
    const routingCardBin = prePayUnPayParams.value.cardBin
    prePayStore.mergeState('ddcFormParams', { bin: routingCardBin })
    emit('handle-action', {
      action: 'updateCheckout',
      payload: {
        params: {
          usedCardBin: routingCardBin,
          cardPaymentFront: 1,
        },
        // need_select: 1,
        // bins: [routingCardBin],
        cb: ({ status }: { status: 'success' | 'fail', result?: any }) => {
          updateCheckoutComplete.value = true
          if (status === 'success') {
            prePayStore.getInstallmentInfo({
              cardBin: prePayUnPayParams.value?.cardBin,
              cardType: prePayUnPayParams.value?.cardType,
            }).then(installmentResInfo => {
              const { installments = [] } = installmentResInfo || {}
              debuggerLog('handlePrePayBinChangeForCheckout==installmentList===', installments)
              prePayStore.updateState('installmentList', installments)
              prePayStore.updateState('isShowInstallmentTipsShow', (installments || [])?.length === 0 || (installments?.length === 1 && installments?.[0]?.stage_num == 1))
              setTimeout(() => {
                prePayStore.updateState('isShowInstallmentTipsShow', false)
              }, 3000)
            })
          }
        },
      },
    })
  }

  const handleCardBinChange = async (bin = '') => {
    debuggerLog('handleCardBinChange==bin===', bin, initInstallmentList)
    if (!bin) {
      // 清除前置路由数据及卡bin信息
      prePayStore.updateState?.('cardBinRoutingInfo', {})
      prePayStore.mergeState?.('prePayCheckoutInfo', { cardBinDiscountInfo: {} })
      // prePayStore.updateState?.('displayCardBinDiscountInfo', {})
      operVisibleCardBinDiscount.value = false
      isCoBrandedBenefitsUnderCardNo.value = false
      prePayStore.mergeState?.('prePayUnPayParams', {
        ...prePayStore.prePayUnPayParams,
      })
      cacheBin = ''

      prePayStore.updateState('installmentList', initInstallmentList?.value || [])

      return
    }
    if (bin && bin.length < 8) {
      prePayStore.updateState('installmentList', initInstallmentList?.value || [])
      // if (cacheBin)
      operVisibleCardBinDiscount.value = false
      isCoBrandedBenefitsUnderCardNo.value = false
      return
    }
    if (cacheBin === bin) {
      operVisibleCardBinDiscount.value = true
      isCoBrandedBenefitsUnderCardNo.value = true
      return
    }
    cacheBin = bin
    updateCheckoutComplete.value = false
    operVisibleCardBinDiscount.value = true
    isCoBrandedBenefitsUnderCardNo.value = true

    billno.value = prePayCheckoutInfo.value?.billno || ''

    // 单后场景
    if (prePayCheckoutInfo.value?.billno) {
      prePayStore.handlePrePayBinChangeForUnPay(bin)
    } else {
      // 非单后场景
      handlePrePayBinChangeForCheckout(bin)
    }
  }

  const handleActivelyClose = () => {
    // TODO
    // SIMetric.metricCount({
    //   metric_name: 'web_payment_error_total', // 指标名称
    //   tags: { // 指标维度
    //     sub_site: gbCommonInfo?.SiteUID || '',
    //     error_scene: 'pre_pay_actively_close',
    //     product_type: 'normal',
    //   },
    //   message: `billno: ${prePayCheckoutInfo.value?.billno}`,
    // })
    prePayStore.updateState('prePayUnPayParams', getDefaultPrePayUnPayParams())
    prePayStore.updateState('installmentList', [])
    prePayStore.mergeState?.('prePayCheckoutInfo', { cardBinDiscountInfo: {} })
    prePayStore.updateState('cardBinRoutingInfo', {})
    paymentFormRef.value?.resetForm?.()
    installmentRef.value?.reset?.()

    cacheBin = ''

    const billno = prePayCheckoutInfo.value?.billno
    const actions: { action: Trade_PayToolKit.BsPaymentAction; payload: any }[] = [
      { action: 'updateTotalPrice', payload: { totalAmountWithSymbol: '' } },
      { action: 'appendSortedPrice', payload: { appendPrice: [] } },
    ]

    if (!billno) {
      actions.push({ action: 'updateCheckout', payload: { params: { usedCardBin: '', cardPaymentFront: 0 } } })
    } else {
      actions.push({ action: 'prePayActiveClose', payload: { billno } })
    }

    actions.forEach(action => {
      emit('handle-action', action)
    })

    emit('actively-close')
  }

  const handleSelectInstallment = (installmentInfo: Trade_PayToolKit.InstallmentInfoItem | Record<string,never> = {}) => {
    prePayStore?.mergeState('prePayUnPayParams', {
      ...prePayStore?.prePayUnPayParams,
      installments: installmentInfo?.stage_num,
      installmentsInfo: installmentInfo,
    })
    // 'installment_fee', 'stage_num'
    const installmentSortPrice = [
      { type: 'installment_fee', local_name: project.value === 'pwa' ? paymentsStore.language?.BS_KEY_PAY_1191 : paymentsStore.language?.SHEIN_KEY_PC_16443},
      { type: 'stage_num', local_name: project.value === 'pwa' ? paymentsStore.language?.BS_KEY_PAY_1190 : paymentsStore.language?.SHEIN_KEY_PC_17705 },
    ].map(item => ({
      type: item.type,
      show: 1,
      price_with_symbol: installmentInfo[item.type],
      amount: installmentInfo[item.type],
      local_name: item.local_name,
    }))

    const actions = [
      { action: 'updateTotalPrice', payload: { totalAmountWithSymbol: installmentInfo?.total_amount } },
      { action: 'appendSortedPrice', payload: { appendPrice: installmentSortPrice } },
    ]
    actions.forEach(action => emit('handle-action', action))
  }

  const handlerPrePayCouponDrawer = (status: boolean) => {
    showCouponsDrawer.value = status
  }

    // 不使用优惠券
  const confirmNotUseCoupon = () => {
    debuggerLog('confirmNotUseCoupon >>>>')
    paymentsStore?.paymentEventBus?.paymentInfoChange?.emit?.({
      extraInfo: {
        isNeedPostCheckout: true,
        isNeedPostCoupon: true, // 需要重新获取券列表数据
        changeCheckData: {
          data: {
            add_coupon: '',
            coupon_list: [],
          },
        },
      },
    })
  }

  const paymentSecurityAbtInfo = computed(() => {
    return paymentsStore.abtInfo?.PaymentSecurity?.param?.PaymentSecurity || ''
  })

  const isUseNewPaymentSecurity = computed(() => paymentSecurityAbtInfo.value === 'Type_A')

  onBeforeUnmount(() => {
    prePayStore.mergeState?.('prePayCheckoutInfo', { cardBinDiscountInfo: {}, routeCode: '' })
    prePayStore.updateState('cardBinRoutingInfo', {})
    prePayStore.updateState('displayCardBinDiscountInfo', {})
    paymentFormRef.value?.resetForm?.()
    installmentRef.value?.reset?.()
  })

  return {
    visiblePrePayPopup,
    paymentPageLogoList,
    language,
    // scene: prePayStore.scene,
    // abtData,
    // prePaymentInfo,
    defaultBinDiscountInfo,
    binDiscountCardTokenInfo,
    isCoBrandedBenefitsUnderCardNo,
    cardBinRoutingInfo,
    prePayCheckoutInfo,
    paySafeIconsList,
    paymentFormRef,
    installmentRef,
    handlePrePayRouting,
    handleCardBinChange,
    handleActivelyClose,
    handleSelectInstallment,
    installmentList,
    showCouponsDrawer,
    handlerPrePayCouponDrawer,
    confirmNotUseCoupon,
    paymentsPreferentialTipsItem,
    coBrandCardPreferentialTips,
    billno,
    paymentSecurityAbtInfo,
    isUseNewPaymentSecurity,
    recommendInstallments,
  }
}
