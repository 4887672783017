<template>
  <div class="payment-form-item">
    <BaseFormItem
      ref="baseFieldRef"
      v-model="fieldValue"
      inputmode="text"
      :placeholder="placeholder"
      :label="label"
      :required="isRequired"
      :clearable="true"
      :maxlength="maxLength"
      :show-tips="showTips"
      @change="handleValueInput"
      @blur="handleValueBlur"
      @click-tips="emits('click-tips')"
    />
    <div
      v-show="errorTips"
      class="error-invalid"
    >
      {{ errorTips }}
    </div>
  </div>
</template>

<script setup lang="ts" name="CardCpfField">
import { watch, ref, computed } from 'vue'
import BaseFormItem from './BaseFormItem.vue'
import { cpfValidator } from '../utils/validator'
import { FORMAT_TAX_NUMBER } from '../utils/constants'

interface Props {
  modelValue: string; // 值
  errorMessage?: string; // 错误提示信息
  required?: boolean; // 是否必填
  label?: string; // 标签文本
  placeholder?: string; // 输入框占位文本
  clearable?: boolean; // 是否显示清除按钮
  formatter?: (value: string) => string; // 指定输入值格式化函数
  validator?: (value: string) => boolean; // 自定义校验规则
  documentRule?: string | RegExp; // cpf规则
  paymentCode?: string; // 真实路由到的支付方式
  showTips?: boolean; // 是否显示提示
}

const emits = defineEmits(['update:modelValue', 'click-tips'])
const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  documentRule: '', // cpf规则
  paymentCode: '',
  showTips: false,
})

// const props = defineProps({
//   value: {
//     type: String,
//     default: '',
//   },
//   required: {
//     type: Boolean,
//     default: false,
//   },
//   documentConfig: {
//     type: Object,
//     default: () => ({
//       placeholder: '',
//       rule: '',
//       title: '',
//     }),
//   },
//   errorMessage: {
//     type: String,
//     default: '',
//   },
//   paymentCode: { // 真实路由到的支付方式
//     type: String,
//     default: '',
//   },
// })


const baseFieldRef = ref()

const fieldValue = ref(props.modelValue)

const errorTips = ref('')
const maxLength = ref(99)

watch(() => props.modelValue, val => {
  fieldValue.value = val
})

const isRequired = computed(() => !!cpfValidator({ cpf: '', rule: props.documentRule as string }))

// ebbc时格式化税号
const formatCpf = evt => {
  if (!FORMAT_TAX_NUMBER.includes(props.paymentCode)) {
    return evt?.target?.value
  }
  const filterVal = evt?.target?.value.replace(/[^0-9Kk]/g, '')
  let splitMap = [
    { cursor: 1, split: '.' },
    { cursor: 4, split: '.' },
    { cursor: 7, split: '-' },
  ]
  if (filterVal.length === 9) {
    splitMap.forEach(v => v.cursor += 1)
  }
  return filterVal.split('').map((v, i) => {
    const matchSplit = splitMap.find(v => v.cursor === i)
    return `${matchSplit?.split || ''}${v}`
  }).join('')
}

const handleValueInput = ev => {
  fieldValue.value = ev.target.value = formatCpf(ev)
  emits('update:modelValue', fieldValue.value)
}

const handleValueBlur = () => {
  validator()
  emits('update:modelValue', props?.required ? fieldValue.value : '')
}

const validator = () => {
  if (!props?.required) {
    errorTips.value = ''
    return {
      result: true,
      failType: null,
      failReason: null,
    }
  }
  const cpf = fieldValue.value?.trim?.() || ''
  const valid = cpfValidator({ cpf, rule: props.documentRule as string })
  // errorTips.value = !valid ? '' : template(props.documentConfig.title, props.language?.BS_KEY_PAY_1166)
  errorTips.value = !valid ? '' : (props.errorMessage || '')
  return {
    result: !valid,
    failType: valid,
    failReason: null,
    reasonType: 'cpf',
  }
}

const reset = () => {
  fieldValue.value = ''
  errorTips.value = ''
  emits('update:modelValue', '')
}

defineExpose({
  validator,
  reset,
  focus: () => baseFieldRef.value.focus(),
})

</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
  font-size: @font-size * 0.5;
}

.payment-form-item {
  padding: 24/75rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24/75rem;
    right: 0;
    height: 1px;
    background-color: var(--sui_color_gray_light2, #e5e5e5);
  }
  &.no-underline {
    &:after {
      display: none;
    }
  }
  .error-invalid {
    font-size: 12px;
    line-height: 1;
    color: var(--sui_color_promo, #FA6338);
    margin-top: 8/75rem;
    transition: height 0.8s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
