<template>
  <div class="logo-list-wrap">
    <div
      v-for="(item, index) in logoList"
      :key="`logo__${index}`"
      class="logo-item"
    >
      <img
        :src="replaceUrlProtocol(item.url)"
        alt=""
      />
    </div>
  </div>
</template>

<script setup>
import { replaceUrlProtocol } from '../../../../utils'

defineProps({
  logoList: {
    type: Array,
    default: () => [],
  },
})
</script>

<style lang="less" scoped>
.logo-list-wrap {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 24/75rem 0;
  &::-webkit-scrollbar {
    display: none;
  }
  .logo-item {
    flex-shrink: 0;
    height: 50/75rem;
    width: auto;
    margin-right: 16/75rem;
    &:first-child {
      margin-left: 24/75rem;
    }
    img {
      width: auto;
      height: 100%;
    }
  }
}
</style>
