// import apiReport from 'public/src/pages/common/apiReport'
import { ref } from 'vue'
import { debuggerLog } from '../utils'

export const useDdc = () => {
  let actionHost = ''

  const ddcSendUtils: {
    callback: (sessionId: string) => void,
    submit: () => void
  } = {
    callback: () => { },
    submit: () => {},
  }

  const ddcTime = ref<{
    sessionId: string,
    timer: ReturnType<typeof setTimeout> | null,
    timeout: number,
    Status: boolean | null,
    sessionTimeStart: number | null,
  }>({
    sessionId: '',
    timer: null,
    timeout: 5 * 1000, //10秒超时
    Status: null,
    sessionTimeStart: Date.now(),
  })

  const ddcResult = ref({
    sessionId: '',
  })
  const submitValid = ref(false)

  function _updateDDC() {
    // // 如果卡bin不是数字，则走降级
    // if (!cardBin || !/^[0-9]*$/.test(`${cardBin}`)) return false
    // // @ts-ignore
    // document.getElementById('collectionForm').action = jsonWebToken.value.formActionUrl
    // if (document.querySelector('#collectionForm input[name=\'Bin\']')) {
    //   // @ts-ignore
    //   document.querySelector('#collectionForm input[name=\'Bin\']').value = bin
    // }
    // // @ts-ignore
    // document.getElementById('collectionForm input[name=\'JWT\']').value = jsonWebToken.value.jwt
    return true
  }

  function _ddcSend() {
    ddcTime.value.sessionId = ''
    submitValid.value = true
    const result = _updateDDC()
    if (result) {
      //若超时，则走降级
      // 补偿请求时，如果已有定时器，需清除
      if (ddcTime.value.timer && ddcTime.value.Status !== null) {
        clearTimeout(ddcTime.value.timer)
        ddcTime.value.timer = null
      }
      ddcTime.value.timer = setTimeout(function () {
        if (!ddcTime.value.sessionId) {
          // window.ocpEventCenter?.$emit('ddc-submit', '')
          ddcSendUtils.callback?.('')
          ddcTime.value.Status = null // 重置状态，用于再次补偿
          submitValid.value = false
        }
      }, ddcTime.value.timeout)

      if (ddcTime.value.Status === null) {
        ddcTime.value.sessionTimeStart = Date.now()
      }
      ddcSendUtils.submit?.()
    } else {
      ddcSendUtils.callback?.('')
      // window.ocpEventCenter?.$emit('ddc-submit', '')
    }
  }

  const ddcEventHandle = event => {
    debuggerLog('event.origin', event.origin)
    if (event.origin === actionHost) {
      if (!submitValid.value) return
      ddcTime.value.timer && clearTimeout(ddcTime.value.timer)
      let data
      try {
        data = JSON.parse(event.data)
      } catch (e) {
        debuggerLog(e)
      }
      if (data && typeof data === 'object') {
        if (!data.SessionId || !data.Status) {
          getSessionIdErrorReport(data)
        }
        if (data.Status) {
          getSessionIdTimeReport(data)
          ddcTime.value.sessionId = data.SessionId
          ddcResult.value.sessionId = data.SessionId
          // window.ocpEventCenter?.$emit('ddc-submit', data.SessionId)
          ddcSendUtils.callback?.(data.SessionId)
          ddcTime.value.Status = null // 重置状态，用于再次补偿
          submitValid.value = false
        } else {
          // 如果第一次Status为false，应补偿请求一次
          if (ddcTime.value.Status === null) {
            _ddcSend()
          } else {
            getSessionIdTimeReport(data)
            // window.ocpEventCenter?.$emit('ddc-submit', '')
            ddcSendUtils.callback?.('')
            ddcTime.value.Status = null // 重置状态，用于再次补偿
            submitValid.value = false
          }
        }
        ddcTime.value.Status = !!data.Status
      } else {
        // window.ocpEventCenter?.$emit('ddc-submit', '')
        ddcSendUtils.callback?.('')
        submitValid.value = false
        getSessionIdTimeReport()
        getSessionIdErrorReport()
      }
    }
  }

  function _initListenerDDC() {
    window.removeEventListener('message', ddcEventHandle)
    window.addEventListener('message', ddcEventHandle)
  }

  function getSessionIdErrorReport(sessionInfo?) {
    debuggerLog('getSessionIdErrorReport', sessionInfo)
    // apiReport.report({
    //   apiPath: '/pay/formActionUrl/sessionid',
    //   payment_method: '',
    //   billno: '',
    //   sessionid:
    //     (sessionInfo && sessionInfo.SessionId) || 'error_sessionid_null',
    //   Status: (sessionInfo && sessionInfo.Status) || 'error_status_false'
    // })
  }
  function getSessionIdTimeReport(data?) {
    debuggerLog('getSessionIdTimeReport', data)
    // apiReport.report({
    //   apiPath: '/pay/formActionUrl/sessionidtime',
    //   payment_method: '',
    //   billno: '',
    //   process_time: Date.now() - ddcTime.value.sessionTimeStart,
    //   error_data_err_result: data?.SessionId ? 'true' : 'false'
    // })
    ddcTime.value.sessionTimeStart = null
  }

  function getActionHost ({ action = '' } = {}) {
    try {
      const wpURL = new URL(action)
      return wpURL.protocol + '//' + wpURL.host
    } catch (e) {
      const reg =
        /((https?):\/\/)?[-A-Za-z0-9+&@#\/%?=~_|!:,.;]+\.[-A-Za-z+]+\/+/g
      const hostUrl = action.match?.(reg)?.[0]
      return hostUrl
        ? hostUrl.substring(0, hostUrl.length - 1)
        : ''
    }
  }

  function sendDdc({
    params,
    submit,
    callback,
  }: { params: {
    cardBin: string;
    jwt: string;
    action: string;
  }; callback: (sessionId: string) => void; submit: () => void }) {
    // console.log('sendDdc=====', params)
    actionHost = getActionHost(params)
    ddcSendUtils.submit = submit
    ddcSendUtils.callback = (...args) => {
      window.removeEventListener('message', ddcEventHandle)
      console.log('ddcSendUtils.callback=====', args)
      return callback?.(...args)
    }
    _initListenerDDC()
    _ddcSend()
  }

  return {
    sendDdc,
  }
}
