<template>
  <div class="otp-input-content">
    <div class="otp-input-content__header">
      <div class="title"> {{ languageInfo.BS_KEY_SRC_1025 }} </div>
      <div class="tips">
        <span>
          {{ languageInfo.BS_KEY_SRC_1026 }}
          <span class="highlight"> {{ maskedValidationChannel }} </span>
          <span
            class="not-you"
            @click="handlerNoYou"
          >{{ languageInfo.BS_KEY_SRC_1027 }}</span>
        </span>
      </div>
      <div
        v-if="isCanChangeVerificationType"
        class="receive-verification"
        @click="changeVerificationType"
      >{{ receiveVerificationTipText }} </div>

      <CodeNumberInput
        ref="codeNumberInputRef"
        class="otp-code-number"
        @submit="handlerValidateCode"
      />

      <div
        class="resend"
        @click="reSend"
      >
        <span
          v-if="isRunning"
          class="time">{{ remainingTime }}s</span>
        <span v-else>{{ languageInfo.BS_KEY_SRC_1030 }}</span>
      </div>

      <div class="remember">
        <SRadio
          v-model="nowRadio"
          class="payment-item-radio"
          toggle
          label="remember"
        />
        <span class="tip-text">{{ languageInfo.BS_KEY_SRC_1037 }}</span>
        <Icon
          @click="handleClickTips"
          name="sui_icon_info_12px_2"
          size="12px"
        />
      </div>
    </div>

    <div class="otp-input-content__footer">
      <div class="card-logo-list">
        <SrcCardList :cardTypeList="availableCardBrands" />
      </div>

      <div class="or">
        <span class="tip">or </span>
      </div>

      <div
        class="enter-card-manually"
        @click="handleEnterCardManually"> {{ languageInfo.BS_KEY_SRC_1031 }} </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import CodeNumberInput from './CodeNumberInput.vue'
import { SRadio } from '@shein-aidc/sui-radio/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import SrcCardList from '../src-card-list/index.vue'
import { useCountdown } from '../../hooks/useCountdown'

const props = defineProps({
  languageInfo: {
    type: Object,
    default: () => ({}),
  },
  c2pInfo: {
    type: Object,
    default: () => {},
  },
  initiateValidationInfo: {
    type: Object,
    default: () => {},
  },
  // supportedValidationChannelsItem: {
  //   type: Object,
  //   default: () => {},
  // },
  isUseEmailValidation: {
    type: Boolean,
    default: false,
  },
  defaultRemember: {
    type: Boolean,
    default: false,
  },
  cardTypeList: {
    type: Array,
    default: () => [],
  },
})

const emits = defineEmits([
  'handleInitiateValidation',
  'close',
  'not-you',
  'enter-card-manually',
  'validate-result',
  'click-tips',
])

const {
    remainingTime,
    isRunning,
    resetTime,
    start,
} = useCountdown({
    seconds: 60,
})

const nowRadio = ref(props.defaultRemember ? 'remember' : '') // 'remember' | ''
const codeNumberInputRef = ref(null)

const receiveVerificationTipText = computed(() => {
  return props.isUseEmailValidation
    ? props.languageInfo.BS_KEY_SRC_1029
    : props.languageInfo.BS_KEY_SRC_1028
})

const maskedValidationChannel = computed(() => {
  const { maskedValidationChannel = '' } = props.initiateValidationInfo || {}
  return maskedValidationChannel
})

const isCanChangeVerificationType = computed(() => {
  return (props.initiateValidationInfo?.supportedValidationChannels || []).length > 1
})

const availableCardBrands = computed(() => {
  if (props?.cardTypeList?.length > 0) {
    return props.cardTypeList
  }
  return props?.c2pInfo?.availableCardBrands || []
})

const supportedValidationChannelsItem = computed(() => {
    return (props.initiateValidationInfo?.supportedValidationChannels || []).find(
      item => item?.maskedValidationChannel !== props.initiateValidationInfo?.maskedValidationChannel,
    )
})

const handlerNoYou = () => {
    props?.c2pInfo?.showOtpValidateDrawer?.()
    props.c2pInfo?.closeC2pAlertDrawer?.()
    codeNumberInputRef.value?.resetData()
    emits('not-you')
}

const handleClickTips = () => {
  emits('click-tips')
}

const handleEnterCardManually = () => {
  codeNumberInputRef.value?.resetData()
  emits('enter-card-manually')
}

const reSend = () => {
    if (isRunning.value) return

    if (supportedValidationChannelsItem.value?.validationChannelId) {
        resetTime()
        start()
        emits('handleInitiateValidation', { requestedValidationChannelId: supportedValidationChannelsItem.value?.validationChannelId })
    }
}

// 切换获取验证的方式 SMS/Email
const changeVerificationType = () => {
    if (supportedValidationChannelsItem.value?.validationChannelId) {
        codeNumberInputRef.value?.resetData()
        emits('handleInitiateValidation', { requestedValidationChannelId: supportedValidationChannelsItem.value?.validationChannelId })
    }
}

const validateCodeCb = (info) => {
    props.c2pInfo?.setRememberMe?.(nowRadio.value === 'remember')

    if (info?.tokenList) {
        props.c2pInfo?.setC2pTokenList?.(info?.tokenList || [])
        // SToast({ content: info?.tokenList?.length > 0 ? props.languageInfo?.BS_KEY_SRC_1045 : props.languageInfo?.BS_KEY_SRC_1051 })
    }

    if (info?.status != 'success') {
      codeNumberInputRef?.value?.setErrInfo({
        errTip: 'Incorrect verification code',
      })
    } else {
      emits('close')
    }
    emits('validate-result', {
      ...info,
      rememberMe: nowRadio.value === 'remember',
    })
}

const handlerValidateCode = async ({ verificationCode = '' } = {}) => {
    if (!verificationCode) {
        return
    }

    try {
        const info = await props.c2pInfo?.validate?.({ value: verificationCode })

        validateCodeCb(info)
    } catch (e: any) {
        console.log('handlerValidateCode error====', e?.reason, e?.message, e?.details)
        SToast({ content: props.languageInfo?.BS_KEY_SRC_1052 })
        codeNumberInputRef?.value?.setErrInfo({
            errTip: 'Incorrect verification code',
        })
    }
}

const reset = () => {
  codeNumberInputRef.value?.resetData()
}

const init = () => {
  resetTime()
  start()
  reset()
}

onMounted(() => {
  start()
})

defineExpose({
  reset,
  init,
})
</script>

<style lang="less" scoped>
.otp-input-content {
  padding: 0 24 * 2/75rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    color: #222222;
    font-size: 16px;
    font-weight: 700;
    word-wrap: break-word;
    text-align: center;
  }

  .tips {
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
    padding: 8 * 2/75rem 0;

    .highlight {
      color: black;
      font-size: 14px;
      font-weight: 590;
      word-wrap: break-word;
    }

    .not-you {
      text-decoration: underline;
      word-wrap: break-word;
      color: #2d68a8;
      margin-left: 4*2/75rem;
    }
  }

  .receive-verification {
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
  }

  .otp-code-number {
    margin: 24 * 2/75rem 0 16 * 2/75rem 0;
  }

  .resend {
    text-align: center;
    color: #bbbbbb;
    font-size: 12px;
    font-weight: 700;
    word-wrap: break-word;
    margin-bottom: 8 * 2/75rem;

    .time {
      color: #222222;
      font-size: 14px;
      font-weight: 600;
      word-wrap: break-word;
    }
  }

  .remember {
    display: flex;
    align-items: center;
    color: #959595;
    font-size: 12px;
    font-weight: 400;
    word-wrap: break-word;

    .tip-text {
      margin-right: 4 * 2/75rem;
    }
  }

  .card-logo-list {
    display: flex;
    justify-content: center;
    margin-top: 24 * 2/75rem;
  }

  .or {
    color: #959595;
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 8 * 2/75rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .tip {
      position: relative;
      padding: 0 12 * 2/75rem;

      &::before,
      &::after {
        content: '';
        flex: 1;
        position: absolute;
        top: 50%;
        height: 1px;
        background: #e5e5e5; // 线的颜色
        width: 145 * 2/75rem; // 线的长度
      }

      &::before {
        left: -(145 * 2/75rem); // 向左延伸
      }

      &::after {
        right: -(145 * 2/75rem); // 向右延伸
      }
    }
  }

  .enter-card-manually {
    // Enter card manually
    color: #2d68a8;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    word-wrap: break-word;
    text-align: center;
  }
}
</style>
