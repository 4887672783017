<template>
  <div
    v-if="isShowCardBinDiscount"
    class="payment-discount-label"
  >
    <SAdapterText
      class="label-adapter-text"
      min-size="10"
      :text="text"
    />
  </div>
</template>

<script setup name="DiscountLabel" lang="ts">
import { computed } from 'vue'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'
const props = defineProps({
  text: {
    type: String,
    default: '',
  },
})

const isShowCardBinDiscount = computed(() => {
  return (props.text || '')?.length > 0
})
</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}

.payment-discount-label {
  box-sizing: border-box;
  margin: 0 0.32rem;
  background: var(---sui_color_promo_bg, #fff6f3);
  border: 0.5px solid rgba(196, 74, 1, 0.3);
  padding: 6/75rem 12/75rem;
  color: var(---sui_color_promo_dark, #c44a01);
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  max-width: 100%;
  .label-adapter-text {
    line-height: inherit;
    font-weight: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
