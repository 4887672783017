<template>
  <component
    :is="tagType"
    v-if="show"
    class="c-icon-tip"
  >
    <span>
      <Icon
        name="sui_icon_doubt_16px_2"
        color="#959595"
        :size="`${iconSize}px`"
        :is-rotate="cssRight"
        @click.stop="handleClickDoubt"
      />
    </span>
    <slot>
    </slot>
  </component>
</template>

<script name="PaymentTips" setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue3'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

interface PaymentTipsProps {
  show?: boolean
  tagType?: string
  iconSize?: number
}

withDefaults(defineProps<PaymentTipsProps>(), {
  show: false,
  tagType: 'div',
  iconSize: 16,
})

const emits = defineEmits(['click-doubt'])
const appConfigs = useAppConfigs()
const { cssRight } = appConfigs?.$envs || {}

const handleClickDoubt = () => {
  emits('click-doubt')
}
</script>

<style lang="less" scoped>
.c-icon-tip {
  margin-left: 4/37.5rem;
}
</style>
