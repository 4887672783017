<template>
  <div>
    <!-- 3ds1.0 -->
    <SDrawer
      :visible="visibleOptions.threeDS1ChallengeShow"
      :show-close="false"
      append-to-body
      :close-on-click-modal="false"
    >
      <template #title></template>
      <template #footer></template>
      <div
        :id="paramOptions.threeDS1DomId"
        class="three-ds1-challenge__iframe"></div>
    </SDrawer>
    <!-- 3ds2.0 -->
    <SDrawer
      :visible="visibleOptions.threeDS2ChallengeShow"
      :show-close="false"
      append-to-body
      :immediately-render="true"
      :close-on-click-modal="false"
      size="80%"
      class="three-ds2-challenge__drawer"
    >
      <template #title></template>
      <template #footer></template>
      <div class="challenge-wrap">
        <div
          :id="paramOptions.threeDS2DomId"
          class="three-ds2-challenge"></div>
      </div>
    </SDrawer>
  </div>
</template>

<script setup lang="ts" name="ChannelChallengePopup">
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'

withDefaults(defineProps<{
  visibleOptions: {
    threeDS1ChallengeShow: boolean
    threeDS2ChallengeShow: boolean
  };
  paramOptions: {
    threeDS1DomId: string;
    threeDS2DomId: boolean
  }
}>(), {
  visibleOptions: () => ({
    threeDS1ChallengeShow: false,
    threeDS2ChallengeShow: false,
  }),
})

</script>

<style lang="less">
.three-ds2-challenge__drawer {
  .challenge-wrap {
    min-height: 100%;
    background-color: @sui_color_white;
    display: flex;
  }
  // stylelint-disable-next-line selector-max-specificity
  .three-ds2-challenge {
    flex: 1;
    // stylelint-disable-next-line selector-max-specificity
    > div {
      height: 100%;
    }
    // stylelint-disable-next-line
    .adyen-checkout__threeds2__challenge {
      height: 100%;
    }
  }
}
</style>
