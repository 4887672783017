<template>
  <SDrawer
    v-model:visible="show"
    class="bin-coupons-drawer"
    direction="btt"
    append-to-body
    @close-from-icon="close"
    @close-from-mask="close"
  >
    <template #title>
      <div>
        <div class="now-coupon">
          <span
            ref="discountRef"
            class="now-coupon-content">
            {{ discount }}
          </span>
        </div>
      </div>
    </template>

    <div class="bin-coupons-content">
      <div class="header-title">
        <p
          ref="titleRef"
          class="title">
          {{ nowTitle }}
        </p>
        <p
          ref="tipTitleRef"
          class="tip-title"
          v-html="nowSubTitle"> </p>
      </div>

      <SelectedBinSupportContent
        :now-err-coupon-bin="nowErrCouponBin"
        :selected-pay="selectedPay"
        :payment-methods="paymentMethods"
        :now-error-coupon="nowErrorCoupon"
        :bin-coupons-drawer-state="binCouponsDrawerState"
        @handlerSelected="handlerSelected"
        @changeIsShowSubNormalTitleText="changeIsShowSubNormalTitleText"
        @tokenNumChange="tokenNumChange"
      />
    </div>

    <template #footer>
      <SButton
        width="4.3rem"
        @click="close">
        {{ language.BS_KEY_PAY_1193 }}
      </SButton>

      <SButton
        width="4.3rem"
        :type="['primary']"
        @click="handlerConfirm">
        {{ language.BS_KEY_PAY_1194 }}
      </SButton>
    </template>
  </SDrawer>
</template>

<script setup lang="ts">
/**
 * 卡bin优惠券，切换token弹窗
 */
import { SToast as $toast } from '@shein-aidc/sui-toast/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import SelectedBinSupportContent from './SelectedBinSupportContent.vue'
import { template } from '@shein/common-function'
import { ref, computed, watch, nextTick } from 'vue'
import { debuggerLog } from '../../../utils'
// import type { AS_PayToolkit } from '../../../types'
// const { triggerNotice } = inject('analysisInstance', null) as AS_PayToolkit.AnalysisInstance

const props = defineProps({
  language: {
    type: Object,
    default: () => {},
  },
  isShowBinCouponsDrawer: {
    type: Boolean,
    default: false,
  },
  paymentInfo: {
    type: Object,
    default: () => {},
  },
  selectedPayment: {
    type: Object,
    default: () => {},
  },
  couponListState: {
    type: Object,
    default: () => {},
  },
  nowUseCouponInfo: {
    type: Array,
    default: () => [],
  },
  binCouponsDrawerState: {
    type: Object,
    default: () => {},
  },
})

const show = ref(false)
const selectedBin = ref(null)
const discountRef = ref<Element>()
const titleRef = ref<Element>()
const tipTitleRef = ref<Element>()
const selectedPay = ref(null)
const isShowSubNormalTitleText = ref(false)
const numToken = ref({})

const paymentMethods = computed(() => {
  debuggerLog('props.paymentInfo >>>>>>', props.paymentInfo, props?.paymentInfo?.payments)
  return props?.paymentInfo?.payments || []
})

const nowErrorCoupon = computed(() => {
  return props.nowUseCouponInfo?.[0] || {}
})

const subTitleText = computed(() => {
  let cardName = nowErrorCoupon.value?.businessExtension?.order?.card?.cardName || ''
  return template(`<span class='tip-title__card'>${cardName}</span>`, props.language.BS_KEY_PAY_1196)
})

const nowSubTitle = computed(() => {
  return isShowSubNormalTitleText.value ? subTitleText.value : `<span>${props.language.BS_KEY_PAY_1197}</span>`
})

const nowTitle = computed(() => {
  debuggerLog('props.language >>>>>>', props.language)
  return props.language.BS_KEY_PAY_1198
})

// 当前卡bin优惠券的bin列表
const nowErrCouponBin = computed(() => {
  return props.nowUseCouponInfo?.reduce(
    (acc, item) => (acc || [])?.concat(item?.businessExtension?.order?.card?.cardCodes || []),
    [],
  )
})

const discount = computed(() => {
  debuggerLog('nowErrorCoupon.value >>>>>>>', nowErrorCoupon.value)
  return nowErrorCoupon.value?.rules?.[0]?.discount
})

watch(
  () => props.isShowBinCouponsDrawer,
  val => {
    show.value = val
    if (val) {
      emits('bs-action', {
        action: 'closeCouponDrawerVisible',
      })
      selectedBin.value = null

      // triggerNotice?.({
      //   id: 'expose_restriction_bin_token1.comp_bin_coupons_drawer',
      //   data: numToken.value,
      // })
    }
  },
  { immediate: true, deep: true },
)

watch(
  () => discount.value,
  () => {
    if (typeof window === 'undefined') return
    handlerNextTick(() => {
      const nowIsTextOverflow = isTextOverflow(discountRef.value)
      if (nowIsTextOverflow) {
        discountRef.value?.classList?.add('mini-title')
      } else {
        discountRef.value?.classList?.remove('mini-title')
      }
    })
  },
  {
    immediate: true,
  },
)

watch(
  () => show.value,
  () => {
    if (typeof window === 'undefined') return
    handlerNextTick(() => {
      isTextOverflowWithCb(titleRef.value, () => {
        titleRef.value?.classList?.add('mini-header-title')
      })

      isTextOverflowWithCb(tipTitleRef.value, () => {
        tipTitleRef.value?.classList?.add('mini-tip-title')
      })
    })
  },
  {
    immediate: true,
  },
)

const emits = defineEmits(['newApplyCoupon', 'bs-action'])

const close = (isNeedDaEventCenter = true) => {
  show.value = false
  emits('bs-action', {
    action: 'close',
  })

  if (isNeedDaEventCenter) {
    // triggerNotice?.({
    //     id: 'click_restriction_bin_token1.comp_bin_coupons_drawer',
    //     data: {
    //       ...numToken.value,
    //       click_type: 'giveup',
    //     },
    // })
  }
}

// 选中 支付方式
const selectPay = () => {
  debuggerLog('selectedConfirm >>>>>>', {
      paymentInfo: selectedPay.value,
      tokenItem: selectedBin.value,
    })
  emits('bs-action', {
    action: 'selectedConfirm',
    data: {
      paymentInfo: selectedPay.value || {},
      tokenItem: selectedBin.value || {},
    },
  })
}

const handlerConfirm = () => {
  // triggerNotice?.({
  //       id: 'click_restriction_bin_token1.comp_bin_coupons_drawer',
  //       data: {
  //       ...numToken.value,
  //       click_type: 'confirm',
  //     },
  //   })
  if (!selectedBin.value && !selectedPay.value) {
    $toast(props.language.BS_KEY_PAY_1198)
    return
  }

  selectPay()

  close(false)
  emits('bs-action', {
    action: 'closeCouponDrawerVisible',
  })
}

// 检测是否文本溢出
function isTextOverflow(element) {
  if (!element) return false
  // 检测元素的滚动宽度是否大于其可视宽度
  return element?.scrollWidth > element?.offsetWidth
}

const isTextOverflowWithCb = (element, cb) => {
  if (!element) return false
  // 检测元素的滚动宽度是否大于其可视宽度
  if (element?.scrollHeight > element?.offsetHeight) {
    cb && cb()
  }
}

async function handlerNextTick(cb) {
  await nextTick()
  cb && cb()
}

const handlerSelected = ({
  nowSelectedBin, pay,
}) => {
  debuggerLog('selectedBin >>>>>>', nowSelectedBin, pay)
  selectedBin.value = nowSelectedBin
  selectedPay.value = pay
}

const changeIsShowSubNormalTitleText = val => {
  isShowSubNormalTitleText.value = val
}

const tokenNumChange = num => {
  numToken.value = {
    num_token: num,
  }
}
</script>

<style lang="less" scoped>
.nowrap-text() {
  // 超出一行展示省略号
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.prime-ellipsis2() {
  // 超过两行省略css样式
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.coupons-before-after() {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: 50%;
  width: 12/75rem;
  height: 24/75rem;
  border: 1/75rem solid #ffe2cf;
  background-color: #fff5f5;
  border-radius: 16/75rem;
  transform: translateY(-50%);
}
.bin-coupons-drawer {
  .now-coupon {
    border: 0.5px #ffe2cf solid;
    background: linear-gradient(180deg, #fff6f3 0%, #ffe6de 100%);
    padding: 16 * 2/75rem 24 * 2/75rem;
    color: #f6451e;
    font-size: 28px;
    font-family: SF Pro;
    font-weight: 860;
    word-wrap: break-word;
    display: inline-block;
    position: relative;
    margin: 32 * 2/75rem 0 24 * 2/75rem 0;
    border-radius: 12/75rem;
    line-height: 1;
    min-width: 148 * 2/75rem;
    max-width: 351 * 2/75rem;
  }

  .now-coupon-content {
    .nowrap-text();
    display: inline-block;
    width: 100%;
  }

  .mini-title {
    font-size: 14px;
  }

  .now-coupon::before {
    .coupons-before-after();
    left: -2/75rem;
    border-left-color: #fff5f5;
    border-radius: 0 12/75rem 12/75rem 0;
  }

  .now-coupon::after {
    .coupons-before-after();
    right: -2/75rem;
    border-right-color: #fff5f5;
    border-radius: 12/75rem 0 0 12/75rem;
  }

  .bin-coupons-content {
    padding: 0 0 0 17 * 2/75rem;
  }

  .header-title {
    margin-bottom: 18 * 2/75rem;
    .title {
      color: black;
      font-size: 18px;
      font-family: SF Pro;
      font-weight: 590;
      word-wrap: break-word;
      .prime-ellipsis2();
    }

    .tip-title {
      color: #767676;
      font-size: 12px;
      font-family: SF Pro;
      font-weight: 400;
      word-wrap: break-word;
      .prime-ellipsis2();
    }
    /deep/.tip-title__card {
      color: var(---sui_color_gray_dark3, #f2363d);
    }

    .mini-header-title {
      font-size: 12px;
    }

    .mini-tip-title {
      font-size: 10px;
    }
  }

  /deep/ .sui-drawer__header {
    border-bottom: unset;
    background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/05/22/fe/1716367274db78d5367f65ae750528fb3e1089f666.png);
    background-size: cover;
  }

  /deep/.sui-drawer__normal-footer {
    background: white;
    margin-top: unset;
    padding: 40/75rem;
    height: unset;
    line-height: unset;
    display: flex;
    justify-content: space-between;
  }
}
</style>
