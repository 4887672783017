<template>
  <SLazyMount>
    <SDrawer
      v-model:visible="isShow"
      class="installment-list-drawer"
      :direction="'btt'"
      :append-to-body="true"
      @close-from-icon="cancel"
      @close-from-mask="cancel"
    >
      <template #title>
        <div class="header-block">
          <p class="title">
            {{ language.BS_KEY_PAY_1075 }}
          </p>
          <p class="second-title">
            <Icon
              name="sui_icon_guarantee_18px"
              size="18px"
              :is-rotate="cssRight"
            />
            <span>
              {{ language.BS_KEY_PAY_1014 }}
            </span>
          </p>
        </div>
      </template>

      <AllInstallmentList
        ref="allInstallmentListRef"
        :language="language"
        :is-display="isShow"
        :installment-options="installmentOptions"
        :recommend-installments="recommendInstallments"
        @select-installment="handlerSelectInstallment"
      />

      <template #footer>
        <slot
          v-if="isShow"
          name="pre-pay-modal-footer"
          scene="pre-pay"
          :payNow="handlerPay"
          :now-total-price="nowTotalPrice"
        ></slot>
      </template>
    </SDrawer>
  </SLazyMount>
</template>

<script setup lang="ts">
/*
 * 支付方式组件: 路由卡分期分期选项抽屉
 *
 * */
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import AllInstallmentList from '../pre-pay/AllInstallmentList.vue'
import { usePaymentsStore } from '../../../hooks/usePayments'
import { useGetInstallments } from '../../../hooks/useGetInstallments'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { ref, computed, watch, inject } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import type { AS_PayToolkit } from '../../../types'
import { debuggerLog } from '../../../utils'
const { triggerNotice } = (inject('analysisInstance', null) || {}) as AS_PayToolkit.AnalysisInstance

interface SelectInstallment {
  installment_amount?: string;
  [key: string]: any;
}

interface PrePayProps {
  bsPaymentAction: (info: { action: Trade_PayToolKit.BsPaymentAction; payload: Record<string, any> }) => void;
  paymentCode?: string;
  language: Record<string, string>;
  nowCurrency: string;
  nowCvv: string;
  nowInstallment: null;
  isNowMaestro: boolean;
}

const props = withDefaults(defineProps<PrePayProps>(), {
  bsPaymentAction: () => {},
  paymentCode: 'routepay-cardinstallment',
  language: () => ({} as Record<string, string>),
  nowCurrency: '',
  nowCvv: '',
  nowInstallment: null,
  isNowMaestro: false,
})

const emits = defineEmits(['cvv-validate', 'pay-now'])

const isShow = ref(false)
const selectInstallment = ref<SelectInstallment>({})
const allInstallmentListRef = ref()
const coverData = ref({})

const paymentStore = usePaymentsStore()
const { cssRight } = useAppConfigs()?.$envs || {}

const { getInstallments, changePayStoreInstallments } = useGetInstallments({ paymentStore })

const selectedPayment = computed(() => paymentStore?.selectedPayment || {})
const nowPayStore = computed(() => paymentStore.editablePaymentInfo?.[props.paymentCode] || {})
const nowTokenPay = computed(() => {
  return paymentStore?.payments?.find(item => item?.code === props.paymentCode) as Trade_PayToolKit.PaymentInfoItem
})
const cardTokenInfo = computed(() => {
  return nowPayStore.value?.card_token_info || {}
})
const isInitInstallmentsToken = computed(() => selectedPayment.value?.code === props.paymentCode && Object.keys(cardTokenInfo.value).length > 0)
const installmentOptions = computed(() => nowPayStore.value?.installments || [])
const recommendInstallments = computed(() => nowPayStore.value?.recommend_installments || 0)

const totalPrice = computed(() => {
  return paymentStore?.checkoutInfo?.totalPriceInfo?.amount || ''
})

const nowTotalPrice = computed(() => {
  return selectInstallment.value?.installment_amount || totalPrice.value
})
const nowUseTokenData = computed(() => {
  return paymentStore?.editablePaymentInfo?.[selectedPayment.value?.code]?.card_token_info || {}
})
const isShowCvvDrawer = computed(() => {
  return nowUseTokenData.value?.need_cvv === '1'
})
const isHadCvv = computed(() => {
  const nowIsHadCvv = props.isNowMaestro || props.nowCvv !== ''
  return isShowCvvDrawer.value ? nowIsHadCvv : true
})

const cancel = () => {
  // console.log('order_new====cancel')
  coverData.value = {}
  allInstallmentListRef.value?.reset?.()
  selectInstallment.value = {}
  props.bsPaymentAction?.({
    action: 'updateTotalPrice',
    payload: {
      totalAmountWithSymbol: 0,
    },
  })

  paymentStore?.paymentEventBus?.paymentValidSendBi?.emit({
      result_reason: '1_13',
  })
}
const handlerPay = () => {
  triggerNotice?.({
    id: 'click_token_installment.comp_installment_list_drawer',
  })
  if (Object.keys(selectInstallment.value).length === 0) {
    SToast(props.language.BS_KEY_PAY_1071)
    return
  }

  isShow.value = false

  // 需要 cvv 校验
  if (isShowCvvDrawer.value) {
    emits('cvv-validate', {
      installments: selectInstallment.value?.stage_num,
      lastAddOrderExtraParams: {
        coverData: coverData.value,
      },
    })
    selectInstallment.value = {}
    return
  }

  emits('pay-now', {
    installments: selectInstallment.value?.stage_num,
    coverData: coverData.value,
    errorCb: () => {
      selectInstallment.value = {}
    },
  })
  coverData.value = {}
}
const handlerSelectInstallment = (installment: Trade_PayToolKit.InstallmentInfoItem | Record<string,never> = {}) => {
  selectInstallment.value = installment
  props.bsPaymentAction?.({
    action: 'updateTotalPrice',
    payload: {
      totalAmountWithSymbol: installment?.total_amount,
    },
  })
}

const handlerGetInstallments = () => {
  const useGetInstallmentsParams = {
    paymentCode: nowTokenPay.value?.code || '',
    cardBin: cardTokenInfo.value?.card_bin || '',
    cardType: cardTokenInfo.value.card_type || '',
    orderCurrency: props.nowCurrency || '',
    cb: (useGetInstallmentsInfo) => {
      if (useGetInstallmentsInfo?.data?.code == 0) {
        const { info = {} } = useGetInstallmentsInfo?.data || {}
        changePayStoreInstallments({
          pay: nowTokenPay.value,
          installments: info || {},
        })
      }
    },
  }
  getInstallments(useGetInstallmentsParams)
}

const validateByPay = (opts?: Trade_PayToolKit.PreCreatePaymentVerifyOptions) => {
  debuggerLog('installment validateByPay >>>', opts)
  if (opts?.lastAddOrderExtraParams?.coverData) {
    coverData.value = opts.lastAddOrderExtraParams.coverData
  }
  if (!!props.nowInstallment && isHadCvv.value) {
    return {
      validateResult: true,
      scene: 'select_installment',
    }
  }

  isShow.value = true
  selectInstallment.value = {}
  return {
      validateResult: false,
      scene: 'select_installment',
      metric_scene: 'payment_token_installment_empty',
      saParams: {
        result_reason: '1_13',
      },
  }
}

// 每次打开抽屉时获取分期信息
watch(() => isShow.value, () => {
  if (isShow.value) {
    handlerGetInstallments()

    triggerNotice?.({
      id: 'click_token_contine.comp_installment_list_drawer',
    })
    triggerNotice?.({
      id: 'expose_token_installment.comp_installment_list_drawer',
    })
  }
}, {
  immediate: true,
})

// 支付列表选中token支付方式时提前获取分期信息
watch(() => isInitInstallmentsToken.value, () => {
  if (isInitInstallmentsToken.value) {
    handlerGetInstallments()
  }
}, {
  immediate: true,
})

defineExpose({
  validateByPay,
  isShow,
  cancel,
  handlerGetInstallments,
})
</script>

<style lang="less" scoped>
.installment-list-drawer {
  .header-block {
    padding: 18/75rem 0;
  }
  .title {
    // Benefits details
    color: #767676;
    font-size: 14px;
    font-family: SF Pro;
    font-weight: 400;
    word-wrap: break-word;
    line-height: 1;
  }
  .second-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #198055;
    font-size: 12px;
    font-family: SF Pro;
    font-weight: 400;
    word-wrap: break-word;
    line-height: 1;
  }

  /deep/ .sui-drawer__normal-footer {
    margin-top: unset;
    height: unset;
  }
}
</style>
