import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export { default as BSrcConsent } from './components/src-consent/index.vue'
export { default as BSrcCardList } from './components/src-card-list/index.vue'
export { default as BSrcValidateDrawer } from './components/src-validate/ValidateDrawer.vue'
export { default as BSrcOptInputDrawer } from './components/src-otp-input/OtpInputDrawer.vue'
export { default as BSrcClickToPayDialog } from './components/src-tip-dialog/ClickToPayDialog.vue'
export { default as BSrcTokenHeader } from './components/src-token-header/index.vue'
export { default as BSrcPhoneEmailConfirmDialog } from './components/src-tip-dialog/PhoneEmailConfirmDialog.vue'
export { default as BSrcRequestTimeOutDialog } from './components/src-tip-dialog/RequestTimeOutDialog.vue'
export { default as BSrcAreaCodeListDrawer } from './components/area-code-list/index.vue'
export { default as SwitchPaymentDialog } from './components/src-tip-dialog/SwitchPaymentDialog.vue'

export { default as BSrcOtpInputContent } from './components/src-otp-input/OtpInputContent.vue'
export { default as BSrcValidateContent } from './components/src-validate/ValidateContent.vue'


import type { DS_ClickToPay } from '../../types'
export type * from '../../types'
export type * from '../lang'

import { getFsDataRunner } from '../../common/dataSource'

export function getClickToPayFsDataRunner(sourceParams?: Partial<DS_ClickToPay.SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}
