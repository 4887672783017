<template>
  <div>
    <Icon
      name="sui_icon_more_down_16px_2"
      size="16px"
      :is-rotate="cssRight"
      class="signup-one_time-arrow"
      @click.stop="clickSignupOneTime"
    />
    <SLazyMount>
      <SDrawer
        v-model:visible="show"
        class="signup-one_time-drawer"
        :append-to-body="true"
      >
        <template #title>
          {{ title }}
        </template>
        <div>
          <SRadioGroup
            v-model="selected"
            class="type-radio"
          >
            <SRadio
              class="type-item"
              :label="radioLabel.useTokenLabel"
            >
              <p>{{ oneTimesSaveTipKey }}</p>
              <p
                v-if="contactMsg"
                class="bold"
              >
                {{ `(${contactMsg})` }}
              </p>
            </SRadio>

            <SRadio
              class="type-item type-item-last"
              :label="radioLabel.useOneTimePayLabel"
              @click="clickOneTimePay"
            >
              {{ oneTimesTipKey }}
            </SRadio>
          </SRadioGroup>

          <div class="confirm-btn">
            <SButton
              width="100%"
              :type="['primary']"
              @click="handleConfirm"
            >
              {{ language.BS_KEY_PAY_1040 }}
            </SButton>
          </div>
        </div>
      </SDrawer>
    </SLazyMount>
  </div>
</template>

<script setup lang="ts" name="SignupPayOneTimePayDrawer">
import { Icon } from '@shein-aidc/icon-vue3'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SRadioGroup } from '@shein-aidc/sui-radio-group/mobile'
import { SRadio } from '@shein-aidc/sui-radio/mobile'
import { SToast as $toast } from '@shein-aidc/sui-toast/mobile'
import { computed, ref, watch, inject } from 'vue'
import type { AS_PayToolkit } from '../../../../types'
const { triggerNotice } = (inject('analysisInstance', null) || {}) as AS_PayToolkit.AnalysisInstance

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  paymentCode: {
    type: String,
    default: '',
  },
  radioLabel: {
    type: Object,
    default: () => ({
      useTokenLabel: '0',
      useOneTimePayLabel: '1',
    }),
  },
  useOneTimePay: {
    type: Boolean,
    default: false,
  },
  oneTimesTipKey: {
    type: String,
    default: '',
  },
  oneTimesSaveTipKey: {
    type: String,
    default: '',
  },
  contactMsg: {
    type: String,
    default: '',
  },
  forceRememberText: {
    type: String,
    default: '',
  },
  isForceRemember: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['confirm'])
const { cssRight } = useAppConfigs()?.$envs || {}

const title = computed(() => {
  return props.language?.BS_KEY_PAY_1192
})

const show = ref(false)

const outValue = computed(() => {
  return props.useOneTimePay || false
})

const selected = ref(outValue.value ? '1' : '0')

const clickOneTimePay = () => {
  if (props.isForceRemember) {
    selected.value = '0'
    return $toast(props.forceRememberText)
  }
}

const handleConfirm = () => {
  triggerNotice({
    id: 'click_token_onetime.comp_signup_one_time_drawer',
    data: {
      token_status: selected.value ? 'token_onetime' : 'token_status',
    },
  })
  if (selected.value === '1' && props.isForceRemember) {
    $toast(props.forceRememberText)
    return
  }
  show.value = false
  emits('confirm', {
    selected: selected.value === '1',
    paymentCode: props.paymentCode,
  })
}

const clickSignupOneTime = () => {
  show.value = true
}

watch(show, (val) => {
  // 重新拉起弹窗时，获取外部传入的选中值
  if (val) {
    triggerNotice({
      id: 'expose_token_onetime.comp_signup_one_time_drawer',
      data: {
        payment_method: props.paymentCode,
        token_status: outValue.value ? 'token_onetime' : 'token_status',
      },
    })
    selected.value = outValue.value ? '1' : '0'
  }
})
</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}

.signup-one_time-arrow {
  color: #999;
}
.signup-one_time-drawer {
  .type-des {
    padding: 0.32rem;
    color: #666;
    background-color: #f6f6f6;
    text-align: center;
    .font-dpr(24px);
  }
  .type-radio {
    border-bottom: 1px solid #f6f6f6;
  }
  .type-item {
    width: 100%;
    height: 1.17333rem;
    margin-left: 0.32rem;
    border-bottom: 1px solid #d9d9d9;
    .bold {
      font-weight: 700;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .confirm-btn {
    padding: 0.2rem 0.32rem;
  }
}
</style>

