<template>
  <div class="payment-form">
    <CardNumberField
      ref="cardNumberField"
      v-model="formData.cardNumber"
      data-field="cardNumber"
      :language="language"
      :card-bin-routing-info="cardBinRoutingInfo"
      :bin-discount-token-info="binDiscountTokenInfo"
      @change="handleCardNumberChange"
      @blur="handleCardNumberBlur"
      @clear="handleCardNumberClear"
    />
    <FieldSlide :visible="showSlideName">
      <!-- name -->
      <CardNameField
        ref="cardNameField"
        v-model="formData.cardHolderName"
        data-field="cardHolderName"
        :language="language"
        :required="showSlideName"
      />
    </FieldSlide>
    <FieldSlide :visible="showSlideCpf">
      <!-- cpf -->
      <CardCpfField
        ref="cardCpfField"
        v-model="formData.cpfNumber"
        data-field="cpfNumber"
        :payment-code="cardBinRoutingInfo.payMethod"
        :language="language"
        :required="showSlideCpf"
        :document-config="documentConfig"
      />
    </FieldSlide>
    <div class="payment-form-item__horizontal item-underline">
      <ExpireDateField
        ref="expireDateField"
        v-model="formData.expireDate"
        data-field="expireDate"
        :language="language"
        class="item-sep-line__right"
      />
      <CardCvvField
        ref="cardCvvField"
        v-model="formData.cvv"
        data-field="cvv"
        :language="language"
        :card-type="cardBinRoutingInfo.cardType"
        :is-show-dynamic-cvv="isShowDynamicCvv"
        @click-tips="() => visiblePopupConfig.isShowCvvTip = true"
      />
    </div>
    <div
      v-if="language.BS_KEY_PAY_1228 && isShowDynamicCvv"
      class="dynamic-cvv-tips"
      v-html="language.BS_KEY_PAY_1228"></div>
    <RememberCardField
      v-model="formData.rememberCard"
      :language="language"
      :disabled="isForbiddenRememberCard"
      :text="language.BS_KEY_PAY_1178"
      :show-tips="!isForbiddenRememberCard"
      @change="handleRememberCardChange"
      @click="handleRememberCardClick"
      @click-tips="() => visiblePopupConfig.rememberCardTips = true"
    />
    <!-- cvv提示弹窗 -->
    <SLazyMount>
      <CvvTipsPopup
        v-model:visible="visiblePopupConfig.isShowCvvTip"
        :language="language"
        :is-show-dynamic-cvv="isShowDynamicCvv"
      />
    </SLazyMount>
    <SLazyMount>
      <RememberCardTips
        v-model:visible="visiblePopupConfig.rememberCardTips"
        :language="language"
      />
    </SLazyMount>
    <SLazyMount>
      <ForbiddenRememberCardTips
        v-model:visible="visiblePopupConfig.forbiddenRememberCardTips"
        :language="language"
      />
    </SLazyMount>
    <SLazyMount>
      <RememberCardPolicyTips
        v-model:visible="visiblePopupConfig.rememberCardPolicyTips"
        v-expose="{ id: 'expose_popup_rememberthiscard.comp_pre_pay' }"
        :language="language"
        @actively-close="handleCloseRememberCardPolicyTips"
        @confirm="handleSelectedRememberCard"
      />
    </SLazyMount>
  </div>
</template>

<script setup lang="ts">
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import CardNumberField from './CardNumberField.vue'
import ExpireDateField from './ExpireDateField.vue'
import CardCvvField from './CardCvvField.vue'
import RememberCardField from './RememberCardField.vue'
import { defineAsyncComponent, reactive, ref, computed, toRef, watch } from 'vue'
import { usePrePayStore } from '../../../../hooks/usePrePay'
import { usePaymentsStore } from '../../../../hooks/usePayments'
// import { daEventCenter } from 'public/src/services/eventCenter/index'
// import { expose as vExpose } from 'public/src/pages/common/analysis/directive.js'
import { useAnalysis } from '../../../../common/analysisSource'
import { type LangKeys } from '../../../../common/languages'
const { vExpose, triggerNotice } = useAnalysis() || {}
import { debuggerLog } from '../../../../utils'


const CardNameField = defineAsyncComponent(() => import('./CardNameField.vue'))
const CardCpfField = defineAsyncComponent(() => import('./CardCpfField.vue'))
const CvvTipsPopup = defineAsyncComponent(() => import('../CvvTipsPopup.vue'))
const FieldSlide = defineAsyncComponent(() => import('../FieldSlide.vue'))
const RememberCardTips = defineAsyncComponent(() => import('../RememberCardTips.vue'))
const ForbiddenRememberCardTips = defineAsyncComponent(() => import('../ForbiddenRememberCardTips.vue'))
const RememberCardPolicyTips = defineAsyncComponent(() => import('../RememberCardPolicyTips.vue'))

const defaultFormData = {
  cardNumber: '',
  cardHolder: '',
  expireDate: '',
  cvv: '',
  cpfNumber: '',
  cardHolderName: '',
  bin: '',
  rememberCard: 0,
}


interface PrePayModalPaymentFormProps {
  language: Record<LangKeys, string>;
  cardBinRoutingInfo: Record<string, any>;
  countryCode: string;
  binDiscountTokenInfo: Record<string, any>;
}
const paymentsStore = usePaymentsStore()
const rootStore = usePrePayStore()

const prePayCheckoutInfo = toRef(rootStore, 'prePayCheckoutInfo')

const emits = defineEmits(['bin-change'])

const props = withDefaults(defineProps<PrePayModalPaymentFormProps>(), {
  language: () => ({} as Record<LangKeys, string>),
  cardBinRoutingInfo: () => ({} as Record<string, any>),
  countryCode: '',
  binDiscountCardTokenInfo: () => ({} as Record<string, any>),
})

const visiblePopupConfig = reactive({
  isShowCvvTip: false,
  rememberCardTips: false,
  forbiddenRememberCardTips: false,
  rememberCardPolicyTips: false,
})

const isForbiddenRememberCard = ref(false)
const isDefaultRememberCard = ref(false)

const cardNumberField = ref()
const cardNameField = ref()
const cardCpfField = ref()
const expireDateField = ref()
const cardCvvField = ref()

const formFieldRefs = [
  cardNumberField,
  cardNameField,
  cardCpfField,
  expireDateField,
  cardCvvField,
]

const formData = reactive({ ...defaultFormData })

const cpfDisplayConfigs = (language: Record<LangKeys, string>) => ({
  // 巴西
  'BR': {
    placeholder: language?.BS_KEY_PAY_1146,
  },
  // 阿根廷
  'AR': {
    placeholder: language?.BS_KEY_PAY_1148,
  },
  // CO
  'CO': {
    placeholder: language?.BS_KEY_PAY_1147,
  },
  // ZA
  'ZA': {
    placeholder: language?.BS_KEY_PAY_1149,
  },
})

const documentConfig = computed(() => {
  const countryCode = props?.cardBinRoutingInfo?.orderCountry || props.countryCode
  const cpfConfig = cpfDisplayConfigs(props.language)?.[countryCode] || {}
  return {
    title: props?.cardBinRoutingInfo?.documentTitle || '',
    placeholder: cpfConfig.placeholder || '',
    rule: props?.cardBinRoutingInfo?.documentRule || '',
  }
})

const showSlideName = computed({
  get () {
    return props?.cardBinRoutingInfo?.isCardHoldName || false
  },
  set () {
  },
})

const showSlideCpf = computed({
  get () {
    return props?.cardBinRoutingInfo?.isDocument || false
  },
  set () {
  },
})

const isShowDynamicCvv = computed(() => {
  const cardNumber = formData.cardNumber?.replace?.(/\s/g, '') || ''
  const isValidCardBin = cardNumber.length >= 8
  const isShowByAbt = paymentsStore.abtInfo?.Mxdynamiccvv?.param?.dynamictest == 1
  const isVirtualCardBin = props?.cardBinRoutingInfo?.isVirtualCardBin == 1
  return isShowByAbt && isValidCardBin && isVirtualCardBin
})

watch(() => props.cardBinRoutingInfo, (info) => {
  if (typeof window === 'undefined') return
  isForbiddenRememberCard.value = info?.isRememberCard != null ? !info?.isRememberCard : false
  isDefaultRememberCard.value = info?.defaultRemember != null ? !!info?.defaultRemember : false
  // formData.rememberCard = !isForbiddenRememberCard.value && isDefaultRememberCard.value ? 1 : 0
  // 已选择记住卡号，但前置路由返回禁止记住卡号时，自动取消记住卡号
  // 未记住卡号，但前置路由返回默认记住卡号时，自动选择记住卡号
  if (formData.rememberCard == 1) {
    formData.rememberCard = isForbiddenRememberCard.value ? 0 : 1
  } else {
    formData.rememberCard = !isForbiddenRememberCard.value && isDefaultRememberCard.value ? 1 : 0
  }
  // 需要税号时自动填充税号
  if (info?.isDocument) {
    // debuggerLog('prePayCheckoutInfo.value?.taxNumber====', prePayCheckoutInfo.value?.taxNumber)
    formData.cpfNumber = info?.isDocument ? formData.cpfNumber || prePayCheckoutInfo.value?.taxNumber || '' : ''
  }
  if (!showSlideName.value) {
    formData.cardHolderName = ''
    cardNameField.value?.reset?.()
  }
  if (!showSlideCpf.value) {
    formData.cpfNumber = ''
    cardCpfField.value?.reset?.()
  }
}, { immediate: true, deep: true })

const handleCardNumberClear = () => {
  debuggerLog('handleCardNumberClear >>>>>>')
  formData.bin = ''
  emits('bin-change', '')
}

const handleCardNumberAllClear = () => {
  cardNumberField.value?.reset?.()
  handleCardNumberClear()
}

const handleCardNumberChange = (cardNumber = '') => {
  const newBin = cardNumber?.slice?.(0, 8) || ''
  if (cardNumber.length < 8) {
    // 清除展示的卡bin优惠
    // rootStore.updateState('displayCardBinDiscountInfo', {})
    emits('bin-change', newBin)
    // rootStore.prePayEventBus?.showDefaultCardBinDiscount?.emit(true)
  }
  if (cardNumber.length >= 8) {
    // rootStore.prePayEventBus?.showDefaultCardBinDiscount?.emit(false)
    formData.bin = newBin
    emits('bin-change', newBin)
    // if (formData.bin !== newBin) {
    //   formData.bin = newBin
    //   emits('bin-change', newBin)
    // } else {
    //   rootStore.updateState('displayCardBinDiscountInfo', prePayCheckoutInfo.value?.cardBinDiscountInfo || {})
    // }
  } else if (!newBin) {
    formData.bin = ''
    emits('bin-change', '')
  }
}

const handleCardNumberBlur = () => {
  // debuggerLog('handleCardNumberBlur=====', cardNumber, formData)
}

const formValidator = () => {
  let result = true
  let firstNoValidField = null as any
  const failReasonResults: string[] = []
  for(let i = 0; i < formFieldRefs.length; i++) {
    const field = formFieldRefs[i]
    if (field?.value?.validator) {
      const { result: fieldResult, failType, reasonType } = field.value.validator()
      result = result && fieldResult
      if (!fieldResult && reasonType) {
        failReasonResults.push(`${reasonType}:${failType}`)
      }
      if (!fieldResult && !firstNoValidField) {
        firstNoValidField = field.value
        // field.value.focus?.()
        // break
      }
    }
  }
  if (firstNoValidField) {
    firstNoValidField?.focus?.()
  }

  if (result) {
    const cardNumber = formData.cardNumber?.replace?.(/\s/g, '') || ''
    rootStore.mergeState('prePayUnPayParams', {
      cardNumber,
      cpfNumber: formData.cpfNumber || '',
      rememberCard: formData.rememberCard,
      autoBindCard: !formData.rememberCard && !isForbiddenRememberCard.value ? 1 : 0,
      cardHolderName: formData.cardHolderName || '',
      expireYear: formData.expireDate.split('/')[1],
      expireMonth: formData.expireDate.split('/')[0],
      cardLastFour: cardNumber.slice(-4),
      cvv: formData.cvv,
      countryCode: props.countryCode,
    })
  }

  return {
    result,
    failReasonResults,
  }
}

const resetForm = () => {
  for (const key in defaultFormData) {
    formData[key] = defaultFormData[key]
  }
  rootStore.updateState('prePayUnPayParams', {})
  formFieldRefs.forEach((field) => {
    if (field?.value?.reset) {
      field.value.reset()
    }
  })
  debuggerLog('resetForm=====', formData)
}

const handleRememberCardClick = () => {
  debuggerLog('handleRememberCardClick=====', formData.rememberCard, isForbiddenRememberCard.value)
  if (isForbiddenRememberCard.value) {
    visiblePopupConfig.forbiddenRememberCardTips = true
    triggerNotice({
      id: 'click_remember_thiscard.comp_pre_pay',
      data: {
        is_remember: 2,
      },
    })
    return
  }
  if (formData.rememberCard && prePayCheckoutInfo.value?.isAutoRenewal) {
    SToast(props.language?.BS_KEY_PAY_1179 || '')
    return
  }
  // 记住卡号 -> 不记住卡号弹出提示
  if (formData.rememberCard && isDefaultRememberCard.value) {
    visiblePopupConfig.rememberCardTips = true
  // 不记住卡号 -> 记住卡号弹出隐私政策提示
  } else if (!formData.rememberCard && !isDefaultRememberCard.value) {
    visiblePopupConfig.rememberCardPolicyTips = true
    return
  }
  formData.rememberCard = formData.rememberCard ? 0 : 1
  triggerNotice({
    id: 'click_remember_thiscard.comp_pre_pay',
    data: {
      is_remember: formData.rememberCard,
    },
  })
}

const handleSelectedRememberCard = () => {
  visiblePopupConfig.rememberCardPolicyTips = false
  formData.rememberCard = 1
  triggerNotice({
    id: 'click_rememberthiscard_ok.comp_pre_pay',
  })
  triggerNotice({
    id: 'click_remember_thiscard.comp_pre_pay',
    data: {
      is_remember: formData.rememberCard,
    },
  })
}

const handleCloseRememberCardPolicyTips = () => {
  visiblePopupConfig.rememberCardPolicyTips = false
  triggerNotice({
    id: 'click_rememberthiscard_close.comp_pre_pay',
  })
  triggerNotice({
    id: 'expose_remembercard_status.comp_pre_pay',
  })
}

const handleRememberCardChange = (info) => {
  debuggerLog('fff=====', info)
  // 记住卡号 -> 不记住卡号弹出提示
  // if (!value && props.cardBinRoutingInfo.defaultRemember) {
  //   nextTick(() => {
  //     visiblePopupConfig.rememberCardTips = true
  //   })
  // // 不记住卡号 -> 记住卡号弹出隐私政策提示
  // } else if (!formData.rememberCard && !isDefaultRememberCard.value) {
  //   visiblePopupConfig.rememberCardPolicyTips = true
  //   return
  // }
  // } else if (value && prePayCheckoutInfo.value?.isAutoRenewal) {
  //   // 记住卡号 -> 自动续费弹出提示
  //   nextTick(() => {
  //     visiblePopupConfig.rememberCardPolicyTips = true
  //   })
  // }
}

defineExpose({
  formValidator,
  resetForm,
  handleCardNumberClear,
  handleCardNumberAllClear,
})

</script>

<style lang="less">
.payment-form {
  .payment-form-item__horizontal {
    display: flex;
    justify-content: space-between;
    .payment-card-field {
      flex: 1;
      overflow: hidden;
    }
  }
  .item-underline {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: var(--sui_color_gray_light2, #e5e5e5);
    }
  }

  .dynamic-cvv-tips {
    margin: 6*2/75rem 12*2/75rem 8*2/75rem;
    padding: 8*2/75rem;
    background-color: #FFF8EB;
    border-radius: 2*2/75rem;
    font-size: 12*2/75rem;
    line-height: 1.5;
    position: relative;

    &:after{
      content: '';
      position: absolute;
      top: -4*2/75rem;
      right: 120*2/75rem;
      border-left: 6*2/75rem solid transparent;
      border-right: 6*2/75rem solid transparent;
      border-bottom: 5*2/75rem solid #FFF8EB;

    }
  }
  
  .item-sep-line__right {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: var(--sui_color_gray_light2, #e5e5e5);
    }
  }
}
</style>
