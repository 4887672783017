export function debuggerLog(...args) {
    if (typeof window === 'undefined') return
    try {
      const logStatus = sessionStorage?.getItem?.('__bs__enable_debug_log') == '1'
      if (!logStatus) return
      console.info(
        '%c c2p token log info:',
        'background: green ; padding: 3px; border-radius: 3px;  color: aliceblue; font-weight: 700;',
        ...args,
        '\n',
      )
    } catch (e) {
      console.log(...args)
    }
  }
