<template>
  <SLazyMount>
    <SDrawer
      id="pre-pay-drawer__wrap"
      v-model:visible="visiblePrePayPopup"
      size="80%"
      append-to-body
      custom-class="bs-pre-pay__drawer"
      @close-from-icon="handleActivelyClose"
      @close-from-mask="handleActivelyClose"
    >
      <template #title>
        <div class="pre-pay-header">
          <span class="pre-pay-header__title">{{ language.BS_KEY_PAY_1078 }}</span>
          <div
            class="all-encrypted"
            @click="showPrivacyDrawer"
          >
            <Icon
              name="sui_icon_guarantee_18px"
              size="18px"
              :is-rotate="cssRight"
            />
            <span>{{ language.BS_KEY_PAY_1014 }}</span>
            <Icon
              name="sui_icon_more_right_12px_2"
              size="12px"
              :is-rotate="cssRight"
            />
          </div>
        </div>
      </template>
      <div class="pre-pay-container">
        <MeetDiscountTip
          :tips="selectedPayment.meetDiscountTip"
        />
        <LogoModal
          v-if="paymentPageLogoList && paymentPageLogoList.length"
          :logo-list="paymentPageLogoList"
        />
        <div class="discount-block">
          <BinDiscountInfo
            v-show="!!defaultBinDiscountInfo.binDiscountTip"
            class="ml-12"
            :discount-info="defaultBinDiscountInfo"
          />
          <BinCoBrandCardInfo
            v-if="!isCoBrandedBenefitsUnderCardNo"
            :preferential-tips="coBrandCardPreferentialTips" />
        </div>
        <PaymentForm
          ref="paymentFormRef"
          :language="language"
          :card-bin-routing-info="cardBinRoutingInfo"
          :bin-discount-token-info="binDiscountCardTokenInfo"
          :country-code="prePayCheckoutInfo.shipCountry"
          @bin-change="handleCardBinChange"
        />

        <!-- 分期信息 -->
        <AllInstallmentList
          ref="installmentRef"
          :language="language"
          :is-display="visiblePrePayPopup"
          :installmentOptions="installmentList"
          :recommend-installments="recommendInstallments"
          @select-installment="handleSelectInstallment"
        />

        <!-- <SupportInfoModule
          :language="language"
          :logoList="payDomainLogoList"
        /> -->
      </div>
      <template #footer>
        <div class="pre-pay-footer">
          <slot name="footer"></slot>
        </div>
      </template>
      <SLazyMount>
        <PrivacyDrawer
          v-model:visible="isShowPrivacyDrawer"
          :language="language"
        >
          <template #privacy>
            <NewPrivacy
              :language="language"
              :logo-list="paySafeIconsList"
            />
          </template>
        </PrivacyDrawer>
      </SLazyMount>

      <SLazyMount>
        <PrePayCouponDrawer
          :language="language"
          :billno="billno"
          :show-drawer="showCouponsDrawer"
          :now-use-coupon-info="paymentsPreferentialTipsItem"
          @close="handlerPrePayCouponDrawer(false)"
          @clearCardBin="clearCardBin"
          @confirmNotUseCoupon="confirmNotUseCoupon"
        />
      </SLazyMount>
    </SDrawer>
  </SLazyMount>
</template>

<script setup lang="ts" name="BsPrePayModal">
import { defineAsyncComponent } from 'vue'
import { type LangKeys } from '../../../common/languages'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'

import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import PaymentForm from './payment-form/index.vue'

import { type PrePayHandleAction } from '../../../hooks/usePrePay'
import { usePrePayModal } from '../../../hooks/usePrePayModal'
import { usePrivacyDrawer } from '../../../hooks/usePrivacyDrawer'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

interface BsPrePayModalProps {
  visible: boolean;
  selectedPayment: Trade_PayToolKit.SelectedPaymentInfo;
  language: Record<LangKeys, string>;
}

const LogoModal = defineAsyncComponent(() => import('./LogoModal.vue'))
const AllInstallmentList = defineAsyncComponent(() => import('./AllInstallmentList.vue'))
const PrivacyDrawer = defineAsyncComponent(() => import('../payment-items/privacy/privacy_drawer.vue'))
const NewPrivacy = defineAsyncComponent(() => import('../payment-items/privacy/new_privacy.vue'))
const MeetDiscountTip = defineAsyncComponent(() => import('./MeetDiscountTip.vue'))
const BinDiscountInfo = defineAsyncComponent(() => import('./BinDiscountInfo.vue'))
const BinCoBrandCardInfo = defineAsyncComponent(() => import('./BinCoBrandCardInfo.vue'))
const PrePayCouponDrawer = defineAsyncComponent(() => import('./coupon-bin/PrePayCouponDrawer.vue'))

const emit = defineEmits<{
  (event: 'update:visible', value: boolean): void
  (event: 'actively-close'): void,
  (event: 'handle-action', info: { action: PrePayHandleAction; payload: Record<string, any> }): void,
}>()


const props = withDefaults(defineProps<BsPrePayModalProps>(), {
  visible: false,
  language: () => ({} as Record<LangKeys, string>),
  selectedPayment: () => ({} as Trade_PayToolKit.SelectedPaymentInfo),
})

const { cssRight } = useAppConfigs()?.$envs || {}

const {
  visiblePrePayPopup,
  prePayCheckoutInfo,
  paymentPageLogoList,
  cardBinRoutingInfo,
  paySafeIconsList,
  defaultBinDiscountInfo,
  binDiscountCardTokenInfo,
  isCoBrandedBenefitsUnderCardNo,
  paymentFormRef,
  installmentRef,
  handleCardBinChange,
  handleActivelyClose,
  handleSelectInstallment,
  installmentList,
  showCouponsDrawer,
  handlerPrePayCouponDrawer,
  confirmNotUseCoupon,
  paymentsPreferentialTipsItem,
  coBrandCardPreferentialTips,
  billno,
  recommendInstallments,
} = usePrePayModal(props, { emit })

const { isShowPrivacyDrawer, showPrivacyDrawer } = usePrivacyDrawer()

const scrollToSection = (el) => {
  // console.log(el, 'el')
  if (el) {
    el?.scrollIntoView({ behavior: 'smooth' })
  }
}

const clearCardBin = () => {
  paymentFormRef.value?.handleCardNumberAllClear?.()
}

defineExpose({
  formValidator: () => paymentFormRef.value?.formValidator(),
  installmentValidator: () => installmentRef.value?.validator(),
  scrollToInstallment: () => scrollToSection(installmentRef.value?.$el),
  handlerPrePayCouponDrawer,
})

</script>

<style lang="less">
.bs-pre-pay__drawer {
  .pre-pay-header {
    line-height: 34/75rem;
    padding: 18/75rem 0 14/75rem 0;

    .pre-pay-header__title {
      color: #767676;
      font-size: 14px;
      font-family: SF Pro;
      font-weight: 590;
      word-wrap: break-word;
    }

    .all-encrypted {
      height: 36/75rem;
      line-height: 36/75rem;
      color: #198055;
      font-size: 12px;
      font-family: SF Pro;
      font-weight: 400;
      word-wrap: break-word;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .sui-drawer__header {
    min-height: auto;
  }
  .sui-drawer__normal-footer {
    // margin-top: unset;
    height: unset;
    line-height: unset;
    // display: flex;
    // justify-content: space-between;
  }

  .discount-block {
    > div{
      &:not(:last-of-type) {
        margin-bottom: 6*2/75rem;
      }
    }
  }
}
</style>
