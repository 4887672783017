<template>
  <SDrawer
    v-model:visible="visibleDrawer"
    custom-class="pre-pay-date__drawer"
    append-to-body
    :direction="'btt'"
    @open="handleOpenedDrawer"
    @close="handleCloseDrawer"
  >
    <template #titleL>
      <span @click="visibleDrawer = false">
        {{ cancelText }}
      </span>
    </template>
    <template #titleR>
      <span @click="handleConfirmSelect">
        {{ confirmText }}
      </span>
    </template>
    <SPicker
      ref="pickerRef"
      :default-index="currentDefaultIndex"
      :columns="dateColumns"
      :columns-field-names="columnsFieldNames"
      @change="handlePickertChange"
    />
  </SDrawer>
</template>

<script setup lang="ts" name="DatePicker">
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SPicker } from '@shein-aidc/sui-picker/mobile'
import { computed, ref, nextTick } from 'vue'
import { isNull } from '@shein/common-function'

interface Column {
  value: string | number
  text: string
  index: number
  children?: Column[]
}

function getYearRange([start, end]): number[] {
  let sd = new Date(),
      ed = new Date()
  sd.setFullYear(sd.getFullYear() + start)
  ed.setFullYear(ed.getFullYear() + end)
  const sy = sd.getFullYear()
  const ey = ed.getFullYear()
  return Array.from({ length: ey - sy + 1 }, (_, i) => sy + i)
}

// function getCurrentDate() {
//   const nd = new Date()
//   return {
//     year: nd.getFullYear(),
//     month: nd.getMonth() + 1,
//     day: nd.getDay() + 1,
//   }
// }

const monthColumns: Column[] = Array.from({ length: 12 }, (_, i) => i + 1).map((month, index) => ({
  value: month,
  text: `${month < 10 ? 0 : ''}${month}`,
  index,
}))

const yearColumns: Column[] = getYearRange([0, 20]).map<Column>((year, index) => ({
  value: year,
  text: year + '',
  index,
}))

const columns: Column[] = monthColumns.map(item => {
  return {
    ...item,
    children: yearColumns,
  }
})

// const defaultColumns = [
//   Array.from({ length: 12 }, (_, i) => i + 1).map((month, index) => ({
//     value: `${month < 10 ? 0 : ''}${month}`,
//     index,
//   })),
//   getYearRange([0, 20]).map((year, index) => ({ value: year, index })),
// ]

const dateColumns = ref(columns)
const columnsFieldNames = {
  children: 'children',
  text: 'text',
}

function matchIndexByValue([month, year]: Array<any>, columns: Column[] = []) {
  if (isNull(year) || isNull(month)) return []
  try {
    const [defaultMonthIndex, defaultYearIndex] = getDefaultIndex()
    const monthItem = columns.find(v => +v.value === +month)
    const yearItem = monthItem?.children?.find?.(v => +v.value === +year)
    return [monthItem?.index || defaultMonthIndex, yearItem?.index || defaultYearIndex]
  } catch (e) {
    return [0, 3]
  }
}

function getDefaultIndex() {
  return [0, 3]
}

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  visible: {
    type: Boolean,
    default: false,
  },
  cancelText: {
    type: String,
    default: '',
  },
  confirmText: {
    type: String,
    default: '',
  },
})

const emits = defineEmits(['update:visible', 'select'])
const pickerRef = ref()
const currentDefaultIndex = ref(getDefaultIndex())

const visibleDrawer = computed({
  get () {
    return props.visible
  },
  set (bool) {
    emits('update:visible', !!bool)
  },
})

const handleCloseDrawer = () => {
  visibleDrawer.value = false
}

const handleOpenedDrawer = () => {
  const patchIndexs = matchIndexByValue(props.modelValue as any, columns as Column[])
  currentDefaultIndex.value = patchIndexs.length ? patchIndexs : currentDefaultIndex.value
  nextTick(() => {
    if (pickerRef.value) {
      pickerRef.value?.setIndexes?.(currentDefaultIndex.value)
    }
  })
}

const handleConfirmSelect = () => {
  visibleDrawer.value = false
  const selectedArr = pickerRef.value.getValues()
  // console.log('pickerRef.value.getSelected()', selectedArr, selectedArr.map(v => +v.value))
  emits('select', selectedArr.map(v => +v.value))
}

const handlePickertChange = () => {
  // const [monthColumn = {}, yearColumn = {}] = pickerColumns || []
  // const { year, month } = getCurrentDate()
  // console.log('handlePickertChange', pickerColumns)
  // if (+monthColumn.value < +month && +yearColumn.value == +year) {
  //   // dateColumns.value = defaultColumns.filter(v => +v.index >= matchIndexs[0])
  //   // pickerRef.value.setColumn(0, defaultColumns[0].filter(v => +v.index >= matchIndexs[0]))
  //   // nextTick(() => {
  //   //   console.log('setColumnSelected===', pickerColumns, matchIndexs[0])
  //   //   pickerRef.value?.setColumnSelected?.(0, matchIndexs[0])
  //   // })
  // } else {
  //   // pickerRef.value.setColumn(0, defaultColumns[0])
  //   // dateColumns.value = defaultColumns
  // }
}

</script>

<style lang="less" scoped>
.pre-pay-date__drawer {
  .S-picker-column__item {
    font-weight: 700;
  }
}
</style>
