<template>
  <div class="payment-form-item no-underline">
    <BaseFormItem
      ref="baseFieldRef"
      v-model="expireDateValue"
      inputmode="text"
      :placeholder="placeholder"
      :label="label"
      :required="true"
      :readonly="true"
      @click="visibleDatePicker = true"
    >
      <template #input-end>
        <Icon
          name="sui_icon_more_down_12px_2"
          size="12px"
          color="#959595"
          :is-rotate="cssRight"
          @click="visibleDatePicker = true"
        />
      </template>
    </BaseFormItem>
    <div
      v-show="errorTips"
      class="error-invalid"
    >
      {{ errorTips }}
    </div>
    <!--  -->
    <SLazyMount>
      <DatePicker
        v-model="selectDate"
        v-model:visible="visibleDatePicker"
        :cancel-text="cancelText"
        :confirm-text="confirmText"
        @select="handleSelectDate"
      />
    </SLazyMount>
  </div>
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import BaseFormItem from './BaseFormItem.vue'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

const DatePicker = defineAsyncComponent(() => import('../components/DatePicker.vue'))

// function getCurrentDate() {
//   const nd = new Date()
//   return {
//     year: nd.getFullYear(),
//     month: nd.getMonth() + 1,
//     day: nd.getDay() + 1,
//   }
// }

const pandingZero = val => +val < 10 ? `0${+val}` : val

type Validator = (cardNumber: string) => {
  result: boolean
  failType?: string
  failReason?: string
  reasonType?: string
  message?: string
}

interface Props {
  modelValue: string; // 值
  errorMessage?: string; // 错误提示信息
  required?: boolean; // 是否必填
  label?: string; // 标签文本
  placeholder?: string; // 输入框占位文本
  clearable?: boolean; // 是否显示清除按钮
  formatter?: (value: string) => string; // 指定输入值格式化函数
  validator?: Validator; // 自定义校验规则
  cancelText: string; // 取消按钮文本
  confirmText: string; // 确认按钮文本
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  cancelText: '',
  confirmText: '',
})

const emits = defineEmits(['update:modelValue'])

const { cssRight } = useAppConfigs()?.$envs || {}

const baseFieldRef = ref()
const errorTips = ref('')
const visibleDatePicker = ref(false)
const selectDate = ref([+props.modelValue?.split('/')?.[0] || 0, +props.modelValue?.split('/')?.[1] || 0])
const expireDateValue = ref('')

// const limitMinDate = () => {
//   const { year, month } = getCurrentDate()
//   return [month, year]
// }

const handleSelectDate = val => {
  selectDate.value = val
  const [month, year] = val
  expireDateValue.value = [pandingZero(month), year].join('/')
  validator()
  emits('update:modelValue', expireDateValue.value)
}

const validator = () => {
  // const [ limitMinMonth, limitMinYear ] = limitMinDate()
  // const [month, year] = selectDate.value || []
  // let failType = ''
  // errorTips.value = ''
  // if (!month || !year || !expireDateValue.value) {
  //   failType = 'null'
  //   errorTips.value = props.language.BS_KEY_PAY_1176
  // } else if (+year === limitMinYear && +month < limitMinMonth) {
  //   failType = 'time_expired'
  //   errorTips.value = props.language.BS_KEY_PAY_1177
  // }

  const { result, message = '', failType } = props.validator?.({ selectDate: selectDate.value, expireDateValue: expireDateValue.value }) || {}
  errorTips.value = !result ? message : ''

  return {
    result,
    failType: failType,
    failReason: null,
    reasonType: 'card_date',
  }
}

const reset = () => {
  expireDateValue.value = ''
  errorTips.value = ''
  emits('update:modelValue', expireDateValue.value)
}

defineExpose({
  validator,
  reset,
  focus: () => baseFieldRef.value.focus(),
})

</script>

<style lang="less">
.payment-form-item {
  padding: 24/75rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24/75rem;
    right: 0;
    height: 1px;
    background-color: var(--sui_color_gray_light2, #e5e5e5);
  }
  &.no-underline {
    &:after {
      display: none;
    }
  }
  .error-invalid {
    font-size: 12px;
    line-height: 1;
    color: var(--sui_color_promo, #FA6338);
    margin-top: 8/75rem;
    transition: height 0.8s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
