<template>
  <div class="bs-pre-pay">
    <!-- 卡前置 -->
    <PrePayModal
      ref="prePayModalRef"
      v-model:visible="visiblePrePay"
      :selected-payment="paymentsStore.selectedPayment"
      :language="paymentsStore.language"
      @handle-action="props.bsPaymentAction"
      @actively-close="handlePrePayActiveClose"
    >
      <template #footer>
        <slot
          v-if="visiblePrePay"
          name="pre-pay-modal-footer"
          scene="pre-pay"
          :payNow="handlePrePayNow"
        ></slot>
      </template>
    </PrePayModal>
    <SLazyMount>
      <FailGuideModal
        v-model:visible="prePayFailGuide.visible"
        :text="prePayFailGuide.failText"
        :type="prePayFailGuide.type"
        :language="paymentsStore.language"
        :close-cb="prePayFailGuide.closeCb"
        :confirm-cb="prePayFailGuide.confirmCb"
        :has-guide="prePayFailGuide.hasGuide"
      />
    </SLazyMount>
  </div>
</template>

<script setup lang="ts" name="BsPrePayModule">
import { defineAsyncComponent } from 'vue'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SToast as $toast } from '@shein-aidc/sui-toast/mobile'
import { usePrePay } from '../../../hooks/usePrePay'
import { usePaymentsStore } from '../../../hooks/usePayments'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'

const PrePayModal = defineAsyncComponent(() => import('./PrePayModal.vue'))
const FailGuideModal = defineAsyncComponent(() => import('./FailGuideModal.vue'))

interface PrePayProps {
  bsPaymentAction: (info: { action: Trade_PayToolKit.BsPaymentAction; payload: Record<string, any> }) => void;
}

const props = withDefaults(defineProps<PrePayProps>(), {
  bsPaymentAction: () => {},
})

const emit = defineEmits<{
  (event: 'update:visible', value: boolean): void
  (event: 'actively-close'): void,
}>()

const paymentsStore = usePaymentsStore()

const event = {
  $toast,
}

const {
  prePayModalRef,
  visiblePrePay,
  isUsePrePay,
  prePayFailGuide,
  validateByPay: validateByPayPrePay,
  // handlePrePayForCheckout,
  handlePrePayNow,
  handlePrePayAction,
} = usePrePay({ props }, { emit, event })

const handlerPrePayCouponDrawer = (status) => {
  prePayModalRef.value?.handlerPrePayCouponDrawer(status)
}

const handlePrePayActiveClose = () => {
  paymentsStore?.paymentEventBus?.paymentValidSendBi?.emit({
      result_reason: '1_12',
  })
}

defineExpose({
  validateByPayPrePay,
  handlePrePayAction,
  isUsePrePay: () => isUsePrePay.value,
  getPrePayVisibleStatus: () => visiblePrePay.value,
  handlerPrePayCouponDrawer,

})

</script>
