<template>
  <div class="selected-bin-support">
    <div
      v-for="(item, index) in showList"
      :key="item.id">
      <p class="pay-title">
        {{ item.title }}
      </p>
      <SelectedBinSupportList
        v-if="isShowBinList(item)"
        :result-token-bin-list="getCardTokenList(item)"
        :payment-methods="paymentMethods"
        :temp-selected-obj="tempSelectedObj"
        :now-pay="item"
        @handlerSelected="selectedData => handlerSelected(selectedData, item)"
      />
      <SimplePayment
        v-else
        sence="binCouponsNormal"
        :selected-pay="selectedPay"
        :payment-methods-options="[item]"
        @event="evt => handlerPaymentEvent(evt, item)"
      />
      <div
        v-if="!isShowBinList(item) && index != showList.length - 1"
        class="dividing-line"></div>
    </div>
  </div>
</template>

<script setup>
import SelectedBinSupportList from './SelectedBinSupportList.vue'
import { computed, watch, ref } from 'vue'
import SimplePayment from '../simple-payment/index.vue'
import { debuggerLog } from '../../../utils'

const props = defineProps({
  nowErrCouponBin: {
    type: Array,
    default: () => [],
  },
  paymentMethods: {
    type: Array,
    default: () => [],
  },
  nowErrorCoupon: {
    type: Object,
    default: () => ({}),
  },
  binCouponsDrawerState: {
    type: Object,
    default: () => {},
  },
  selectedPay: {
    type: Object,
    default: () => {},
  },
})

const tempSelectedObj = ref({})
const emits = defineEmits(['handlerSelected', 'changeIsShowSubNormalTitleText', 'tokenNumChange'])

const installmentPay = computed(() => {
  return props.paymentMethods?.find(item => item.code === 'routepay-cardinstallment')
})

const routerPay = computed(() => {
  return props.paymentMethods?.find(item => item.code === 'routepay-card')
})

const paymentCoupon = computed(() => {
  return props.binCouponsDrawerState?.paymentCoupon || []
})

const nowUsePayList = computed(() => {
  if (paymentCoupon.value.length === 0) {
    return [routerPay.value, installmentPay.value]
  }
  return [routerPay.value, installmentPay.value].filter(item => paymentCoupon.value.includes(item?.code))
})

const showList = computed(() => {
  return nowUsePayList.value?.filter(
    item =>
      // props.nowErrorCoupon?.payment_limit?.includes(item.code) &&
      (!item?.card_token_list || (item?.card_token_list?.length > 0 && getCardTokenList(item).length > 0)),
  )
})

const isShowSubNormalTitleText = computed(() => {
  debuggerLog('showList.value >>>', showList.value)
  return showList.value?.every(item => !isShowBinList(item))
})

const numToken = computed(() => {
  const num = showList.value?.reduce((acc, item) => {
    return acc + (getCardTokenList(item)?.length || 0)
  }, 0)
  return {
    num_token: num,
  }
})

const getCardTokenList = payData => {
  return (payData?.card_token_list || [])?.filter(item => props.nowErrCouponBin?.includes(item.card_bin))
}

const isShowBinList = item => {
  debuggerLog('isShowBinList >>>>>', item, item?.card_token_list?.length > 0 && getCardTokenList(item).length > 0)
  return item?.card_token_list?.length > 0 && getCardTokenList(item).length > 0
}

const handlerSelected = (selectedData, pay) => {
  tempSelectedObj.value = {
    [pay.code]: selectedData,
  }
  emits('handlerSelected', {
    nowSelectedBin: selectedData,
    pay,
  })
}

const handlerPaymentEvent = (evt, pay) => {
  if (evt.type === 'clickPayment') {
    emits('handlerSelected', {
      nowSelectedBin: evt.data,
      pay,
    })
    tempSelectedObj.value = {}
  }
}

watch(
  () => isShowSubNormalTitleText.value,
  () => {
    emits('changeIsShowSubNormalTitleText', isShowSubNormalTitleText.value)
  },
  {
    immediate: true,
  },
)

watch(
  () => numToken.value,
  () => {
    emits('tokenNumChange', numToken.value)
  },
  {
    immediate: true,
  },
)
</script>

<style lang="less" scoped>
.selected-bin-support {
  .pay-title {
    color: black;
    font-size: 14px;
    font-family: SF UI Text;
    font-weight: 700;
    word-wrap: break-word;
    margin-bottom: 8 * 2/75rem;
  }

  .dividing-line {
    border-bottom: 0.5 * 2/75rem #e5e5e5 solid;
    margin-bottom: 17 * 2/75rem;
  }

  /deep/.j-payment-item {
    padding-top: 0;
  }
  /deep/.payment-footer {
    margin-top: 6 * 2/75rem;
  }
}
</style>
