import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

// 手机号码号码格式校验
export const usePhoneValidate = async (data: {
    area_abbr: string, // alias_type=2时必填，示例：CN
    alias: string, // alias_type=1，alias为邮箱；alias_type=2，alias为手机号
    area_code: number | string, // alias_type=2时必填，示例：+86
    alias_type: number // 1邮箱；2手机号；3whatsapp
}): Promise<any> => {
    const appConfigs = useAppConfigs()
    const result = await appConfigs?.$schttp({
        baseURL: appConfigs?.$envs?.langPath,
        url: '/api/auth/phoneValidate/get',
        method: 'POST',
        data,
        headers: {
          AppCurrency: appConfigs?.$envs.currency,
          AppLanguage: appConfigs?.$envs.appLanguage,
        },
      })
      return result.data || result
}
