<template>
  <DiscountLabel
    class="bin-discount-info"
    :text="discountInfo.binDiscountTip"
  />
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
const DiscountLabel = defineAsyncComponent(() => import('./DiscountLabel.vue'))

defineProps({
  discountInfo: {
    type: Object,
    default: () => ({}),
  },
})

</script>
