<template>
  <div
    class="paypal-bnpl-message"
    data-pp-message
    data-pp-layout="text"
    data-pp-text-color="black"
    data-pp-logo-type="inline"
    :data-pp-amount="totalPriceAmount"
  >
  </div>
</template>

<script setup name="PaypalMessage">
import { defineProps } from 'vue'

defineProps({
  totalPriceAmount: {
    type: String,
    required: true,
  },
})
</script>

<style scoped>
.paypal-bnpl-message {
  margin-top: 0.16rem;
}
</style>
