import { nextTick, ref } from 'vue'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { cloneDeep } from '@shein/common-function'

export const useFrontBindingCard = ({ paymentsStore }: { paymentsStore: any }) => {
  const frontBindingCardRef = ref()
  const visibleFrontBindingCard = ref(false)

  const currentSelectPaymentInfo = ref({} as Trade_PayToolKit.SelectedPaymentInfo)

  const originSelectPaymentInfo = ref({} as Trade_PayToolKit.SelectedPaymentInfo)

  const originEditablePaymentInfo = ref({} as Trade_PayToolKit.EditablePaymentInfo)

  const setOriginPaymentInfo = ({
    selectPaymentInfo,
    editablePaymentInfo,
  }) => {
    originSelectPaymentInfo.value = selectPaymentInfo
    originEditablePaymentInfo.value = cloneDeep(editablePaymentInfo)
    console.log('setOriginPaymentInfo >>>>>', originEditablePaymentInfo.value)
  }

  const handlePrePayManualClose = () => {
    visibleFrontBindingCard.value = false
    // 切换支付方式，需要重置状态
    paymentsStore.updateState('selectPaymentInfo', originSelectPaymentInfo.value)
    paymentsStore.updateState('editablePaymentInfo', originEditablePaymentInfo.value)
    paymentsStore.paymentEventBus.setSelectedPaymentByCode.emit({
      paymentItem: originSelectPaymentInfo.value || {},
      needEmitEvent: true,
    })
    nextTick(() => {
      paymentsStore.setCurrentFrontPayScene({ isFrontBindingCard: false })
      frontBindingCardRef.value?.reset?.()
    })
    console.log('handlePrePayManualClose')
  }

  return {
    frontBindingCardRef,
    visibleFrontBindingCard,
    currentSelectPaymentInfo,
    handlePrePayManualClose,
    originSelectPaymentInfo,
    originEditablePaymentInfo,
    setOriginPaymentInfo,
  }
}
