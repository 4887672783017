<template>
  <SDialog
    v-model:visible="isShow"
    :show-close="false"
    :append-to-body="true"
    class="c2p-tip-dialog"
    @close="closeDialog">
    <div class="content">
      {{ languageInfo.BS_KEY_SRC_1053 }}
    </div>

    <template #footer>
      <SButton
        :type="['primary', 'H72PX']"
        :width="'100%'"
        @click="closeDialog">
        {{ languageInfo.BS_KEY_SRC_1009 }}
      </SButton>
    </template>
  </SDialog>
</template>

<script setup lang="ts">
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'

import { ref, watch } from 'vue'

const props = defineProps({
  isShowTipDialog: {
    type: Boolean,
    default: false,
  },
  languageInfo: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits(['closeDialog'])

const isShow = ref(false)

const closeDialog = () => {
  isShow.value = false
  emits('closeDialog')
}

watch(
  () => props.isShowTipDialog,
  val => {
    isShow.value = val
  },
)
</script>
<style lang="less">
.c2p-tip-dialog {
  .content {
    text-align: center;
    color: black;
    font-size: 16px;
    font-weight: 700;
    word-wrap: break-word;
  }
}
</style>
