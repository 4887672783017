import useApis from './useApis'
import { ref, computed, type Ref } from 'vue'

type Store = {
  order: any
  errInfo: any
  showPopup: boolean
  countdown: number
  timer: any
  needCountdown: boolean
  freeVerifyErr: string
  goVerifyTipShow: boolean
  language: Record<string, string>
  onValidateFail: () => void
  onValidateSuccess: () => void
  onClose: (params?: any) => void
  onStartValidate: () => void
  onCreate: () => void
}

const defaultState = {
  order: {},
  errInfo: {},
  showPopup: false,
  countdown: 5,
  timer: null,
  needCountdown: false,
  freeVerifyErr: '',
  goVerifyTipShow: false,
  language: {},
  onValidateFail: () => {},
  onValidateSuccess: () => {},
  onClose: () => {},
  onStartValidate: () => {},
  onCreate: () => {},
}

const store: Ref<Store> = ref(defaultState)

const useCodVerifyFree = (props?: { language: Record<string, string> }) => {
  const { fetchCodVerifyFreeApi, getLanguageInfo } = useApis()

  const initStore = async () => {
    store.value = { ...defaultState }
  }

  const fetchLanguage = ({ langs }: { langs: Record<string, string> }) => {
    return getLanguageInfo({ langs })
  }

  const langText = computed(() => {
    return props?.language || store.value.language
  })

  return {
    langText,
    fetchCodVerifyFreeApi,
    fetchLanguage,
    store,
    initStore,
  }
}

export default useCodVerifyFree
