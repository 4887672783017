<template>
  <div class="payment-form-item no-underline">
    <SDatePicker
      v-model="timeDate"
      format="MM/DD/YYYY"
      :append-to-body="true"
    >
      <BaseFormItem
        ref="baseFieldRef"
        v-model="dateValue"
        inputmode="text"
        :placeholder="placeholder"
        :label="label"
        :required="true"
        :readonly="true"
        @click="handlerShowPickBirth"
      >
        <template #input-end>
          <Icon
            name="sui_icon_more_down_12px_2"
            size="12px"
            color="#959595"
            :is-rotate="cssRight"
            @click="handlerShowPickBirth"
          />
        </template>
      </BaseFormItem>
    </SDatePicker>
    <div
      v-show="errorTips"
      class="error-invalid"
    >
      {{ errorTips }}
    </div>
    <!-- <SDatePickerPanel
      ref="birthBlock"
      format="MM/DD/YYYY"
      :selectableYearRange="{ from: 1900, to: 2050 }"
    /> -->
    <!-- <SField
      v-if="isMounted"
      v-show="false"
      ref="birthBlock"
      v-model="timeDate"
      label="Time Date"
      input-mode="date"
      :value-bolder="true"
    >
      <SFieldDatePick
        slot="datePick"
        format="MM/DD/YYYY"
        :selectableYearRange="{ from: 1900, to: 2050 }"
      />
    </SField> -->
  </div>
</template>

<script setup lang="ts">
import { ref, computed, nextTick, watch } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import BaseFormItem from './BaseFormItem.vue'
import { SDatePicker } from '@shein-aidc/sui-date-picker/mobile'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

/**
 * 格式化日期为 MM/DD/YYYY
 * @param {Date | string | number} date - 要格式化的日期，可以是 Date 对象、时间戳、或字符串
 * @returns {string} 格式化后的日期字符串
 */
 function formatDate(date) {
  if (!date) return ''
  // 确保输入是一个有效的日期
  const validDate = new Date(date)
  if (isNaN(validDate)) {
    throw new Error('Invalid date')
  }

  // 获取月份、日期和年份
  const month = String(validDate.getMonth() + 1).padStart(2, '0') // 月份从 0 开始，因此加 1
  const day = String(validDate.getDate()).padStart(2, '0')
  const year = validDate.getFullYear()

  // 返回格式化结果
  return `${month}/${day}/${year}`
}

/**
 * 将 MM/DD/YYYY 格式的日期字符串转为 Date 对象
 * @param {string} dateStr - 格式为 MM/DD/YYYY 的日期字符串
 * @returns {Date} 转换后的 Date 对象
 */
 function parseDate(dateStr) {
  if (!dateStr) return ''
  // 验证输入是否符合 MM/DD/YYYY 格式
  const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/
  const match = regex.exec(dateStr)
  if (!match) {
    throw new Error('Invalid date format. Expected MM/DD/YYYY.')
  }

  // 从匹配结果中提取月份、日期和年份
  const month = parseInt(match[1], 10) // 月份
  const day = parseInt(match[2], 10) // 日期
  const year = parseInt(match[3], 10) // 年份

  // 月份需要减 1，因为在 JavaScript Date 中，月份是从 0 开始的
  return new Date(year, month - 1, day)
}

// const SDatePickerPanel = SDatePicker.SDatePickerPanel

type Validator = ({ timeDate }: { timeDate: string }) => {
  result: boolean
  failType?: string
  failReason?: string
  reasonType?: string
  message?: string
}

interface Props {
  modelValue: string; // 值
  errorMessage?: string; // 错误提示信息
  required?: boolean; // 是否必填
  label?: string; // 标签文本
  placeholder?: string; // 输入框占位文本
  clearable?: boolean; // 是否显示清除按钮
  validator?: Validator; // 自定义校验规则
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
})

const emits = defineEmits(['update:modelValue'])

const { cssRight } = useAppConfigs()?.$envs || {}

const isMounted = ref(false)
const birthBlock = ref()
const baseFieldRef = ref()
const errorTips = ref('')
const dateValue = ref(props.modelValue || '')
const timeDate = computed({
  get: () => {
    return parseDate(props.modelValue)
  },
  set: val => {
    console.log('val====', val)
    dateValue.value = formatDate(val)
    emits('update:modelValue', dateValue.value)
    nextTick(() => {
      validator()
    })
  },
})

const handlerShowPickBirth = () => {
  isMounted.value = true
  nextTick(() => {
    // console.log('birthBlock.value====', birthBlock.value)
    birthBlock.value?.click?.()
  })
}

const validator = () => {
  const { result, message = '', failType } = props.validator?.({ timeDate: timeDate.value }) || {}
  errorTips.value = !result ? message : ''

  return {
    result,
    failType,
    failReason: null,
    reasonType: 'birth_date',
  }
}

const reset = () => {
  dateValue.value = ''
  errorTips.value = ''
  emits('update:modelValue', dateValue.value)
}

watch(() => props.modelValue, () => {
  timeDate.value = parseDate(props.modelValue)
})

defineExpose({
  validator,
  reset,
  focus: () => baseFieldRef.value.focus(),
})

</script>

<style lang="less">
.payment-form-item {
  padding: 24/75rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24/75rem;
    right: 0;
    height: 1px;
    background-color: var(--sui_color_gray_light2, #e5e5e5);
  }
  &.no-underline {
    &:after {
      display: none;
    }
  }
  .error-invalid {
    font-size: 12px;
    line-height: 1;
    color: var(--sui_color_promo, #FA6338);
    margin-top: 8/75rem;
    transition: height 0.8s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
