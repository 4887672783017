<template>
  <div>
    <iframe
      id="DDC_IFRAME"
      height="1"
      width="1"
      name="DDC_IFRAME"
      style="display: none"
    ></iframe>
    <form
      id="ddcCollectForm"
      method="POST"
      target="DDC_IFRAME"
      style="display: none"
    >
      <input
        type="hidden"
        name="Bin"
      />
      <input
        type="hidden"
        name="JWT"
      />
    </form>
  </div>
</template>

<script name="DdcModule" setup lang="ts">
import { watch, reactive, nextTick } from 'vue'
import { debuggerLog } from '../../../utils'

const props = withDefaults(defineProps<{
  action: string;
  cardBin: string;
  jwt: string;
}>(), {
  action: '',
  cardBin: '',
  jwt: '',
})


const ddcConfig = reactive({
  action: '',
  cardBin: '',
  jwt: '',
})

watch([
  () => props.action,
  () => props.jwt,
  () => props.cardBin,
], () => {
  ddcConfig.action = props.action
  ddcConfig.cardBin = props.cardBin
  ddcConfig.jwt = props.jwt
  debuggerLog('DDC--==DdcModule==props===', props.action, props.jwt, props.cardBin)
}, { immediate: true })


const submit = async () => {
  await nextTick()
  if (ddcConfig.action) {
    (document.getElementById('ddcCollectForm') as any).action = ddcConfig.action
  }
  if (ddcConfig.cardBin && ddcConfig.jwt) {
    // @ts-ignore
    document.querySelector('#ddcCollectForm input[name=\'Bin\']').value = ddcConfig.cardBin
    // @ts-ignore
    document.querySelector('#ddcCollectForm input[name=\'JWT\']').value = ddcConfig.jwt
  }
  if (ddcConfig.action && ddcConfig.cardBin && ddcConfig.jwt) {
    debuggerLog('DDC--==DdcModule==submit--=', ddcConfig.action, ddcConfig.jwt, ddcConfig.cardBin);
    (document.getElementById('ddcCollectForm') as any)?.submit?.()
  }
}

defineExpose({
  submit,
})

</script>
