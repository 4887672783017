<template>
  <div class="payment-form-item form-item__type-select">
    <label tabindex="0">{{ label }}<i>*</i></label>
    <div class="radio-block">
      <SRadio
        v-for="(item, index) in selectConfig"
        :key="index"
        v-model="cardTypeSelect"
        class="type-item type-item-last"
        :label="item.label"
      >
        {{ item.text }}
      </SRadio>
    </div>
  </div>
</template>

<script setup lang="ts" name="KRCardTypeSelectItem">
import { SRadio } from '@shein-aidc/sui-radio/mobile'
import { ref, watch } from 'vue'

interface Props {
  modelValue: string | number; // 值
  label: string; // 标签文本
  selectConfig: Array<{
    label: string | number;
    text: string;
  }>; // 选择配置
}

const emits = defineEmits(['update:modelValue', 'change'])
const props = withDefaults(defineProps<Props>(), {
  value: '',
  label: '',
})

const cardTypeSelect = ref(props.modelValue)

watch(cardTypeSelect, (val) => {
  emits('update:modelValue', val)
  emits('change', { value: val })
})

</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, declaration-no-important, selector-max-specificity, selector-max-type, selector-nested-pattern */
.form-item__type-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.3rem;

  .radio-block {
    .type-item {
      margin-bottom: unset;
    }
    .S-radio:last-of-type {
      margin-right: unset !important;
    }
  }

  /deep/.icons-select {
    margin-left: unset !important;
  }
}
</style>
