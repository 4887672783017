<template>
  <div
    class="field-slide"
    :class="{'toggle-show': visible}"
  >
    <slot></slot>
  </div>
</template>

<script setup>
defineProps({
  visible: Boolean,
})
</script>

<style scoped lang="less">
.field-slide {
  max-height: 0;
	transition: max-height 1s;
	overflow: hidden;
	&.toggle-show {
		max-height: 500px;
	}
}
</style>
