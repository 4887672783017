<template>
  <SDialog
    :visible.sync="isShow"
    :append-to-body="true"
    :show-close="true"
    class="c2p-tip-dialog"
    @close="closeDialog">
    <div class="content">
      {{ nowLanguageInfo.BS_KEY_SRC_1056 }}
    </div>

    <template #footer>
      <SButton
        :type="['primary', 'H72PX']"
        :width="'100%'"
        @click="closeDialog">
        {{ nowLanguageInfo.BS_KEY_SRC_1057 }}
      </SButton>
    </template>
  </SDialog>
</template>

<script setup lang="ts">
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { ref, watch, computed } from 'vue'
import { useGetLang } from '../../hooks/useGetLang'

const props = defineProps({
  isShowDialog: {
    type: Boolean,
    default: false,
  },
  c2pInfo: {
    type: Object,
    default: () => ({}),
  },
  languageInfo: {
    type: Object,
    default: () => ({}),
  },
})

const { languageInfo: lang } = useGetLang({ isNoGetLang: ('BS_KEY_SRC_1057' in (props.languageInfo || {})) })

const emits = defineEmits(['switch-payment', 'close'])

const isShow = ref(false)

const nowLanguageInfo = computed(() => {
  return {
    ...lang.value,
    ...props.languageInfo,
  }
})

const close = () => {
    isShow.value = false
    emits('close', false)
}

const closeDialog = () => {
  close()
  emits('switch-payment')
}

watch(
  () => props.isShowDialog,
  val => {
    isShow.value = val
  },
  {
    immediate: true,
  },
)
</script>


<style lang="less">
.c2p-tip-dialog {
  .content {
    text-align: center;
    color: black;
    font-size: 16px;
    font-weight: 700;
    word-wrap: break-word;
  }
}
</style>
