<template>
  <div class="warning-extra-tip">
    <Icon
      name="sui_icon_caution_18px"
      size="14px"
      :is-rotate="cssRight"
    />
    <p
      class="content"
    >
      {{ warning }}
    </p>
  </div>
</template>

<script name="PaymentDescription" setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue3'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

interface PaymentDescriptionProps {
  warning: string
}

withDefaults(defineProps<PaymentDescriptionProps>(), {
  warning: '',
})
const { cssRight } = useAppConfigs()?.$envs || {}
</script>

<style lang="less" scoped>
.warning-extra-tip {
  font-size: 12px;
  color: @sui_color_unusual;
  display: flex;

  span {
    position: relative;
    top: 2px;
  }
}

.content {
  margin-left: 4 / 75rem;
}
</style>
