<template>
  <div class="src-card-list">
    <div class="src-card-list__logo">
      <i class="icon-src"></i>
    </div>
    <div class="divine"></div>
    <div 
      v-for="(item, index) in srcCardList"
      :key="index"
      class="src-card-list__card"
    >
      <img
        :src="item.url"
        class="card-background" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

const props = defineProps({
  cardLogoList: {
    type: Array,
    default: () => [],
  },
  cardType: {
    type: String,
    default: '',
  },
  nowCardLogo: {
    type: String,
    default: '',
  },
  cardTypeList: {
    type: Array,
    default: () => [],
  },
})

const allLogoList = ref([
  {
    url: 'https://img.ltwebstatic.com/images3_ccc/2025/02/07/52/173890966315f6143315a2e7e8424d3211d7daa8af.png',
    type: 'mastercard',
  },
  {
    url: 'https://img.ltwebstatic.com/images3_ccc/2025/02/07/bc/1738909837c9f16435acd0ce43d61412567082dd1a.png',
    type: 'visa',
  },
  {
    url: 'https://img.ltwebstatic.com/images3_ccc/2025/02/07/0c/1738909874d03c36aee43f51858ed269300b576bbe.png',
    type: 'amex',
  },
  {
    url: 'https://img.ltwebstatic.com/images3_ccc/2025/02/07/fb/17389099091ddd35dee8dbc309cc2ea62f88365017.png',
    type: 'discover',
  },
])

const srcCardList = computed(() => {
  if (props.nowCardLogo?.length > 0) {
    return [{ url: props.nowCardLogo }]
  }
  const tempList = (props.cardLogoList || [])?.length > 0 ? props.cardLogoList : allLogoList.value
  if (props.cardType) {
    return tempList.filter(item => item?.type === props.cardType)
  }

  if (props.cardTypeList?.length > 0) {
    const tempTypeList = props.cardTypeList?.map(item => item?.toLocaleLowerCase())
    return tempList.filter(item => tempTypeList.includes(item?.type))
  }

  return tempList
})
</script>

<style lang="less" scoped>
.src-card-list {
  display: flex;
  align-items: center;
  .src-card-list__logo {
    width: 24 * 2/75rem;
    height: 15 * 2/75rem;
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2025/01/23/2d/17376229308eee3716a2f614a65df08370e5efc053.png');
    background-size: 100% 100%;
  }
  .divine {
    content: '';
    width: 1px; // 竖线宽度
    height: 15 * 2/75rem; // 竖线高度
    background-color: #000; // 竖线颜色
    margin: 0 4 * 2/75rem; // 竖线与图标的间距
  }

  .src-card-list__card {
    max-width: 27*2/75rem;
    margin-right: 4 * 2/75rem;
    display: inline-flex;
  }

  .card-background {
    width: 100%;
    vertical-align: middle;
    background-size: 100% 100%;
  }
}
</style>
