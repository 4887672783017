import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { CO_BRAND_CARD_FIRST_ORDER_UI_TYPE } from '../common/constants'

// 加工联名卡权益文案
export const processCoBrandCardBenefits = (item: Trade_PayToolKit.PaymentsPreferentialTips = {}) => {
  const { imageUrl, uiType, titles } = item
  const firstTitle = titles?.[0] || ''

  const isFirstOrder = uiType === CO_BRAND_CARD_FIRST_ORDER_UI_TYPE
  const imgNode = `<img style="height: 12px;margin:0 4px;" src="${imageUrl}" />`
  return isFirstOrder ? titles.join(imgNode) : firstTitle
}
