<template>
  <SLazyMount>
    <SDrawer
      v-model:visible="isShow"
      class="token-cvv-drawer"
      :direction="'btt'"
      :append-to-body="true"
      @close="close"
      @close-from-icon="cancel"
      @close-from-mask="cancel"
    >
      <template #title>
        <div class="cvv-title">
          <Icon
            name="sui_icon_guarantee_18px"
            size="16px"
            color="#198055" />
          <span>{{ language.BS_KEY_PAY_1231 }}</span>
        </div>
      </template>
      <div
        :class="{
          'cvv-block': true,
          'cvv-block-new': isShowOtpCodeInput
        }">
        <div class="card-num">
          <img
            v-if="cardLogo"
            :src="cardLogo" />
          {{ cardNo }}
        </div>
        <template v-if="isShowOtpCodeInput">
          <div class="otp-code-wrapper">
            <OtpCodeInput
              ref="otpCodeInput"
              v-model="cvvData"
              :error-tips="error"
              class="otp-code-number"
              :digits="knownCvvDigits"
              :is-encrypted="isEncrypted"
              :cssRight="cssRight"
              :click-eye-event="triggerSecure"
              @focus="tokenCvvClear"
              @blur="handleBlur"
              @submit="handleFinish" />
          </div>
        </template>
        <template v-else>
          <div class="input-block">
            <SInput
              v-model.trim.number="cvvData"
              :class="{
                'cvv-input': true,
                'cvv-input-error': !!error,
              }"
              :label="language.BS_KEY_PAY_1167"
              max-length="4"
              :type="isEncrypted ? 'password' : 'text'"
              :placeholder="language.BS_KEY_PAY_1235"
              @focus="tokenCvvClear"
              @input="handlerKeyup"
              @blur="handleBlur">
              <template #suffix>
                <div
                  class="cvv-input__icon"
                  @click.stop="triggerSecure">
                  <Icon
                    v-if="isEncrypted"
                    key="close"
                    name="sui_icon_eyeclosed_20px"
                    size="16px"
                    :is-rotate="cssRight" />
                  <Icon
                    v-else
                    key="open"
                    name="sui_icon_eyeopen_20px"
                    size="16px"
                    :is-rotate="cssRight" />
                </div>
              </template>
            </SInput>
          </div>
        </template>
        <p
          v-if="error.length > 0"
          class="err-tip">
          {{ error }}
        </p>
        <p class="cvv-tip">
          <span @click="isShowCvvTip = true">{{ language.BS_KEY_PAY_1232 }}</span>
        </p>
      </div>
      <template #footer>
        <div class="footer-wrap">
          <SButton
            :type="['primary', 'H88PX']"
            width="100%"
            @click="handlerPay">
            {{ language.BS_KEY_PAY_1233 }}
          </SButton>
        </div>
      </template>
    </SDrawer>
  </SLazyMount>
  <!-- cvv 提示弹窗 -->
  <SLazyMount>
    <SDialog
      v-model:visible="isShowCvvTip"
      :show-close="true"
      :append-to-body="true"
      @close="closeCvvTip">
      <template #title>
        {{ language.BS_KEY_PAY_1232 }}
      </template>
      <div class="cvv-tip-content">
        <p tabindex="0">
          {{ language.BS_KEY_PAY_1139 }}
        </p>
        <img
          :src="imgSrc"
          aria-hidden="true" />
      </div>
      <template #footer><div></div></template>
    </SDialog>
  </SLazyMount>
</template>

<script name="CvvDrawer" setup lang="ts">
/*
 * 支付方式组件: cvv 抽屉
 *
 * */

// components
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SInput } from '@shein-aidc/sui-input/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import OtpCodeInput from '../otp-code-input/Index.vue'

// utils
import { ref, computed, defineEmits, nextTick, watch, inject } from 'vue'
import { transformImg } from '@shein/common-function'
import { PaymentsStore } from '../../../hooks/usePayments'
import { type LangKeys } from '../../../common/languages'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { debuggerLog } from '../../../utils'
import type { AS_PayToolkit } from '../../../types'
const { triggerNotice } = (inject('analysisInstance', null) || {}) as AS_PayToolkit.AnalysisInstance

const VISA_ID = 'visa'
const AMEX_ID = 'amex'
const MAESTRO_ID = 'maestro'
const MASTERCARD_ID = 'mastercard'
const SPECIAL_CVV_LIST = ['0000'] // 特殊的不支持输入的 cvv 卡号

interface CvvDrawerProps {
  language: Record<LangKeys, string>;
  paymentsStore: PaymentsStore;
  bsPaymentAction: (info: { action: Trade_PayToolKit.BsPaymentAction; payload: Record<string, any> }) => void;
}

const props = withDefaults(defineProps<CvvDrawerProps>(), {
  bsPaymentAction: () => { },
})

const otpCodeInput = ref()

const isShow = ref(false)
const cvvData = ref('')
const isEncrypted = ref(true)
const error = ref('')
const isShowCvvTip = ref(false)
const imgSrc = ref(
  'https://img.ltwebstatic.com/images3_ccc/2024/09/02/2b/172526160143947d8be76dd214e4230f49b34adc84.jpg',
)
const isHandlerPay = ref(false) // 是否点击了支付按钮
const installments = ref(1)
const coverData = ref({})
// 判断是否已经生成订单
const isOrderAdded = ref(false)
// 缓存支付失败地址
const cvvPayFailUrl = ref('')

const emit = defineEmits(['pay-now'])

const { cssRight, NODE_SERVER_ENV } = useAppConfigs()?.$envs || {}

const selectedPayment = computed(() => {
  return props?.paymentsStore?.selectedPayment || {}
})

// 区分是路由卡还是路由卡分期
const nowUseTokenData = computed(() => {
  return props.paymentsStore?.editablePaymentInfo?.[selectedPayment.value?.code]?.card_token_info || {}
})

const cardId = computed(() => {
  return nowUseTokenData.value?.id || ''
})


const cardType = computed(() => {
  return (nowUseTokenData.value?.card_type || '')?.toLowerCase() || ''
})

const cardLogo = computed(() => {
  const logo = nowUseTokenData.value?.web_logo
  if(!logo)return ''
  return transformImg({ img: logo })
})

const cardNo = computed(() => {
  const cardNo = nowUseTokenData.value?.card_no || ''
  if (!cardNo) return ''
  return cardNo.padStart(16, '*').replace(/(.{4})(?=.)/g, '$1 ')
})

const isShowCvvDrawer = computed(() => {
  return nowUseTokenData.value?.need_cvv === '1'
})

const isMAESTRO = computed(() => {
  return cardType.value == MAESTRO_ID // 是否是 maestro 卡
})

// 已知cvv的位数，undefined表示不限制位数
const knownCvvDigits = computed<number | undefined>(() => {
  if (!cardType.value) return undefined

  switch (cardType.value) {
    case AMEX_ID:
      return undefined
    case MAESTRO_ID:
    case VISA_ID:
    case MASTERCARD_ID:
      return 3
    default:
      return 3
  }
})

// 是否显示cvv新交互
const isShowOtpCodeInput = computed(() => {
  const isSegmentedCvv = props.paymentsStore.abtInfo?.CardtokenCvvExp?.param?.CardtokenCvvshow === 'Show'
  return knownCvvDigits.value && isSegmentedCvv
})

const handlerKeyup = (value = cvvData.value) => {
  nextTick(() => {
    cvvData.value = isShow.value ? value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ') : ''
  })
}

const handleBlur = ()=>{
  window.scrollTo(0, 0)
  checkTokenCvv()
}

// 校验cvv，并返回埋点参数
const checkTokenCvv: () => {
  is_cvvnolimit: 0 | 1,
  result_reason: 'null_error' | 'digit_error' | 'valid_error' | '-'
} = () => {
  handlerKeyup()
  const cvv = cvvData.value.replace(/\s/g, '')
  error.value = ''
  // 埋点参数
  const is_cvvnolimit = cvv == '000' || cvv == '0000' || cvv.length === 0 ? 0 : 1

  const regObj = {
    AMEX: /^\d{3,4}$/,
    MAESTRO: /^(\d{3}|\s{0})$/,
    other: /^\d{3}$/,
  }

  let reg = /^\d{3,4}$/ // 默认匹配不上,卡种返回异常
  if (cardType.value) {
    reg = regObj[cardType.value.toUpperCase()] || regObj['other']
  }

  const isNumOfDigitsError = !reg.test(cvv)

  const isInvalidNumber = SPECIAL_CVV_LIST.includes(cvv)

  // 当卡种返回非AE卡（包括V/M）时，输入框CVV支持输入3位000；
  if (!isNumOfDigitsError || cvv == '000') return {
    is_cvvnolimit,
    result_reason: '-',
  }

  // 当卡种返回AE卡时，输入框CVV支持输入3位000或者4位0000；
  if (cardType.value == AMEX_ID && cvv == '0000') return {
    is_cvvnolimit,
    result_reason: '-',
  }
  if (cvv.length === 0 || isNumOfDigitsError || isInvalidNumber) {
    error.value = cvv.length === 0 ? props.language.BS_KEY_PAY_1237 : props.language.BS_KEY_PAY_1234

    if (!error.value && ['localhost', 'debug'].includes(NODE_SERVER_ENV)) {
      error.value = 'props.language.BS_KEY_PAY_1237'
    }

    let result_reason: 'null_error' | 'digit_error' | 'valid_error' | '-' = '-'
    switch (true) {
      case cvv.length === 0:
        result_reason = 'null_error'
        break
      case isNumOfDigitsError:
        result_reason = 'digit_error'
        break
      case isInvalidNumber:
        result_reason = 'valid_error'
        break
      default:
        break
    }

    return {
      is_cvvnolimit,
      result_reason,
    }
  }
  return {
    is_cvvnolimit,
    result_reason: '-',
  }
}

const handleFinish = () => {
  handlerPay('auto')
}

const triggerSecure = () => {
  isEncrypted.value = !isEncrypted.value
  triggerNotice({
    id: 'click_cvv_encryption:simple',
    data: {
      is_encryption: isEncrypted.value,
    },
  })
}

const tokenCvvClear = () => {
  error.value = ''
}

const closeCvvTip = () => {
  isShowCvvTip.value = false
}

const resetInfo = () => {
  cvvData.value = ''
  error.value = ''
  isHandlerPay.value = false
  coverData.value = {}
}

const reportClickConfirm = (confirm_scene: 'manual' | 'auto', otherParams) => {
  let card_type = nowUseTokenData.value?.card_type || ''
  const knownCardTypes = [VISA_ID, AMEX_ID, MAESTRO_ID, MASTERCARD_ID]

  if (card_type === '') {
    card_type = 'null'
  } else if (!knownCardTypes.includes(card_type.toLowerCase())) {
    card_type = 'other'
  }

  triggerNotice({
    id: 'click_cvv_pay:simple',
    data: {
      tokenid: cardId.value,
      verify_result: error.value?.length > 0 ? 0 : 1,
      is_default_confirm: confirm_scene === 'auto' ? 1 : 0,
      card_type,
      ...otherParams,
    },
  })
}

const handlerPay = (confirm_scene: 'manual' | 'auto' = 'manual') => {
  isHandlerPay.value = true
  const reportParams = checkTokenCvv()

  reportClickConfirm(confirm_scene, reportParams)

  if (error.value?.length > 0) {
    return
  }
  emit('pay-now', {
    isSegmentedCvv: isShowOtpCodeInput.value,
    cvv: (cvvData.value || '').trim(),
    installments: installments.value || 1,
    isMAESTRO: isMAESTRO.value,
    coverData: coverData.value,
    errorCb: () => {
      isShow.value = false
      resetInfo()
    },
  })
  tokenCvvClear()
  // 新的cvv交互不关闭弹窗
  if (!isShowOtpCodeInput.value) {
    isShow.value = false
    nextTick(() => {
      resetInfo()
    })
  }
}

const close = () => {
  isShow.value = false
  tokenCvvClear()
}

const cancel = () => {
  close()
  resetInfo()

  // 已经生成订单的关闭后进入订单详情页面
  if (isOrderAdded.value) {
    props.bsPaymentAction?.({
      action: 'cvvDrawerCloseWithOrder',
      payload: {
        cvvPayFailUrl: cvvPayFailUrl.value,
      },
    })
  } else {
    props.paymentsStore?.paymentEventBus?.paymentValidSendBi?.emit({
      result_reason: '1_10',
    })
  }
}

const showDrawer = () => {
  isShow.value = true
  isOrderAdded.value = false

  triggerNotice({
    id: 'expose_cvv_encryption:simple',
    data: {
      is_encryption: isEncrypted.value,
    },
  })
}

const validateByPay = (opts?: Trade_PayToolKit.PreCreatePaymentVerifyOptions) => {
  debuggerLog('cvv validateByPay >>>', opts)
  if (opts?.installments) {
    installments.value = opts.installments as number
  }
  if (opts?.lastAddOrderExtraParams?.coverData) {
    coverData.value = opts.lastAddOrderExtraParams.coverData
  }
  if (opts?.has_cvv) return {
    validateResult: true,
    scene: 'token_cvv_drawer',
  }

  checkTokenCvv()

  const isMAESTROHandlerPay = isMAESTRO.value && isHandlerPay.value // maestro 卡点击支付按钮
  const isNeedShowByMAESTRO = isShowCvvDrawer.value && !isHandlerPay.value && isMAESTRO.value
  if ((isShowCvvDrawer.value && (error.value?.length > 0 && !isMAESTROHandlerPay)) || isNeedShowByMAESTRO) {
    error.value = ''
    showDrawer()
    return {
      validateResult: false,
      scene: 'token_cvv_drawer',
      metric_scene: 'payment_token_cvv_empty',
      saParams: {
        result_reason: '1_10',
      },
    }
  }

  return {
    validateResult: true,
    scene: 'token_cvv_drawer',
  }
}

// 处理 cvv drawer 交互
const updateCvvDrawerInfo = (
  opt: {
    showErrorTips?: Boolean;
    errorTips?: string;
    isOrderAdded?: Boolean;
    closeCvvDrawer?: Boolean;
    cvvPayFailUrl?: string;
  } = {},
) => {
  const {
    showErrorTips,
    errorTips = props.language.BS_KEY_PAY_1234,
    isOrderAdded: hasOrder,
    closeCvvDrawer,
    cvvPayFailUrl: failUrl,
  } = opt
  isOrderAdded.value = !!hasOrder
  cvvPayFailUrl.value = failUrl || ''
  if (isShowOtpCodeInput.value) {
    showErrorTips && (error.value = errorTips)
    if (closeCvvDrawer) {
      close()
      resetInfo()
    }
  } else {
    showErrorTips && (error.value = errorTips)
  }
}

watch(() => cardId.value, () => {
  resetInfo()
})

defineExpose({
  validateByPay,
  updateCvvDrawerInfo,
})
</script>

<style lang="less">
.token-cvv-drawer {
  // 处理圆角
  .sui-drawer__container {
    border-radius: 12*2/75rem 12*2/75rem 0 0;

    .sui-drawer__header {
      border-bottom: none;
      position: relative;

      .sui-drawer__close-btn {
        width: auto;
        height: auto;
        line-height: 1;
        padding: 10*2/75rem 12*2/75rem;
        font-size: 0;
        color: #666;
        right: 0;
        top: 0;

        > .sui-icons {
          font-size: 16*2/75rem;
        }
      }
    }
  }

  .cvv-title {
    display: flex;
    column-gap: 4*2/75rem;
    justify-content: center;
    align-items: center;
    color: #222;
  }

  .cvv-block {
    padding: 14*2/75rem 12*2/75rem 24*2/75rem;

    .card-num {
      color: #000;
      font-size: 14*2/75rem;
      font-weight: 400;
      text-transform: uppercase;
      word-wrap: break-word;
      margin-bottom: 12*2/75rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 8*2/75rem;

      >img {
        height: 20*2/75rem;
      }
    }

    .input-block {
      width: 100%;

      > .cvv-input {
        border: 1px solid #E5E5E5;

        &.cvv-input-error {
          border-color: #bf4123;
        }

        &:after {
          display: none;
        }

        .sui-input__label {
          color: #666;
        }

        .sui-input__right-icon {
          right: 0;
          z-index: 1;
        }

        .cvv-input__icon {
          display: inline-block;
          padding: 30*2/75rem 12*2/75rem 12*2/75rem;
        }
      }
    }

    .err-tip {
      padding-top: 4*2/75rem;
      color: #bf4123;
      font-size: 12*2/75rem;
      line-height: 14*2/75rem;
      font-weight: 400;
      word-wrap: break-word;
    }

    .cvv-tip {
      padding-top: 6*2/75rem;
      text-align: right;

      >span {
        display: inline-block;
        padding: 6*2/75rem 0;
        font-weight: 400;
        font-size: 12*2/75rem;
        line-height: 14*2/75rem;
        color: #767676;
      }
    }
  }

  .cvv-block-new {
    padding-top: 16*2/75rem;

    .card-num {
      justify-content: center;
    }

    .cvv-tip {
      padding-top: 18*2/75rem;
      text-align: center;
    }

    .err-tip {
      text-align: center;
      padding: 14*2/75rem 0 0;
    }
  }

  .sui-drawer__normal-footer {
    margin-top: unset;
    height: unset;
    line-height: unset;
    padding: 16/75rem 24/75rem;
  }
}

.cvv-tip-content {
  text-align: center;

  p {
    color: #333;
    padding: 12*2/75rem 0;
  }

  img {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
</style>
