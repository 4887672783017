<template>
  <SDialog
    v-model:visible="isShow"
    :append-to-body="true"
    :show-close="true"
    class="src-phone-email-confirm-dialog"
    @close="closeDialog"
  >
    <div class="content">
      <div class="title"> {{ languageInfo.BS_KEY_SRC_1043 }} </div>
      <div class="tip">
        <Icon
          name="sui_icon_guarantee_12px"
          size="12px" />
        <span class="tip-text">{{ languageInfo.BS_KEY_SRC_1044 }}</span>
      </div>

      <div class="validate-block">
        <div class="validate-input phone-input">
          <div class="title"> {{ languageInfo.BS_KEY_SRC_1021 }} </div>
          <div class="phone-input__content">
            <span
              class="country-area"
              @click="isShowAreaCodeDrawer = true"
            >{{ prevStageSelected }} +{{ phoneInfo.code }} <i class="suiiconfont sui_icon_more_s_triangle_down_12px"></i></span>
            <input
              ref="phoneInputRef"
              v-model="phoneInfo.phone"
              :placeholder="languageInfo.BS_KEY_SRC_1022"
              class="input-block"
              @blur="nowHandlerPhoneBlur"
              @focus="phoneInputFocus"
            />
          </div>
        </div>

        <p v-if="phoneInfo.errTip" class="err-tip">
          {{ phoneInfo.errTip }}
        </p>

        <div class="validate-input">
          <SInput
            v-model="emailInfo.email"
            :label="languageInfo.BS_KEY_SRC_1017"
            :placeholder="languageInfo.BS_KEY_SRC_1018"
            @blur="handlerEmailBlur"
            @focus="EmailInputFocus" />
        </div>

        <p v-if="emailInfo.errTip" class="err-tip">
          {{ emailInfo.errTip }}
        </p>
      </div>
    </div>

    <template #footer>
      <SButton
        class="click-to-pay-btn"
        :type="['primary', 'H72PX']"
        :width="'100%'"
        @click="submit">
        {{ languageInfo.BS_KEY_SRC_1009 }}
      </SButton>
    </template>
    <BSrcAreaCodeListDrawer
      :countryCodes="countryCodes"
      :codeId="phoneInfo.id"
      :languageInfo="languageInfo"
      :show="isShowAreaCodeDrawer"
      @select-item="setPhoneCode"
      @close="isShowAreaCodeDrawer = false"
    />
  </SDialog>
</template>

<script setup lang="ts">
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SInput } from '@shein-aidc/sui-input/mobile'
import { ref, watch, onMounted } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { useGetPhoneEmail } from '../../hooks/useGetPhoneEmail'
import BSrcAreaCodeListDrawer from '../area-code-list/index.vue'
import { debuggerLog } from '../../utils/index'
// import { useGetLang } from '../../hooks/useGetLang'

// const { languageInfo } = useGetLang()

const props = defineProps({
  isShowDialog: {
    type: Boolean,
    default: false,
  },
  countryId: {
    type: String,
    default: '226',
  },
  countryList: {
    type: Array,
    default: () => [],
  },
  languageInfo: {
    type: Object,
    default: () => ({}),
  },
  isPay: {
    type: Boolean,
    default: false,
  },
  c2pPhoneAndEmailInfo: {
    type: Object,
    default: () => {},
  },
})

const {
  emailInfo,
  phoneInfo,
  phoneInputFocus,
  EmailInputFocus,
  prevStageSelected,
  handlerPhoneBlur,
  countryCodes,
  setPhoneCode,
  handlerEmailBlur,
  setAllCountryInfo,
} =
  useGetPhoneEmail({ countryId: props.countryId, props })

const emits = defineEmits(['submit', 'getAllCountryList', 'phoneValidateFn', 'close'])

const isShow = ref(false)
const isShowAreaCodeDrawer = ref(false)

const closeDialog = () => {
  isShow.value = false
  emits('close')
}

const validateCb = () => {
    if (phoneInfo.phone?.length === 0 || emailInfo.email?.length === 0 ||
      phoneInfo.errTip?.length > 0 || emailInfo.errTip?.length > 0) {
      return
    }

    emits('submit', {
      phone: phoneInfo.phone,
      email: emailInfo.email,
      area_code: phoneInfo.code,
    })

    closeDialog()
}

const submit = () => {
  handlerEmailBlur()
  nowHandlerPhoneBlur({
    isSubmit: true,
  })
}

const nowHandlerPhoneBlur = ({
  isSubmit = false,
} = {}) => {
  if (props.isPay) {
    const nowParams = {
        alias_type: 2,
        area_code: phoneInfo.code,
        alias: phoneInfo.phone,
        area_abbr: prevStageSelected.value,
    }
    emits('phoneValidateFn', nowParams, (res) => {
      debuggerLog('res >>>', res)
      if (res?.code !== 0) {
          phoneInfo.errTip = props?.languageInfo?.BS_KEY_SRC_1048 || res?.msg || ''
      } else {
        isSubmit && validateCb()
      }
    })
  } else {
    handlerPhoneBlur(() => validateCb())
  }
}

const initInfo = () => {
  if (Object.keys(props.c2pPhoneAndEmailInfo || {}).length > 0) {
    phoneInfo.phone = props.c2pPhoneAndEmailInfo?.phone || ''
    emailInfo.email = props.c2pPhoneAndEmailInfo?.email || ''
  }
}

watch(
  () => props.isShowDialog,
  val => {
    isShow.value = val
    if (val) {
      initInfo()
    }
  },
)

onMounted(() => {
  if (props.isPay) {
    emits('getAllCountryList', (res) => {
      setAllCountryInfo(res)
    })

    initInfo()
  }
})
</script>

<style lang="less">
.src-phone-email-confirm-dialog {
  .content {
    .title {
      color: black;
      font-size: 16px;
      font-weight: 700;
      word-wrap: break-word;
      margin-bottom: 4 * 2/75rem;
      text-align: center;
      margin-top: 8 * 2/75rem;
      // 超出一行展示省略号
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .tip {
      color: #198055;
      font-size: 12px;
      font-weight: 400;
      word-wrap: break-word;
      margin-bottom: 8 * 2/75rem;
      text-align: center;
    }

    .tip-text {
        margin-left: 2 * 2/75rem;
    }

    .validate-input {
      border: 1px #e5e5e5 solid;
      margin: 12 * 2/75rem 0;
    }

    .S-Field::after {
      border-top: unset;
    }
    .S-Field__left {
      padding: 10 * 2/75rem 12 * 2/75rem;
    }
    .phone-input {
      padding: 0.32rem 0.32rem;
      .title {
        color: #767676;
        font-size: 14px;
        line-height: normal;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
      }
      .country-area {
        margin-right: 4*2/75rem;
      }
      .input-block {
        border: 0;
        outline: 0;
        padding: 0;
        margin: 0;
        resize: none;
        font-family: inherit;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-size: 14px;
      }

      input::placeholder {
        color: #BBBBBB;
      }
    }

    .err-tip {
      color: #BF4123;
      margin: 0 0.32rem;
      margin-top: 0.10666rem;
      text-align: left;
      }
  }
}
</style>
