<template>
  <SDrawer
    :visible="visible"
    append-to-body
    direction="btt"
  >
    <img
      class="coupon-bg"
      :src="couponBgImg" />
    <div class="coupon-drawer">
      <Coupon
        :couponInfo="nowUseCouponInfo"
        :language="language" />
      <div class="coupon-desc">
        <img
          :src="couponReminderImg"
          class="coupon-reminder"
          alt="" />
        <span v-html="desc"></span>
      </div>
      <template v-if="billno.length > 0">
        <div
          class="coupon-modify"
          @click="handleConfirm">
          {{ language.BS_KEY_PAY_1204 }}
        </div>
        <div
          class="coupon-cancel"
          @click="handleCancel">
          {{ language.BS_KEY_PAY_1205 }}
        </div>
      </template>
      <template v-else>
        <div
          class="coupon-modify"
          @click="confirmNotUseCoupon">
          {{ language.BS_KEY_PAY_1206 }}
        </div>
        <div
          class="coupon-cancel"
          @click="handleConfirm">
          {{ language.BS_KEY_PAY_1207 }}
        </div>
      </template>
    </div>
  </SDrawer>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import Coupon from './CouponInfo.vue'
import { template } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
const { triggerNotice } = (inject('analysisInstance', null) || {})

const appConfigs = useAppConfigs()

const props = defineProps({
  visible: { type: Boolean, default: false },
  billno: { type: String, default: '' },
  language: {
    type: Object,
    default: () => {},
  },
  nowUseCouponInfo: {
    type: Object,
    default: () => ({}),
  },
})

const couponBgImg = ref(
  'https://img.ltwebstatic.com/images3_ccc/2024/10/15/93/1728993098a7c68a41e8dd150c94341faf669e9494.png',
)
const couponReminderImg = ref(
  'https://img.ltwebstatic.com/images3_ccc/2024/10/15/f9/1728993205a286e88b2f2cfc8522e1678d496dbb71.png',
)

const emits = defineEmits(['close', 'clearCardBin', 'confirmNotUseCoupon'])

const cardName = computed(() => {
  return props.nowUseCouponInfo?.titles?.[1]
})

const desc = computed(() => {
  return template('<b>' + cardName.value + '</b>', props.language?.BS_KEY_PAY_1208) || ''
})

const handleClose = () => {
  emits('close')
}

const handleConfirm = () => {
  triggerNotice({
    id: 'click_cardno_modify.comp_coun-bin',
    extraData: {
      click_type: 'modify',
    },
  })
  emits('clearCardBin') // 将所有的卡号进行清空
  handleClose()
}

const handleCancel = () => {
  triggerNotice({
    daId: 'click_cardno_modify.comp_coun-bin',
    extraData: {
      click_type: 'cancel',
    },
  })

  const langPath = appConfigs.$envs?.langPath
  window.location.href = `${langPath}/user/orders/detail/${props.billno}`

  handleClose()
}

const confirmNotUseCoupon = () => {
  emits('confirmNotUseCoupon')
  handleClose()
}
</script>

<style lang="less">
.coupon-bg {
  width: 100%;
  height: 58.5/37.5rem;
  margin-bottom: -35.5/37.5rem;
}
.coupon-close {
  width: 16/37.5rem;
  height: 16/37.5rem;
  position: absolute;
  top: 2/37.5rem;
  right: 2/37.5rem;
}
.coupon-drawer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .coupon-circle {
    height: 10/37.5rem;
    margin-top: 5/37.5rem;
  }
  .coupon-desc {
    color: var(---sui_color_unusual, #bf4123);
    font-family: 'SF Pro';
    font-size: 14/37.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 327/37.5rem;
    margin-top: 12/37.5rem;
    margin-bottom: 20/37.5rem;

    .coupon-reminder {
      width: 16/37.5rem;
      height: 16/37.5rem;
      position: relative;
      top: 0;
      margin-right: 8 * 2/75rem;
    }
  }

  .coupon-modify {
    width: 351/37.5rem;
    height: 46/37.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: #000;
    color: #fff;
    font-family: Inter;
    font-size: 16/37.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .coupon-cancel {
    width: 351/37.5rem;
    height: 40/37.5rem;
    border: 0.5/37.5rem solid var(---sui_color_gray_light2, #bbb);
    background: var(---sui_color_white, #fff);
    color: var(---sui_color_gray_dark1, #000);
    text-align: center;
    font-family: 'SF UI Text';
    font-size: 16/37.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 12/37.5rem;
    margin-bottom: 42/37.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
