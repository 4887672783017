<template>
  <div
    class="form-item-slide"
    :class="{'toggle-show': isVisble}"
  >
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue'
const props = defineProps({
  visible: Boolean,
})

const isVisble = ref(false)

watch(() => props.visible, (val) => {
  nextTick(() => {
    isVisble.value = !!val
  })
}, { immediate: true })


</script>

<style scoped lang="less">
.form-item-slide {
  max-height: 0;
	transition: max-height .6s;
	overflow: hidden;
	&.toggle-show {
		max-height: 500px;
	}
}
</style>
