<template>
  <div class="bin-list">
    <div
      v-for="item in resultTokenBinList"
      :key="item.id"
      class="bin-item"
      :class="[
        {
          selected: isSelected(item),
        },
      ]"
      @click="handlerSelected(item)"
    >
      <div
        v-show="isSelected(item)"
        class="selected-item">
        <Icon
          name="sui_icon_selected_12px"
          size="16px"
          class="select-icon"
          :is-rotate="cssRight"
          style="color: white" />
      </div>
      <img
        v-show="item.web_logo"
        :src="replaceUrlProtocol(item.web_logo)"
        alt=""
        class="card-img-logo" />
      <span class="card-bin">{{ `${item.card_bin}${item.card_no}` }}</span>
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { computed } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { replaceUrlProtocol } from '../../../utils'

const props = defineProps({
  resultTokenBinList: {
    type: Array,
    default: () => [],
  },
  paymentMethods: {
    type: Array,
    default: () => [],
  },
  tempSelectedObj: {
    type: Object,
    default: () => ({}),
  },
  nowPay: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits(['handlerSelected'])

const { cssRight } = useAppConfigs()?.$envs || {}

const tempSelected = computed(() => props.tempSelectedObj?.[props.nowPay?.code] || {})

const handlerSelected = item => {
  emits('handlerSelected', item)
}

const isSelected = item => {
  return item.id === tempSelected.value?.id
}
</script>

<style lang="less" scoped>
.bin-item {
  border-radius: 10/75rem;
  border: 1/75rem #c9c9c9 solid;
  width: 335 * 2/75rem;
  height: 48 * 2/75rem;
  margin-bottom: 16 * 2/75rem;
  display: flex;
  align-items: center;
  padding-left: 12 * 2/75rem;
  position: relative;
}

.selected-item {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24 * 2/75rem;
  height: 24 * 2/75rem;
  background-color: #333333; /* 背景色 */
  /* 圆角设置 */
  border-bottom-left-radius: 24 * 2/75rem; /* 创建四分之一圆 */
  /* 隐藏其他三个角 */
  overflow: hidden;

  .select-icon {
    position: absolute;
    top: 4/75rem;
    right: 4/75rem;
  }
}

.selected {
  border: 1/75rem #333333 solid;
}
.card-img-logo {
  width: 26.76 * 2/75rem;
  margin-right: 7 * 2/75rem;
}

.card-bin {
  color: black;
  font-size: 18px;
  font-weight: 590;
  word-wrap: break-word;
}
</style>
