<template>
  <SDrawer
    :visible.sync="isShow"
    append-to-body
    class="src-validate-drawer"
    @close="close"
  >
    <!-- 标题 -->
    <template #title>
      <div class="validate-drawer__header">
        <Icon
          name="sui_icon_guarantee_18px"
          size="18px"
          class="validate-drawer__header-icon" />
        <span> {{ languageInfo.BS_KEY_SRC_1015 }}</span>
      </div>
    </template>

    <ValidateContent
      ref="validateContentRef"
      class="validate-component"
      :language-info="languageInfo"
      :c2pInfo="c2pInfo"
      :area-info="areaInfo"
      @close="close"
      @submit-cb="submitCb"
      @select-area-code="selectAreaCode"
      @phone-validate-fn="handlerPhoneBlur"
    />

    <BSrcAreaCodeListDrawer
      :countryCodes="countryCodes"
      :codeId="phoneInfo.id"
      :languageInfo="languageInfo"
      :show="isShowAreaCodeDrawer"
      @select-item="setPhoneCode"
      @close="isShowAreaCodeDrawer = false"
    />
  </SDrawer>
</template>

<script setup lang="ts">
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { ref, watch, computed } from 'vue'
import ValidateContent from './ValidateContent.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { useGetLang } from '../../hooks/useGetLang'
import BSrcAreaCodeListDrawer from '../area-code-list/index.vue'

const { languageInfo } = useGetLang({})

const props = defineProps({
  c2pInfo: {
    type: Object,
    default: () => {},
  },
})

const isShow = ref(false)
const validateContentRef = ref(null)
const isShowAreaCodeDrawer = ref(false)

const isShowOtpValidateDrawer = computed(() => props.c2pInfo?.isShowOtpValidateDrawer)

const phoneInfo = computed(() => validateContentRef.value?.phoneInfo || {})
const countryCodes = computed(() => validateContentRef.value?.countryCodes || [])

const areaInfo = computed(() => ({
  countryCode: validateContentRef.value?.prevStageSelected || '',
  areaCode: phoneInfo.value?.code || '',
}))

const close = () => {
  isShow.value = false
  props.c2pInfo?.closeOtpValidateDrawer?.()
  validateContentRef.value?.reset()
}

const submitCb = () => {}

const selectAreaCode = () => {
  isShowAreaCodeDrawer.value = true
}

const setPhoneCode = (item) => {
  validateContentRef.value?.setPhoneCode(item)
}

const handlerPhoneBlur = (nowParams, cb) => {
  validateContentRef.value?.handlerPhoneBlur(cb)
}

watch(
  () => isShowOtpValidateDrawer.value,
  val => {
    isShow.value = val
    if (val) {
      validateContentRef.value?.handlerFocus()
    }
  },
)
</script>

<style lang="less">
.src-validate-drawer {
  .sui-drawer__container {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .sui-drawer__header {
    border-bottom: unset;
    color: black;
    font-size: 16px;
    font-weight: 510;
    word-wrap: break-word;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    // 超出一行展示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .validate-drawer__header-icon {
    margin-right: 4 * 2/75rem;
    color: #198055 !important;
  }
  .validate-component {
    height: calc(70vh - 44px);
  }
}
</style>
