<template>
  <component
    :is="compTag"
    v-show="active"
    class="animated fadeIn"
    :active="active"
    :duration="300"
  >
    <div class="extra-tip">
      <p
        v-if="showDescription"
        class="content"
      >
        {{ description }}
        <slot name="icon"></slot>
      </p>
    </div>
  </component>
</template>

<script name="PaymentDescription" setup lang="ts">
// import { computed } from 'vue'
// import slideUpDown from '@sheinfe/vue-slide-up-down'

interface PaymentDescriptionProps {
  description: string;
  showDescription: boolean;
  active: boolean;
  useNativeTag?: boolean;
}

const props = withDefaults(defineProps<PaymentDescriptionProps>(), {
  description: '',
  showDescription: false,
  active: false,
  useNativeTag: false,
})

// const compTag = computed(() => props.useNativeTag ? 'div' : slideUpDown)
const compTag = 'div'

</script>

<style lang="less" scoped>
.extra-tip {
  font-size: 12px;
  color: #666;
  display: flex;
  flex-wrap: wrap;
}
</style>
