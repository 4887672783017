<template>
  <SDialog
    v-model:visible="isShow"
    :append-to-body="true"
    :show-close="true"
    class="src-click-to-pay-dialog"
    @close="closeDialog"
  >
    <div class="content">
      <div class="title"> {{ nowLanguageInfo?.BS_KEY_SRC_1001 }} </div>
      <div class="fast-secure-payment-method"> {{ nowLanguageInfo?.BS_KEY_SRC_1002 }}</div>
      <div class="supported-by-pay-method"> {{ nowLanguageInfo?.BS_KEY_SRC_1003 }} </div>

      <div class="card-logo-list">
        <SrcCardList />
      </div>

      <ul class="tips-list">
        <li
          v-for="(tip, index) in tipsList"
          :key="index"
          class="tip-text"> {{ tip }} </li>
      </ul>
    </div>

    <template #footer>
      <SButton
        class="click-to-pay-btn"
        :type="['primary', 'H72PX']"
        :width="'100%'"
        @click="closeDialog">
        {{ nowLanguageInfo?.BS_KEY_SRC_1009 }}
      </SButton>
    </template>
  </SDialog>
</template>

<script setup lang="ts">
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { ref, watch, computed } from 'vue'
import SrcCardList from '../src-card-list/index.vue'
import { useGetLang } from '../../hooks/useGetLang'

const props = defineProps({
  isShowClickToPayDialog: {
    type: Boolean,
    default: false,
  },
  languageInfo: {
    type: Object,
    default: () => {},
  },
})

const { languageInfo: lang } = useGetLang({ isNoGetLang: ('BS_KEY_SRC_1001' in (props.languageInfo || {})) })

const emits = defineEmits(['closeDialog'])

const isShow = ref(false)

const nowLanguageInfo = computed(() => {
  return {
    ...lang.value,
    ...props.languageInfo,
  }
})

const tipsList = computed(() => [
  nowLanguageInfo.value?.BS_KEY_SRC_1004 || '',
  nowLanguageInfo.value?.BS_KEY_SRC_1005 || '',
  nowLanguageInfo.value?.BS_KEY_SRC_1008 || '',
])

const closeDialog = () => {
  isShow.value = false
  emits('closeDialog')
}

watch(
  () => props.isShowClickToPayDialog,
  val => {
    isShow.value = val
  },
)
</script>

<style lang="less">
.src-click-to-pay-dialog {
  .content {
    .title {
      color: black;
      font-size: 16px;
      font-weight: 700;
      word-wrap: break-word;
      text-align: center;
      margin-bottom: 12 * 2/75rem;
      // 超出一行展示省略号
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .fast-secure-payment-method {
      color: black;
      font-size: 14px;
      font-weight: 400;
      word-wrap: break-word;
      margin-bottom: 4 * 2/75rem;
      text-align: center;
    }

    .supported-by-pay-method {
      color: #767676;
      font-size: 12px;
      font-weight: 400;
      word-wrap: break-word;
      margin-bottom: 12 * 2/75rem;
      text-align: center;
    }

    .card-logo-list {
      display: flex;
      justify-content: center;
      margin-bottom: 12 * 2/75rem;
    }
  }

  .tips-list {
    color: #666666;
    font-size: 12px;
    font-weight: 400;
    word-wrap: break-word;
    .tip-text {
      position: relative;
      padding-left: 14 * 2/75rem; // 为圆点留出空间
      margin-bottom: 8 * 2/75rem;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 6 * 2/75rem; // 圆点大小
        height: 6 * 2/75rem; // 圆点大小
        background: #666666; // 圆点颜色
        border-radius: 50%; // 使其成为圆形
      }
    }
  }
}
</style>
