<template>
  <DiscountLabel
    class="bin-discount-label"
    :text="text"
  />
</template>

<script setup>
import DiscountLabel from './DiscountLabel.vue'
// import { computed, ref, watch, toRef } from 'vue'
// import { useTokenBinDiscount } from 'public/src/pages/checkout/page_tpls/token_pay/useTokenBinDiscount.js'
// import { usePrePayStore } from '../../../hooks/usePrePay'

const props = defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  text: {
    type: String,
    default: '',
  },
})

// const cardBinDiscountDesc = ref('')
// const cardBinDiscountDescType = ref('')

// const rootStore = usePrePayStore()

// const displayCardBinDiscountInfo = toRef(rootStore, 'displayCardBinDiscountInfo')

// const cardBinDiscountInfo = computed(() => {
//   return displayCardBinDiscountInfo.value || {}
// })

// const cardBin = computed(() => {
//   return rootStore?.prePayUnPayParams?.cardBin || ''
// })

// const isShowBinRandomDiscountABT = computed(() => {
//   const { BinRandomDiscount = {} } = rootStore?.preFetchInfo?.abtData || {}
//   return BinRandomDiscount?.param?.BinRandomShow === 'Show'
// })

// const isShowCardBinDiscount = computed(() => {
//   if (!isShowBinRandomDiscountABT.value) {
//     return false
//   }

//   const { all_card_bin_discount_map = {} } = cardBinDiscountInfo.value
//   const isHadBin = Object.keys(all_card_bin_discount_map || {}).some(item =>
//     cardBin.value.includes(item),
//   )

//   return isHadBin
// })

// watch(
//   () => cardBinDiscountDesc.value,
//   (val) => {
//     rootStore?.mergeState('prePayUnPayParams', {
//       ...rootStore?.prePayUnPayParams,
//       isHadBinDiscount: isShowCardBinDiscount.value && val,
//       cardBinDiscountDesc: val,
//       isShowCardBinDiscountDescInPriceDetail: isShowCardBinDiscount.value && val && cardBinDiscountDescType.value !== 3,
//     })
//   },
//   {
//     immediate: true,
//     deep: true,
//   },
// )

// watch(
//   () => [cardBin.value, cardBinDiscountInfo.value],
//   () => {
//     // const { getCardBinDiscountDesc, type } = useTokenBinDiscount(
//     //   cardBinDiscountInfo.value,
//     //   cardBin.value,
//     //   props.language,
//     // )
//     // cardBinDiscountDesc.value = getCardBinDiscountDesc()
//     // cardBinDiscountDescType.value = type
//     cardBinDiscountDesc.value = ''
//     cardBinDiscountDescType.value = ''
//   },
//   {
//     immediate: true,
//     deep: true,
//   },
// )
</script>

<style lang="less">
.bin-discount-label {
  margin: 0;
}
</style>
