// import PWA_LANG from './pwa'
// import PC_LANG from './pc'
import pcLangJson from '../../laptop/lang/pc.json'
import pwaLangJson from '../../mobile/lang/pwa.json'
import { type BPayToolkitKeys } from '../../laptop/lang'

const LANGS_MAP = {
  pwa: pwaLangJson.BPayToolkit,
  pc: pcLangJson.BPayToolkit,
}

export default LANGS_MAP

export type LangProjectTypes = keyof typeof LANGS_MAP

export type PwaLangKeys = keyof typeof LANGS_MAP.pwa
export type PcLangKeys = BPayToolkitKeys

export type LangKeys = PwaLangKeys | PcLangKeys

