<template>
  <div class="src-token-header">
    <span class="src-card-list__logo">
      <i class="icon-src"></i>
    </span>
    <span>
      <span
        class="click-to-pay"
        @click="clickToPay"
      > {{ languageInfo.BS_KEY_SRC_1012 }} </span>
      {{ languageInfo.BS_KEY_SRC_1013 }}
      <Icon
        name="sui_icon_doubt_16px_2"
        size="16px"
        class="tip-icon"
        style="color: #959595"
        @click="showTipDialog"
      />
    </span>

    <ClientOnly>
      <BSrcClickToPayDialog
        :is-show-click-to-pay-dialog="isShowClickToPayDialog"
        :language-info="languageInfo"
        @closeDialog="isShowClickToPayDialog = false"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { ClientOnly } from '@sheinfe/vue-client-only'
import BSrcClickToPayDialog from '../src-tip-dialog/ClickToPayDialog.vue'
import { useGetLang } from '../../hooks/useGetLang'

const { languageInfo } = useGetLang({})

const emits = defineEmits(['clickToPay'])

const isShowClickToPayDialog = ref(false)

const showTipDialog = () => {
  isShowClickToPayDialog.value = true
}

const clickToPay = () => {
    emits('clickToPay')
}
</script>

<style lang="less" scoped>
.src-token-header {
  display: inline-block;
  //   align-items: flex-start;
  background: #fff1d5;
  padding: 8 * 2/75rem 12 * 2/75rem;
  color: black;
  font-size: 12px;
  font-weight: 400;
  word-wrap: break-word;
  width: 100%;

  .src-card-list__logo {
    width: 24 * 2/75rem;
    height: 15 * 2/75rem;
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2025/01/23/2d/17376229308eee3716a2f614a65df08370e5efc053.png');
    background-size: 100% 100%;
    display: inline-block;
    margin-right: 4 * 2/75rem;
  }

  .click-to-pay {
    color: #2d68a8;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    word-wrap: break-word;
  }

  .tip-icon {
    color: #959595;
  }
}
</style>
