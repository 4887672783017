import type { DS_ClickToPay } from '../../../types'
import { getLangs } from '@shein-aidc/bs-sdk-libs-manager'
import LangMapData from '../../../mobile-vue2/lang/mobile.json'
import PCLangMapData from '../../../laptop/lang/laptop.json'

// TODO write your apis
export const api1: DS_ClickToPay.APIS_THIS['api1'] = async function (x, y, z) {
  const result = await this.$schttp<Array<number>>({
    url: '/api/*',
    params: { x, y, z },
  })
  return result.data
}

// 获取多语言key
export const getLanguageInfo: DS_ClickToPay.APIS['getLanguageInfo'] = async function ({
  langStage = 'pwa'
} = {}) {
  const langData = langStage === 'pc' ? PCLangMapData : LangMapData
  const result = await getLangs<DS_ClickToPay.LangKeys>(langData['BClickToPay'], this.$schttp)
  return result
}
