<template>
  <div class="src-consent">
    <SrcCardList
      class="c2p-card-logo"
      :card-type="cardType"
      :now-card-logo="nowCardLogo"
    />
    <div class="remember-me">
      <div class="selected-header">
        <SCheckbox
          ref="dcfSuppressedbtnsRef"
          v-model="isCheckedDcfSuppressed"
          class="c2p-checkbox"
          @change="changeDcfSuppressed"
        />
        <div class="selected-header__title">
          <span
            @click="showTipDialog"
            v-html="nowSaveLang"
          ></span>
        </div>
      </div>
      <div class="selected-footer">
        {{ languageInfo?.BS_KEY_SRC_1035 }} {{ languageInfo?.BS_KEY_SRC_1036 }}
      </div>
    </div>
    <div class="dcf-suppressed">
      <div class="selected-header">
        <SCheckbox
          ref="checkedRememberMebtnsRef"
          v-model="isCheckedRememberMe"
          :disabled="!isCheckedDcfSuppressed"
          class="c2p-checkbox"
          @change="changeDcfRememberMe"
        />
        <div class="selected-header__title">
          <span>{{ languageInfo?.BS_KEY_SRC_1037 }}</span>
          <Icon
            name="sui_icon_info_12px_2"
            size="12px"
            class="tip-icon"
            @click="handleShowTipDialog"
          />
        </div>
      </div>
      <div class="selected-footer">
        <span
          class="row terms-and-privacy false"
          v-html="nowRememberTipLang"
        ></span>
      </div>
    </div>

    <ClientOnly>
      <div>
        <TipDialog
          :isShowTipDialog="isShowTipDialog"
          :language-info="languageInfo"
          @closeDialog="isShowTipDialog = false"
        />
        <BSrcClickToPayDialog
          :is-show-click-to-pay-dialog="isShowClickToPayDialog"
          :language-info="languageInfo"
          @closeDialog="isShowClickToPayDialog = false"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue'
import { SCheckbox } from '@shein-aidc/sui-checkbox/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import TipDialog from './TipDialog.vue'
import SrcCardList from '../src-card-list/index.vue'
import BSrcClickToPayDialog from '../src-tip-dialog/ClickToPayDialog.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { debuggerLog } from '../../utils/index'
import { template } from '@shein/common-function'
import { useGetLang } from '../../hooks/useGetLang'

const { languageInfo } = useGetLang({})

const A_STYLE = `color: #2D68A8;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    word-wrap: break-word;`

const props = defineProps({
  cardType: {
    type: String,
    default: '',
  },
  nowCardLogo: {
    type: String,
    default: '',
  },
  initC2pSrcContentInfo: {
    type: Object,
    default: () => {},
  },
})

const emits = defineEmits(['handlerClick'])

const isCheckedRememberMe = ref(props?.initC2pSrcContentInfo?.initCheckedRememberMe || false)
const isCheckedDcfSuppressed = ref(props?.initC2pSrcContentInfo?.initCheckedDcfSuppressed || false)
const isShowTipDialog = ref(false)

const isShowClickToPayDialog = ref(false)

const dcfSuppressedbtnsRef = ref(null)
const checkedRememberMebtnsRef = ref(null)

const urlsList = computed(() => [
    {
      privacyPolicyUrl: 'https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html?locale=en',
      termsUrl: 'https://www.mastercard.com/global/click-to-pay/country-listing/terms.html?locale=en',
      type: 'mastercard',
      saveLang: languageInfo?.value?.BS_KEY_SRC_1058 || '',
      rememberTipLang: languageInfo?.value?.BS_KEY_SRC_1062 || '',
    },
    {
      privacyPolicyUrl: 'https://www.visa.com.hk/en_HK/legal/global-privacy-notice.html',
      termsUrl: 'https://www.visa.com.hk/en_HK/legal/visa-checkout/terms-of-service.html',
      type: 'visa',
      saveLang: languageInfo?.value?.BS_KEY_SRC_1059 || '',
      rememberTipLang: languageInfo?.value?.BS_KEY_SRC_1063 || '',
    },
    {
      privacyPolicyUrl: 'https://www.americanexpress.com/us/company/privacy-center/online-privacy-disclosures/',
      termsUrl: 'https://www.americanexpress.com/en-us/services/click-to-pay/terms-and-conditions/',
      type: 'amex',
      saveLang: languageInfo?.value?.BS_KEY_SRC_1061 || '',
      rememberTipLang: languageInfo?.value?.BS_KEY_SRC_1065 || '',
    },
    {
      privacyPolicyUrl: 'https://www.discoverglobalnetwork.com/solutions/enable-payments/secure-remote-commerce/privacy/',
      termsUrl: 'https://www.discoverglobalnetwork.com/solutions/enable-payments/secure-remote-commerce/terms-of-use/',
      type: 'discover',
      saveLang: languageInfo?.value?.BS_KEY_SRC_1060 || '',
      rememberTipLang: languageInfo?.value?.BS_KEY_SRC_1064 || '',
    },
  ])

const nowUseUrl = computed(() => {
  debuggerLog('props.cardType >>>', props.cardType)
  return urlsList.value.find(item => item.type === props.cardType)
})

const privacyPolicyUrl = computed(() => {
  return nowUseUrl.value?.privacyPolicyUrl || ''
})

const termsUrl = computed(() => {
  return nowUseUrl.value?.termsUrl || ''
})

const nowSaveLang = computed(() => {
  debuggerLog('nowUseUrl.value >>>>', nowUseUrl.value)
  return template(`<span>
              <a
                href="#"
                class="href-block"
                style="${A_STYLE}"
              >${languageInfo?.value?.BS_KEY_SRC_1033}</a>
            </span>`, nowUseUrl.value?.saveLang || '')
})

const nowRememberTipLang = computed(() => {
  return template(`<span><a
            href="${termsUrl.value}"
            class="href-block"
            style="${A_STYLE}">${languageInfo?.value?.BS_KEY_SRC_1040}</a>
          </span>`, `<span>
            <a
              href="${privacyPolicyUrl.value}"
              class="href-block"
              style="${A_STYLE}">${languageInfo?.value?.BS_KEY_SRC_1042}</a></span>`, nowUseUrl.value?.rememberTipLang || '')
})

const handleShowTipDialog = () => {
  isShowTipDialog.value = true
}

const changeDcfSuppressed = () => {
  debuggerLog('changeDcfSuppressed >>>>', isCheckedDcfSuppressed.value)
  if (!isCheckedDcfSuppressed.value) {
    isCheckedRememberMe.value = false
  }
  changeData()
}

const changeDcfRememberMe = () => {
  debuggerLog('changeDcfRememberMe >>>>', isCheckedRememberMe.value)
  if (!isCheckedDcfSuppressed.value) return
  changeData()
}

const changeData = () => {
  emits('handlerClick', {
    isCheckedRememberMe: isCheckedRememberMe.value,
    isCheckedDcfSuppressed: isCheckedDcfSuppressed.value,
  })
}

const showTipDialog = (event) => {
  debuggerLog('showTipDialog >>>>', event?.target, event?.target?.matches('a.href-block'))
  if (event?.target?.matches('a.href-block')) {
    isShowClickToPayDialog.value = true
  }
}

onMounted(() => {
  nextTick(() => {
    changeData()
  })
})
</script>

<style lang="less" scoped>
.src-consent {
  padding: 12 * 2/75rem;
  background-color: #ffffff;

  .c2p-card-logo {
    margin-bottom: 8 * 2/75rem;
  }

  .remember-me {
    margin-bottom: 8 * 2/75rem;
  }

  .selected-header {
    display: flex;
    align-items: flex-start;
    color: #141413;
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
    margin-bottom: 4*2/75rem;

    .S-checkbox {
        margin-right: 6*2/75rem !important;
    }
  }

  .selected-footer {
    color: #767676;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    word-wrap: break-word;
    padding-left: 26*2/75rem;
  }

  .tip-icon {
    margin-left: 4*2/75rem;
  }
}
</style>
