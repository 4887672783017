// useCountdown.ts
import { ref, onUnmounted } from 'vue'

interface CountdownOptions {
  seconds: number // 倒计时秒数
  onFinish?: () => void // 倒计时结束回调
}

export function useCountdown(options: CountdownOptions) {
  const { seconds, onFinish } = options
  const remainingTime = ref(seconds)
  const isRunning = ref(false)
  let timer: NodeJS.Timer | null = null

  // 开始倒计时
  const start = () => {
    if (isRunning.value) return

    isRunning.value = true
    remainingTime.value = seconds

    timer = setInterval(() => {
      if (remainingTime.value > 0) {
        remainingTime.value--
      } else {
        stop()
        onFinish?.()
      }
    }, 1000)
  }

  // 停止倒计时
  const stop = () => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    isRunning.value = false
  }

  // 重置倒计时
  const resetTime = () => {
    stop()
    remainingTime.value = seconds
  }

  // 组件卸载时清理定时器
  onUnmounted(() => {
    stop()
  })

  return {
    remainingTime,
    isRunning,
    start,
    stop,
    resetTime,
  }
}
