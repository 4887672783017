<template>
  <div
    v-expose="{
      id: 'expose_scenesabt.comp_pay-toolkit',
      data: {
        scenes: 'bnpl_visual_component',
        // 0 不开启 1 开启 根据后端传参【动态化展示开关】确认是否开启
        if_show_bnpl_component: options.abt_open || '-',
      }
    }"
    class="payment-bnpl-bar"
    :class="{
      'meet-condition': !!bnplBarList.length,
    }"
    :style="{
      '--bnpl_color': options.color
    }"
  >
    <div
      v-if="bnplBarList.length"
      v-expose="{
        id: 'expose_bnpl_component.comp_pay-toolkit',
        data: {
          payment_method: paymentCode,
          installments_number: options.count,
        }
      }"
      class="wrap"
    >
      <div
        v-for="(item, idx) of bnplBarList"
        :key="`item-${idx}`"
        class="item"
        :class="{ 'item-last': item.isLast, 'item-start': item.isStart }"
        :style="{
          flex: `1 0 ${100 / bnplBarList.length}%`,
        }"
      >
        <SAdapterText
          class="item__amount"
          min-size="10"
          :text="item.price"
        />
        <div class="item__txt">
          {{ item.date_desc }}
        </div>
        <div
          class="item__line"
          :style="{
            backgroundColor: item.color
          }"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script name="ItemInfoBnplBar" setup lang="ts">
import { computed, inject } from 'vue'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'
import type { AS_PayToolkit } from '../../../../types'
const { vExpose } = (inject('analysisInstance', null) || {}) as AS_PayToolkit.AnalysisInstance

interface ItemInfoBnplBarProps {
  scene: string;
  paymentCode: string;
  options: {
    cycle: {
        progress: string;
        price: string;
        date_desc: string;
    }[];
    color: string;
    count: string;
    abt_open: string;
    has_nj_data: string;
    is_show: string;
    is_bottom_bar_style: string;
    undertone_color: string;
  }
}

const props = withDefaults(defineProps<ItemInfoBnplBarProps>(), {
  scene: 'checkout',
  paymentCode: '',
  options: () => ({
    cycle: [],
    color: '',
    count: '',
    abt_open: '',
    has_nj_data: '',
    is_show: '',
    is_bottom_bar_style: '',
    undertone_color: '',
  }),
})

const bnplBarList = computed(() => {
  if (Number(props.options?.is_show) !== 1) return []
  return props.options?.cycle?.map?.((item, idx) => {
    const isStart = idx === 0
    const isLast = idx === props.options.cycle.length - 1
    const color = isStart ? props.options.color : props.options.undertone_color
    return {
      price: item.price,
      date_desc: item.date_desc,
      isLast,
      isStart,
      color,
    }
  }) || []
})

</script>

<style scoped lang="less">
.payment-bnpl-bar {
  position: relative;
  &.meet-condition {
    border: 1/37.5rem solid #d9d9d9;
    border-radius: 4/37.5rem;
    margin-top: 10/37.5rem;
    &::after {
      content: ' ';
      position: absolute;
      top: -5.5/37.5rem;
      left: 35/37.5rem;
      width: 10/37.5rem;
      height: 10/37.5rem;
      border: 1/37.5rem solid #d9d9d9;
      border-top-left-radius: 1/37.5rem;
      border-bottom: none;
      border-right: none;
      background-color: #fff;
      transform: rotate(45deg);
    }
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 7/37.5rem 10/37.5rem 10/37.5rem;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .item {
    min-width: 55/37.5rem;
    line-height: 1;
    padding: 0 2/37.5rem;
    .item__line {
      width: 100%;
      height: 6/37.5rem;
      border-radius: 1px;
      margin-top: 6/37.5rem;
    }
    .item__amount {
      color: var(---sui_color_gray_dark1, #222);
      font-size: 14/37.5rem;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
    }
    .item__txt {
      color: var(---sui_color_gray_dark3, #767676);
      font-size: 9/37.5rem;
      font-weight: 400;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'SF Pro';
      margin-top: 2/37.5rem;
    }
  }
  .item-start {
    padding-left: 0;
    .item__amount {
      font-weight: 700;
      color: var(---sui_color_brand, #000);
    }
    .item__txt {
      color: var(---sui_color_brand, #000);
    }
  }
  .item-last {
    padding-right: 0;
  }
}
</style>
