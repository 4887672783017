<template>
  <SDrawer
    ref="phoneDrawerRef"
    :visible="show"
    :size="'80rem'"
    :append-to-body="true"
    class="c-phone-drawer"
    @close-from-icon="close"
  >
    <template #title>
      {{ languageInfo?.BS_KEY_SRC_1055 }}
    </template>
    <div class="c-phone-drawer__body">
      <ul
        ref="containerRef"
        class="newpop-select-area"
      >
        <!-- 区号列表 -->
        <li
          v-for="(item, index) in countryCodes"
          :id="`item-${index}`"
          :key="index"
          :class="[{ active: item.id == codeId }]"
          @click="handleItemClick(item, index)"
        >
          <span class="phone-country">{{
            `${item.country}${item.type == 'title' ? '' : ` (+${item.countrynum})`}`
          }}</span>
          <i v-if="item.id == codeId" class="suiiconfont sui_icon_selected_16px"></i>
        </li>
      </ul>
      <!-- 右侧字母列表 -->
      <div
        class="c-upper-silder"
      >
        <ul ref="capitalListRef" class="capital-list">
          <li
            v-for="(item, index) in formateCapitalList"
            :key="index"
            :ref="'j-shortcut-' + index"
            :data-index="index"
            @click="scrollToPosition(item)"
          >
            <span :data-index="index" class="shortcut-item">{{ item }}</span>
          </li>
        </ul>
      </div>
    </div>
  </SDrawer>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import type { PropType } from 'vue'
import { useScroll } from '../../hooks/useScroll'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'

interface CountryCode {
  value: string
  [key: string]: any
}

// Props 定义
const props = defineProps({
  show: { type: Boolean, default: false },
  codeId: { type: String, default: '' },
  countryCodes: { type: Array as PropType<CountryCode[]>, default: () => [] },
  title: { type: String, default: '' },
  phoneAreaCodePopop: { type: String, default: '' },
  languageInfo: { type: Object, default: () => {} },
})

// Emits 定义
const emit = defineEmits(['close', 'select-item'])

const { containerRef, scrollToElement } = useScroll()

// Refs
const phoneDrawerRef = ref()

const formateCapitalList = computed(() => {
    const list: string[] = [];
    (props.countryCodes || []).forEach(item => {
        if (!list.includes(item?.country?.charAt(0))) {
        list.push(item?.country?.charAt(0))
      }
    })
  return list
})

const scrollToActive = (index) => {
  scrollToElement(`#item-${index}`, {
    behavior: 'smooth',
    block: 'center',
  })
}

const scrollToPosition = (item) => {
    const index = (props.countryCodes || []).findIndex(
        country => country?.country?.charAt(0) === item,
    )
    scrollToActive(index)
}

const close = () => {
  emit('close')
}

const handleItemClick = (item: CountryCode) => {
  emit('select-item', item)
  close()
}
</script>

<style lang="less" scoped>
.font-dpr(@font-size) {
  font-size: @font-size * 0.5;
}

.right(@size) {
  right: @size;
}

.padding-l(@size) {
  padding-left: @size;
}

.c-phone-drawer {
  .newpop-select-area {
    flex-grow: 1;
    overflow: auto;
    li {
      padding: 0.32rem 0.8rem 0.32rem 0.32rem;
      font-size: 14px;
      color: #222;
      display: flex;
      align-items: center;
      justify-content: space-between;
      [class*='suiiconfont'] {
        font-size: 20px;
        color: @sui_color_main;
        padding-right: 0.533rem;
      }
    }
    li.active {
      font-weight: bold;
      color: @sui_color_main;
    }
    li.unselectable {
      cursor: auto;
      padding: 0.32rem 0.8rem 0.1rem 0.32rem;
      font-weight: bold;
      color: @sui_color_main;
    }
  }

  .shortcut-selected {
    position: fixed;
    .font-dpr(40px);
    color: #fff;
    .right(0.9rem);
    width: 0.90666rem;
    height: 0.64rem;
    line-height: 0.64rem;
    .name {
      position: relative;
      .padding-l(0.16rem);
    }
    img {
      position: absolute;
      width: 100%;
    }
  }
}
.c-upper-silder {
  position: absolute;
  top: 54%;
  .right(0);
  z-index: 2999; /* stylelint-disable-line declaration-property-value-blacklist */
  transform: translate3d(0, -50%, 2999px);
  overflow-y: hidden;
  text-align: center;
  font-size: 11px;
}
.capital-list {
    max-height: 437*2/75rem;
    overflow-y: scroll;
  li {
    width: 0.8rem;
    height: 0.38rem;
    margin-bottom: 5px;
    font-size: 12px;
    color: @sui_color_gray_dark2;
    text-align: center;
    .shortcut-item {
      display: inline-block;
      &.selected {
        background: #222;
        border-radius: 50%;
        color: #fff;
      }
    }
  }
}
// 解决Safari浏览器兼容性问题
.phone-country:not(:empty):after {
  content: 'i';
  visibility: hidden;
  font-size: 0;
}
.phone-country:not(:empty):before {
  content: 'i';
  visibility: hidden;
  font-size: 0;
}
</style>
