<template>
  <SDrawer
    :visible.sync="isShow"
    append-to-body
    class="src-opt-input-drawer"
    size="80%"
    @close="close"
  >
    <!-- 标题 -->
    <template #title>
      <div class="validate-drawer__header">
        <Icon
          name="sui_icon_guarantee_18px"
          size="18px"
          class="validate-drawer__header-icon" />
        <span class="validate-drawer__header-text">
          {{ title }}
        </span>
      </div>
    </template>

    <OtpInputContent
      ref="otpInputContentRef"
      :language-info="languageInfo"
      :c2pInfo="c2pInfo"
      :initiateValidationInfo="initiateValidationInfo"
      :supportedValidationChannelsItem="supportedValidationChannelsItem"
      :isUseEmailValidation="isUseEmailValidation"
      :defaultRemember="defaultRemember"
      @handleInitiateValidation="handleInitiateValidation"
      @close="close"
      @not-you="handlerNoYou"
      @enter-card-manually="handlerEnterCardManually"
      @validate-result="validateResult"
      @click-tips="handlerShowTipDialog"
    />

    <SLoading
      :show="fetchLoading"
      type="curpage"
    />

    <RememberTipDialog
      :isShowTipDialog="isShowTipDialog"
      :language-info="languageInfo"
      @closeDialog="isShowTipDialog = false"
    />
  </SDrawer>
</template>

<script setup lang="ts">
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { ref, watch, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import OtpInputContent from './OtpInputContent.vue'
import { useGetLang } from '../../hooks/useGetLang'
import { debuggerLog } from '../../utils/index'
import RememberTipDialog from '../src-tip-dialog/RememberTipDialog.vue'

const { languageInfo } = useGetLang({})

type handleInitiateValidationParams = {
  requestedValidationChannelId?: string
  cb?: () => void
}

const props = defineProps({
  isShowOtpInputContent: {
    type: Boolean,
    default: false,
  },
  c2pInfo: {
    type: Object,
    default: () => {},
  },
})

const isShow = ref(false)
const initiateValidationInfo = ref({})
const fetchLoading = ref(false)
const otpInputContentRef = ref(null)
const isShowTipDialog = ref(false)

const emits = defineEmits(['handleStartFrontBindingCard'])

const supportedValidationChannelsItem = computed(() => {
    return (initiateValidationInfo.value?.supportedValidationChannels || []).find(
      item => item?.maskedValidationChannel === initiateValidationInfo.value?.maskedValidationChannel,
    )
})

const isUseEmailValidation = computed(() => {
    return supportedValidationChannelsItem.value?.validationChannelId !== 'SMS'
})

const title = computed(() => {
  return isUseEmailValidation.value
    ? languageInfo.value?.BS_KEY_SRC_1024
    : languageInfo.value?.BS_KEY_SRC_1023
})

const defaultRemember = computed(() => props.c2pInfo?.isShowCheckedRememberMeAbt)

const close = () => {
  isShow.value = false
  props.c2pInfo?.closeC2pAlertDrawer?.()
  otpInputContentRef.value?.reset()
}

const handleInitiateValidation = async ({ requestedValidationChannelId, cb }: handleInitiateValidationParams = {}) => {
  fetchLoading.value = true

  try {
    const info = await props.c2pInfo?.initiateValidation?.(requestedValidationChannelId && { requestedValidationChannelId })
    debuggerLog('validationInfo >>>>>', info)
    if (Object.keys(info || {}).length > 0) {
      cb && cb()
    } else {
      SToast({ content: languageInfo.value?.BS_KEY_SRC_1051 })
    }
    initiateValidationInfo.value = info
  } catch (e: any) {
    debuggerLog('initiateValidation error====', e?.reason, e?.message, e?.details)
    // sdkErrorDetail.value = e?.details || {}
  } finally {
    fetchLoading.value = false
  }
}

const onOpen = async (cb, payload) => {
    try {
      if (payload?.scene === 'callIdLookup') {
        await props.c2pInfo?.idLookup?.(payload?.params).catch(() => {
          console.error('idLookup error')
        })
      }
      handleInitiateValidation({ cb })
    } catch (e: any) {
      debuggerLog('onOpen error====', e)
    } finally {
      props.c2pInfo?.changeCheckoutLoading({ config: { loading: true }, setLoading: false })
    }

}

const handlerNoYou = () => {
  props?.c2pInfo?.handleNotYou?.(() => {
    props.c2pInfo?.closeC2pAlertDrawer?.()
  })
}

const handlerEnterCardManually = () => {
  const paymentCode = 'routepay-card'
  emits('handleStartFrontBindingCard', paymentCode)
}

const validateResult = (info) => {
  if (info?.status != 'success') {
    props.c2pInfo?.handlerValidateCb?.({
      res: info,
      cb: () => {
        // SToast({ content: languageInfo.value?.BS_KEY_SRC_1051 })
      },
      close: () => {
        close()
        // SToast({ content: languageInfo.value?.BS_KEY_SRC_1051 })
      },
    })
  } else {
    SToast({ content: languageInfo.value?.BS_KEY_SRC_1045 })
    if (info?.tokenList) {
      props.c2pInfo?.setC2pTokenList?.(info?.tokenList || [])
    }
  }
}

const handlerShowTipDialog = () => {
  isShowTipDialog.value = true
}

watch(
  () => props.isShowOtpInputContent,
  val => {
    isShow.value = val
    if (val) {
      otpInputContentRef.value?.init()
    }
  },
)

defineExpose({
  onOpen,
})
</script>

<style lang="less">
.src-opt-input-drawer {
  .sui-drawer__container {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .sui-drawer__header {
    border-bottom: unset;
    color: black;
    font-size: 16px;
    font-weight: 510;
    word-wrap: break-word;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .validate-drawer__header-icon {
    margin-right: 4 * 2/75rem;
    color: #198055 !important;
  }

  .validate-drawer__header-text {
    // 超出一行展示省略号
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .otp-input-content {
    justify-content: unset;
  }
}
</style>
