<template>
  <div class="validate-content">
    <div class="validate-content__header">
      <div
        v-for="item in selectedList"
        :key="item.value"
        class="validate-item"
        :class="{ 'selected-item': selectedItem.value === item.value }"
        @click="selectedValidateItem(item)"
      >
        <span class="validate-title">
          {{ item.name }}
        </span>
      </div>
    </div>

    <div class="validate-block">
      <div class="validate-input">
        <SInput
          v-if="isShowEmail"
          ref="emailInputRef"
          v-model="emailInfo.email"
          :label="languageInfo.BS_KEY_SRC_1017"
          :placeholder="languageInfo.BS_KEY_SRC_1018"
          @blur="nowValidateFn(null)"
          @focus="EmailInputFocus"
        />
        <SInput
          v-else
          ref="phoneInputRef"
          v-model="phoneInfo.phone"
          :label="languageInfo.BS_KEY_SRC_1021"
          :placeholder="languageInfo.BS_KEY_SRC_1022"
          @prev-stage-changed="prevStageChange"
          @blur="nowValidateFn(null)"
          @focus="phoneInputFocus"
        >
          <template #prepend>
            <div
              class="phone-prepend"
              @click.prevent.stop="emits('select-area-code')">
              <span class="phone-area-code">{{ areaInfoText }}</span>
              <Icon
                name="sui_icon_more_s_triangle_down_12px"
                size="12px"
              />
            </div>
          </template>
        </SInput>
      </div>
      <p
        v-if="nowErrTip"
        class="err-tip">
        {{ nowErrTip }}
      </p>

      <div class="validate__tip"> {{ languageInfo.BS_KEY_SRC_1019 }} </div>
    </div>

    <div class="validate-footer">
      <SButton
        :type="['primary']"
        width="100%"
        :disabled="btnDisabled"
        @click="submit"
      >
        {{ languageInfo.BS_KEY_SRC_1009 }}
      </SButton>
    </div>

    <SLoading
      :show="fetchLoading"
      type="curpage"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, nextTick } from 'vue'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SInput } from '@shein-aidc/sui-input/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import { useGetPhoneEmail } from '../../hooks/useGetPhoneEmail'

const props = defineProps({
  languageInfo: {
    type: Object,
    default: () => ({}),
  },
  c2pInfo: {
    type: Object,
    default: () => {},
  },
  areaInfo: {
    type: Object,
    default: () => ({
      countryCode: '',
      areaCode: '',
    }),
  },
})

const emits = defineEmits([
  'close',
  'submit-cb',
  'select-area-code',
  'phone-validate-fn',
])

const {
  emailInfo,
  phoneInfo,
  phoneInputFocus,
  EmailInputFocus,
  prevStageSelected,
  prevStageChange,
  reset,
  handlerEmailBlur,
  handlerPhoneBlur,
  countryCodes,
  setPhoneCode,
} =
  useGetPhoneEmail({
    countryId: props.c2pInfo?.countryId,
    props,
  })

const selectedItem = ref({
  value: 'email',
})

const fetchLoading = ref(false)
const emailInputRef = ref(null)
const phoneInputRef = ref(null)

const areaInfoText = computed(() => {
  return `${props.areaInfo.countryCode || ''} +${props.areaInfo?.areaCode}`
})

const selectedList = computed(() => [
  {
    name: props.languageInfo?.BS_KEY_SRC_1016,
    value: 'email',
  },
  {
    name: props.languageInfo?.BS_KEY_SRC_1020,
    value: 'mobile',
  },
])

const isShowEmail = computed(() => selectedItem.value.value === 'email')

const nativeParams = computed(() => {
  if (isShowEmail.value) {
    return {
      email: emailInfo.email,
    }
  }

  return {
    phone: phoneInfo.phone,
    area_code: phoneInfo.code,
  }
})

const nowErrTip = computed(() => {
  if (isShowEmail.value) {
    return emailInfo.errTip
  }

  return phoneInfo.errTip
})

const btnDisabled = computed(() => {
  if (isShowEmail.value) {
    return !emailInfo.email || emailInfo.errTip?.length !== 0
  }

  return !phoneInfo.phone || phoneInfo.errTip?.length !== 0
})

const nowHandlerPhoneBlur = (cb) => {
  const nowParams = {
      alias_type: 2,
      area_code: phoneInfo.code,
      alias: phoneInfo.phone,
      area_abbr: prevStageSelected.value,
  }
  if (!nowParams.area_code || !nowParams.alias) {
    phoneInfo.errTip = props?.languageInfo?.BS_KEY_SRC_1048 || ''
    cb?.()
    return
  }
  emits('phone-validate-fn', nowParams, (res) => {
    console.log('res >>>', res)
    cb?.()
    if (res?.code !== 0) {
      phoneInfo.errTip = props?.languageInfo?.BS_KEY_SRC_1048 || res?.msg || ''
    }
  })
  // handlerPhoneBlur()
}

const nowValidateFn = (cb) => {
  if (isShowEmail.value) {
    handlerEmailBlur()
    cb?.()
  } else {
    nowHandlerPhoneBlur(cb)
  }
}

const selectedValidateItem = item => {
  selectedItem.value = item
  emailInfo.errTip = ''
  phoneInfo.errTip = ''
}

const submitCb = ({ consumerPresent }) => {
  if (consumerPresent) {
    props.c2pInfo?.handleStartAccessYourCards?.()
    emits('close')
  }
  emits('submit-cb', { consumerPresent })
}

const submit = () => {
  if (btnDisabled.value) return
  fetchLoading.value = true
  nowValidateFn(async () => {
    try {
      if (btnDisabled.value) {
        fetchLoading.value = false
        return
      }
      const idLookupRes = await props.c2pInfo?.idLookup?.(nativeParams.value)
      const isSuccess = idLookupRes?.consumerPresent == '1'

      submitCb({ consumerPresent: isSuccess })
      console.log('idLookupRes >>>>>', nativeParams.value, idLookupRes, isSuccess)
    } catch (error) {
      console.error('submit error', nativeParams.value, error)
      submitCb({ consumerPresent: false })
    } finally {
      fetchLoading.value = false
    }
  })
}

const handlerFocus = () => {
  nextTick(() => {
    if (isShowEmail.value) {
      emailInputRef.value?.triggerFocus?.()
    } else {
      phoneInputRef.value?.triggerFocus?.()
    }
  })
}

const triggerBlur = () => {
  if (isShowEmail.value) {
    emailInputRef.value?.triggerBlur?.()
  } else {
    phoneInputRef.value?.triggerBlur?.()
  }
}

watch(() => props.areaInfo, () => {
  phoneInfo.code = props.areaInfo?.areaCode || ''
})

defineExpose({
  reset,
  handlerFocus,
  triggerBlur,
  countryCodes,
  phoneInfo,
  setPhoneCode,
  handlerPhoneBlur,
  prevStageSelected,
})
</script>

<style lang="less" scoped>
.validate-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .validate-content__header {
    border-bottom: 0.5px #e5e5e5 solid;
    display: flex;
    align-items: center;
    padding: 13 * 2/75rem 0 8 * 2/75rem 0;
  }
  .validate-item {
    flex: 1;
    text-align: center;
    color: #767676;
    font-size: 14px;
    font-weight: 700;
    word-wrap: break-word;
  }
  .selected-item {
    color: black;
    .validate-title {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -3 * 2/75rem; // 横线距离底部
        left: 0;
        width: 100%; // 横线宽度
        height: 3px; // 横线高度
        background-color: #000; // 横线颜色
      }
    }
  }

  .validate-block {
    padding: 8 * 2/75rem 12 * 2/75rem 0;
    flex: 1;
    .phone-prepend {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
    }
    .phone-area-code {
      display: inline-block;
      padding-right: 3px;
    }
  }

  .validate-input {
    border: 1px #e5e5e5 solid;
  }

  .validate__tip {
    color: #767676;
    font-size: 12px;
    font-weight: 400;
    word-wrap: break-word;
    margin-top: 4 * 2/75rem;
  }

  .validate-footer {
    padding: 12 * 2/75rem;
  }
  .sui-input::after {
    border-top: unset;
  }

  .sui-input__left {
    padding: 10 * 2/75rem 12 * 2/75rem;
  }

  .err-tip {
		color: #BF4123;
		margin: 0 0.32rem;
		margin-top: 0.10666rem;
		text-align: left;
	}
}
</style>
