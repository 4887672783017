<template>
  <div
    class="code-number"
    :class="codePopCodeTip.txt ? 'error' : ''">
    <p>
      <span :class="{ active: inputCode.length == 0 && inputCodeFocus }">{{ inputCode.slice(0, 1) }}</span>
      <span :class="{ active: inputCode.length == 1 && inputCodeFocus }">{{ inputCode.slice(1, 2) }}</span>
      <span :class="{ active: inputCode.length == 2 && inputCodeFocus }">{{ inputCode.slice(2, 3) }}</span>
      <span :class="{ active: inputCode.length == 3 && inputCodeFocus }">{{ inputCode.slice(3, 4) }}</span>
      <span :class="{ active: inputCode.length == 4 && inputCodeFocus }">{{ inputCode.slice(4, 5) }}</span>
      <span :class="{ active: lastActiveIndex }">{{ inputCode.slice(5, 6) }}</span>
      <input
        v-model.trim="inputCode"
        type="text"
        unselectable="on"
        maxlength="6"
        @click="handleInputCodeClick"
        @input="handleInputCodeInput"
        @blur="handlePhoneCheckInputParams"
      />
    </p>
    <p
      class="error-tip"
      tabindex="0">
      {{ codePopCodeTip.txt }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, nextTick } from 'vue'

const codePopCodeTip = ref({
  show: false,
  txt: '',
})
const inputCode = ref('')
const inputCodeFocus = ref(false)

const lastActiveIndex = computed(() => {
  return inputCode.value?.length >= 5 && inputCodeFocus.value
})

const emits = defineEmits(['submit'])

const showInputTips = (msg, show = true) => {
  codePopCodeTip.value.show = show
  codePopCodeTip.value.txt = msg
}

const handleInputCodeClick = () => {
  inputCodeFocus.value = true
  codePopCodeTip.value.show = false
  codePopCodeTip.value.txt = ''
}

// In Vue.js, when you modify a v-model bound value during an input event,
// it can cause the input field to lose its cursor position due to re-rendering, especially on iOS devices.
const handleInputCodeInput = (event) => {
  inputCodeFocus.value = true
  showInputTips('', false)
  let v = event?.target?.value?.replace(/\D/g, '')

  if (v?.length > 6) {
    v = v.slice(0, 6)
  }

  if (v !== inputCode.value) {
    nextTick(() => {
      inputCode.value = v
    })
  }

  if (v?.length === 6) {
    emits('submit', { verificationCode: v })
  }
}

const handlePhoneCheckInputParams = () => {
  inputCodeFocus.value = false
  if (!/\d{6}/.test(inputCode.value)) {
    showInputTips('Error. Please try again later.')
    return false
  }
  return true
}

const resetData = () => {
  inputCode.value = ''
  inputCodeFocus.value = false
  codePopCodeTip.value.txt = ''
}

const setErrInfo = ({
  errTip = '',
}) => {
  codePopCodeTip.value.txt = errTip
  codePopCodeTip.value.show = true
}

defineExpose({
  resetData,
  setErrInfo,
})
</script>

<style lang="less" scoped>
.code-number {
  @keyframes code-number-pop-flash {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  p {
    position: relative;
    display: flex;
    justify-content: center;
  }
  span {
    width: 1.06rem;
    height: 1.28rem;
    line-height: 1.28rem;
    text-align: center;
    border: 1px solid #e5e5e6;
    font-weight: bold;
    font-size: 18px;
    // background: #fafafa;
    color: #222;
    position: relative;
    margin-left: 0.427rem;
    /* rw:begin */
    font-family: 'Adieu';
    border-radius: 2px;
    /* rw:end */
  }
  span:first-of-type {
    margin-left: 0;
  }
  .active {
    border-color: #222;
    &::after {
      content: '';
      display: inline-block;
      height: 0.53rem;
      width: 1px;
      background: #222;
      position: relative;
      top: 0.05rem;
      margin: 0 0.05rem;
      animation: code-number-pop-flash 1s linear infinite;
    }
  }
  input {
    width: 1000%;
    height: 100%;
    position: absolute;
    top: 0;
    left: -100%;
    right: 0;
    bottom: 0;
    border: 0;
    background: transparent;
    outline: none;
    color: transparent;
    caret-color: transparent;
    opacity: 0;
  }
  .error-tip {
    margin: 16 * 2/75rem 0 0 2 * 2/75rem;
    display: block;
    color: #bf4123;
    font-size: 12px;
    font-weight: 400;
    word-wrap: break-word;
    border-bottom: 0;
    text-align: center;
  }
}

.error {
  span {
    border: 1px #BF4123 solid;
  }
}
</style>
