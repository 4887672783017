import * as standard from './sources/standard'
import { organizeDataSource, createFsDataRunnerBySource } from '@shein-aidc/utils-data-source-toolkit'
import type { DS_ClickToPay } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_ClickToPay.SourceParams): DS_ClickToPay.APIS {
  return organizeDataSource<DS_ClickToPay.APIS, DS_ClickToPay.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(sourceParams: DS_ClickToPay.FS_SourceParams) {
  const { getLanguageInfo } = getSource(sourceParams)
  return createFsDataRunnerBySource<DS_ClickToPay.FS_APIS, DS_ClickToPay.FS_DATA>({
    getLanguageInfo,
  })
}
