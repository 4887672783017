<template>
  <SDialog
    v-model:visible="visibleTips"
    class="front-binding-cpf__pop"
    :show-close="true"
    @close="handleClose"
  >
    <div class="tips">{{ config.message }}</div>
    <div class="img-con">
      <img
        :src="replaceUrlProtocol(config.imgSrc)"
        alt=""
        @click="handleFullScreen(true)"
      />
    </div>
    <SImageBox
      :visible="fullscreen"
      class="page__rpcCard_pop-full"
      :append-to-body="true"
    >
      <img
        :src="replaceUrlProtocol(info.imgSrc)"
        alt=""
        @click="handleFullScreen(false)"
      />
    </SImageBox>
  </SDialog>
</template>

<script setup>
import { SImageBox } from '@shein-aidc/sui-image-box/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { ref, computed } from 'vue'
import { replaceUrlProtocol } from '../../../../utils'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  config: {
    type: Object,
    default: () => ({
      message: '',
      imgSrc: '',
    }),
  },
})

const emit = defineEmits(['close', 'update:visible'])

const fullscreen = ref(false)

const visibleTips = computed({
  get: () => props.visible,
  set: (val) => {
    emit('update:visible', val)
  },
})

const handleClose = () => {
  emit('close')
}

const handleFullScreen = (v) => {
  fullscreen.value = v
}

</script>

<style lang="less">
.front-binding-cpf__pop {
  text-align: center;
  .tips {
    line-height: 1.2;
    margin-bottom: 12px;
  }
  img {
    width: 100%;
    height: auto;
    cursor: zoom-in;
    margin-bottom: 14px;
  }
}
</style>
