<template>
  <div>
    <SDrawer
      id="front-binding-drawer__wrap"
      v-model:visible="visiblePrePayPopup"
      size="80%"
      append-to-body
      custom-class="front-binding-pay__drawer"
      @open="handleOpenDrawer"
      @close-from-icon="handleActivelyClose"
      @close-from-mask="handleActivelyClose"
    >
      <template #title>
        <div class="pre-pay-header">
          <span class="pre-pay-header__title">{{ language.SHEIN_KEY_PWA_30050 }}</span>
          <div
            v-if="isUseDefaultPrivacy || isUseNewPaymentSecurity"
            class="all-encrypted"
            @click="handlePaymentSecurityClick"
          >
            <Icon
              name="sui_icon_guarantee_18px"
              size="18px"
              color="#198055"
            />
            <span>{{ isUseDefaultPrivacy ? language.SHEIN_KEY_PWA_29739 : language.SHEIN_KEY_PWA_30725 }}</span>
            <!-- <Icon
              v-if="isUseNewPaymentSecurity"
              name="sui_icon_more_right_12px_2"
              size="12px"
            /> -->
          </div>
        </div>
      </template>
      <div class="pre-pay-container">
        <!-- <RandomDiscount
          :language="language"
          @handler-is-show-random-discount-des="handlerIsShowRandomDiscountDes"
        /> -->
        <LogoModal
          v-if="payDomainLogoList && payDomainLogoList.length"
          :logo-list="payDomainLogoList"
        />
        <div class="discount-block">
          <!-- <BinDiscountInfo
            v-show="operVisibleCardBinDiscount"
            class="ml-12"
            :language="language"
            :is-show-random-discount-des="isShowRandomDiscountDes"
          /> -->
          <!-- <div
            v-if="coBrandedCardBenefits && showCoBrandCardBenefits"
            class="co-branded-card-benefits"
          >
            <div>
              <span v-html="coBrandedCardBenefits"></span>
              <i
                class="sui_icon_doubt_16px_2 icon suiiconfont"
                style="width: 12px"
                @click.stop="handlePaymentOptionsOper('co-brand-card-article')"
              ></i>
            </div>
          </div> -->
        </div>
        <PaymentForm
          ref="paymentFormRef"
          :language="language"
          :card-bin-routing-info="cardBinRoutingInfo"
          :prePayCheckoutInfo="prePayState.prePayCheckoutInfo"
          :country-code="prePayState.prePayCheckoutInfo.shipCountry"
          :c2pInfo="c2pInfo"
          :abt-info="injectData && injectData.abtInfo"
          @handle-c2p-consent-info="handleC2pConsentInfo"
          @bin-change="handleCardBinChange"
        />
        <!-- <PaymentForm
          ref="paymentFormRef"
          :language="language"
          :abt-data="abtData"
          :card-bin-routing-info="cardBinRoutingInfo"
          :country-code="prePayCheckoutInfo.shipCountry"
          @bin-change="handleCardBinChange"
        /> -->

        <!-- <SupportInfoModule
          :language="language"
          :logoList="payDomainLogoList"
        /> -->
      </div>
      <template #footer>
        <div class="pre-pay-footer">
          <slot
            v-if="visiblePrePayPopup"
            name="footer"
            scene="pre-pay"
            :payNow="handlePrePayNow"
          ></slot>
        </div>
      </template>
    </SDrawer>
    <!-- 弹窗 -->
    <!-- <PrivacyDrawer
      v-model="visiblePrivacyDrawer"
      :language="language"
    >
      <template slot="privacy">
        <NewPrivacy
          :language="language"
          :logo-list="payDomainLogoList"
        />
      </template>
    </PrivacyDrawer> -->
    <SLazyMount>
      <FailGuideModal
        v-model:visible="prePayFailGuide.visible"
        :text="prePayFailGuide.failText"
        :type="prePayFailGuide.type"
        :close-cb="prePayFailGuide.closeCb"
        :confirm-cb="prePayFailGuide.confirmCb"
        :confirm-text="language.SHEIN_KEY_PWA_23438"
        :cancel-text="language.SHEIN_KEY_PWA_23439"
      />
    </SLazyMount>

    <BSrcPhoneEmailConfirmDialog
      :language-info="language"
      :is-show-dialog="isShowBSrcPhoneEmailConfirmDialog"
      :country-id="shippingCountryId"
      :is-pay="true"
      :c2p-phone-and-email-info="c2pPhoneAndEmailInfo"
      @getAllCountryList="getAllCountryList"
      @phoneValidateFn="phoneValidateFn"
      @submit="handlePhoneEmailConfirmSubmit"
      @close="closeBSrcPhoneEmailConfirmDialog"
    />
  </div>
</template>

<script setup lang="ts">
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
// import LogoModal from './components/LogoModal.vue'
// import PaymentForm from './components/Form.vue'
import { computed, ref, onMounted, defineAsyncComponent } from 'vue'
// import useCoBrandedCardPaymentToken from 'public/src/pages/checkout/hooks/useCoBrandedCardPaymentToken'
import { usePrePayment } from './hooks/usePrePayment.ts'
import { useCheckoutPreRouting } from './hooks/useCheckoutPreRouting.ts'
// import { usePrePayRearOrderFlow } from './hooks/usePrePayRearOrderFlow.ts'
import { injectLanguages, useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import langJson from '../../lang/front-binding.json'
import { type BFrontBindingCardKeys } from '../../lang/index.ts'
import {
  BSrcPhoneEmailConfirmDialog,
} from '@shein-aidc/bs-click-to-pay-mobile'

const LogoModal = defineAsyncComponent(() => import('./components/LogoModal.vue'))
const PaymentForm = defineAsyncComponent(() => import('./components/Form.vue'))
const FailGuideModal = defineAsyncComponent(() => import('./components/FailGuideModal.vue'))
// const PrivacyDrawer = defineAsyncComponent(() => import('public/src/pages/components/privacy_drawer.vue'))
// const SupportInfoModule = defineAsyncComponent(() => import('./components/SupportInfoModule.vue'))
// const NewPrivacy = defineAsyncComponent(() => import('public/src/pages/components/new_privacy.vue'))
// const InstallmentList = defineAsyncComponent(() => import('./components/AllInstallmentList.vue'))
// const RandomDiscount = defineAsyncComponent(() => import('./components/RandomDiscount.vue'))

function debuggerLog(...args) {
  if (typeof window === 'undefined') return
  try {
    const enableDebugLog = sessionStorage?.getItem?.('__bs__enable_debug_log')
    if (!enableDebugLog) return
    console.info(
      '%cPre Pay Libs Debug Info:',
      'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
      ...args,
      '\n',
    )
  } catch (e) {
    console.log(...args)
  }
}

const emits = defineEmits([
  'update:visible',
  'actively-close',
  'confirmNotUseCoupon',
  'payment-options-oper',
  'handle-action',
  'pay-now',
])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  currentPaymentInfo: {
    type: Object,
    default: () => ({}),
  },
  paymentCheckoutInfo: {
    type: Object,
    default: () => ({}),
  },
  handleSendDdc: {
    type: Function,
    default: () => {},
  },
  c2pInfo: {
    type: Object,
    default: () => ({}),
  },
  injectData: {
    type: Object,
    default: () => ({
      abtInfo: {},
    }),
  },
})

let cacheBin = ''

const {
  cardBinRoutingInfo,
  updateCardBinRoutingInfo,
  fetchPrePayRoutingForCheckout,
  // handleQueryUnPayPreRoutingInfo,
} = useCheckoutPreRouting()

const {
  prePayFailGuide,
  prePayState,
  paymentPageLogo,
  formatPrePayCheckoutInfo,
  fetchPreInfo,
  updatePrePayCheckoutInfo,
  mergePrePayUnPayParams,
  mergeState,
  resetPrePayState,
} = usePrePayment()

// const {
//   orderInfo,
//   handlePrePayRearOrderFlow,
// } = usePrePayRearOrderFlow()

const usePrePayModal = () => {
  const appConfigs = useAppConfigs()
  const language = ref<Record<BFrontBindingCardKeys, string>>({} as Record<BFrontBindingCardKeys, string>)
  const payDomainLogoList = computed(() => paymentPageLogo.value)
  const abtData = ref({})
  const paymentFormRef = ref()
  const installmentRef = ref()
  const isUseC2p = ref(false)
  const isRememberC2pCard = ref(false)

  const prePayEventBus = {}

  const handleActivelyClose = () => {
    // console.log('handleActivelyClose===')
    emits('actively-close')
  }

  const changeShowCouponsDrawer = () => {
    // console.log('changeShowCouponsDrawer===')
  }

  const handleOpenDrawer = () => {
    // console.log('handleOpenDrawer===', props.paymentCheckoutInfo)
    fetchPreInfo({ paymentCode: props.currentPaymentInfo.code })
    updatePrePayCheckoutInfo(formatPrePayCheckoutInfo({ checkoutInfo: props.paymentCheckoutInfo, selectedPayment: props.currentPaymentInfo }))
  }

  const handlePrePayRouting = async (bin = '') => {
    const {
      orderAttributesList,
      orderCurrency,
      orderUsdAmount,
      orderAmount,
      shipCountry,
      virtualOrderScene,
      routeCode,
    } = prePayState.prePayCheckoutInfo || {}

    const params = {
      bin,
      orderAttributesList,
      orderCurrency,
      orderUsdAmount,
      orderAmount,
      shipCountry,
      routeCode,
    }
    if (virtualOrderScene) Object.assign(params, { virtualOrderScene })
    await fetchPrePayRoutingForCheckout(params)
    return cardBinRoutingInfo.value
  }

  const handlePrePayBinChangeForCheckout = async (bin = '') => {
    // prePayStore.updateState('cardBinRoutingInfo', {})
    mergePrePayUnPayParams({
      cardBin: '',
      routeId: '',
      cardType: '',
      paymentCode: '',
    })
    updateCardBinRoutingInfo({})
    await handlePrePayRouting(bin).then((routingInfo: any) => {
      console.log('handlePrePayBinChangeForCheckout==routingInfo===', routingInfo)
      mergePrePayUnPayParams({
        cardBin: routingInfo.bin,
        routeId: routingInfo.routeId,
        paymentCode: routingInfo.payMethod,
        countryCode: routingInfo.orderCountry || prePayState.prePayCheckoutInfo?.shipCountry || '',
        cardType: routingInfo.cardType,
      })
    })
    const routingCardBin = prePayState.prePayUnPayParams.cardBin
    // TODO 待处理
    // prePayStore.mergeState('ddcFormParams', { bin: routingCardBin })
    emits('handle-action', {
      action: 'updateCheckout',
      payload: {
        params: {
          usedCardBin: routingCardBin,
          cardPaymentFront: 2, // 新卡场景
        },
        cb: ({ status }) => {
          // updateCheckoutComplete.value = true
          if (status === 'success') {
            console.log('handlePrePayBinChangeForCheckout==updateCheckout===success')
            // prePayStore.getInstallmentInfo({
            //   cardBin: prePayUnPayParams.value?.cardBin,
            //   cardType: prePayUnPayParams.value?.cardType,
            // }).then(installmentResInfo => {
            //   const { installments = [] } = installmentResInfo || {}
            //   debuggerLog('handlePrePayBinChangeForCheckout==installmentList===', installments)
            //   prePayStore.updateState('installmentList', installments)
            //   prePayStore.updateState('isShowInstallmentTipsShow', (installments || [])?.length === 0 || (installments?.length === 1 && installments?.[0]?.stage_num == 1))
            //   setTimeout(() => {
            //     prePayStore.updateState('isShowInstallmentTipsShow', false)
            //   }, 3000)
            // })
            const { jwt, formActionUrl } = props.currentPaymentInfo || {}
            if (routingCardBin && jwt && formActionUrl) {
              props.handleSendDdc?.({
                params: {
                  cardBin: routingCardBin,
                  jwt: jwt,
                  action: formActionUrl,
                },
                callback: (val) => {
                  // 写入ddc sessionId
                  mergePrePayUnPayParams({
                    sessionId: val || '',
                  })
                },
              })
            }
            // emits('handle-action', {
            //   action: 'handleSendDdc',
            //   payload: {
            //     cardBin: routingCardBin,
            //     callback: (val) => {
            //       mergePrePayUnPayParams({
            //         ddcSessionId: val || '',
            //       })
            //       console.log('handleSendDdc====', val)
            //     },
            //   },
            // })
          }
        },
      },
    })
  }

  // const handlePrePayBinChangeForUnPay = async (bin = '') => {
  //   updatePrePayCheckoutInfo({ billno: orderInfo.value.relation_billno || orderInfo.value.billno })
  //   const { routingInfo } = await handleQueryUnPayPreRoutingInfo({
  //     bin,
  //     routeCode: orderInfo.value.payment_method,
  //     paymentMethod: orderInfo.value.payment_method,
  //     orderCurrency: orderInfo.value.currency_code,
  //     countryCode: orderInfo.value.country_code || prePayState.prePayUnPayParams?.countryCode,
  //     billno: prePayState.prePayCheckoutInfo?.billno || '',
  //   })
  //   mergePrePayUnPayParams({
  //     cardBin: routingInfo?.bin,
  //     routeId: routingInfo?.routeId,
  //     paymentCode: routingInfo?.payMethod,
  //     countryCode: routingInfo?.orderCountry || orderInfo.value.country_code,
  //     cardType: routingInfo?.cardType,
  //   })
  // }

  const handleCardBinChange = async (bin = '') => {
    if (!bin) {
      // 清除前置路由数据及卡bin信息
      updateCardBinRoutingInfo({})
      updatePrePayCheckoutInfo({ cardBinDiscountInfo: {} })
      cacheBin = ''
      // rootStore.updateState?.('cardBinRoutingInfo', {})
      // rootStore.mergeState?.('prePayCheckoutInfo', { cardBinDiscountInfo: {} })
      // rootStore.updateState?.('displayCardBinDiscountInfo', {})
      // rootStore.mergeState?.('prePayUnPayParams', {
      //   ...rootStore.prePayUnPayParams,
      //   isShowCardBinDiscountDescInPriceDetail: false
      // })
      return
    }
    if (bin && bin.length < 8) {
      // prePayStore.updateState('installmentList', initInstallmentList?.value || [])
      // if (cacheBin)
      // operVisibleCardBinDiscount.value = false
      // isCoBrandedBenefitsUnderCardNo.value = false
      return
    }
    if (cacheBin === bin) {
      // operVisibleCardBinDiscount.value = true
      // isCoBrandedBenefitsUnderCardNo.value = true
      return
    }
    cacheBin = bin
    // 单后场景
    if (prePayState.prePayCheckoutInfo?.billno) {
      // handlePrePayBinChangeForUnPay(bin)
    } else {
      // 非单后场景
      handlePrePayBinChangeForCheckout(bin)
    }

    // handlerCouponsBin(bin)
  }

  const prePayForCheckoutValidator = () => {
    const { result, failReasonResults, formInfo = {} } = paymentFormRef.value?.formValidator?.() || {}
    if (result) {
      mergePrePayUnPayParams(formInfo)
    }
    return {
      validateResult: !!result,
      prePayUnPayParams: prePayState.prePayUnPayParams,
      freezeParamsStr: JSON.stringify(prePayState.cardBinFreezeParams || {}),
      failReasonResults: failReasonResults || [],
    }
  }

  const handlePrePayNow = (cb) => {
    const { validateResult, failReasonResults } = prePayForCheckoutValidator?.() || {}
    return cb?.({
      validateResult,
      scene: 'front_binding_card',
      metric_scene: 'front_binding_card_vaild_fail_scene',
      // orderInfo: {
      //   sub_billnos: (prePayState.orderInfo as any)?.sub_billnos || [],
      //   payment_method: (prePayState.orderInfo as any)?.payment_method,
      // },
      failReasonResults,
      prePayUnPayParams: prePayState.prePayUnPayParams,
      billno: prePayState.prePayCheckoutInfo?.billno,
      freezeParamsStr: prePayState.prePayCheckoutInfo?.billno ? JSON.stringify(prePayState.cardBinFreezeParams || {}) : {},
      isUseC2p: isUseC2p.value,
      isRememberC2pCard: isRememberC2pCard.value,
      c2pInfo: props.c2pInfo,
    })
  }

  const getLanguage = async () => {
    await injectLanguages({ langPackages: { ...langJson } })
    language.value = appConfigs.$language.BFrontBindingCard || {}
    console.log('language.value >>>>>>>', language.value, langJson)
  }

  const handleC2pConsentInfo = consentInfo => {
    console.log('consentInfo >>>>', consentInfo)
    const { isCheckedDcfSuppressed = false, isCheckedRememberMe = false } =
      consentInfo || {}
    isUseC2p.value = isCheckedDcfSuppressed
    isRememberC2pCard.value = isCheckedRememberMe
  }

  onMounted(() => {
    getLanguage()
  })

  return {
    language,
    payDomainLogoList,
    abtData,
    paymentFormRef,
    installmentRef,
    prePayEventBus,
    handleCardBinChange,
    handleActivelyClose,
    changeShowCouponsDrawer,
    handleOpenDrawer,
    handlePrePayNow,
    prePayForCheckoutValidator,
    // handlePrePayBinChangeForUnPay,
    handlerCouponsBin: () => {},
    handleC2pConsentInfo,
  }
}

const {
  payDomainLogoList,
  // logoListNotEmpty,
  language,
  paymentFormRef,
  // installmentRef,
  handleCardBinChange,
  handleActivelyClose,
  // prePayEventBus,
  // showCouponsDrawer,
  // changeShowCouponsDrawer,
  // nowUseCouponInfo,
  handlerCouponsBin,
  handleOpenDrawer,
  handlePrePayNow,
  prePayForCheckoutValidator,
  // handlePrePayBinChangeForUnPay,
  handleC2pConsentInfo,
  // billno
} = usePrePayModal()

const visiblePrivacyDrawer = ref(false)
// const operVisibleCardBinDiscount = ref(true)
// const isShowRandomDiscountDes = ref(false)
// const showCoBrandCardBenefits = ref(true)

// const paymentCode = toRef(props, 'paymentCode')
// const { coBrandedCardBenefits } = useCoBrandedCardPaymentToken(store, paymentCode, props.language)
const visiblePrePayPopup = computed({
  get () {
    return props.visible
  },
  set (bool) {
    emits('update:visible', !!bool)
  },
})

const isUseNewPaymentSecurity = computed(() => {
  // return abtData.value?.PaymentSafetyExperiment?.param?.PaymentSecurity === 'Type_A'
  return false
})

const isUseDefaultPrivacy = computed(() => {
  // return abtData.value?.PaymentSafetyExperiment?.param?.PaymentSecurity === 'Default'
  return true
})

const shippingCountryId = computed(() => {
  console.log('prePayState.prePayCheckoutInfo?.addressInfo?.countryId >>>>', prePayState.prePayCheckoutInfo, prePayState.paymentCheckoutInfo?.addressInfo?.countryId)
  return props.paymentCheckoutInfo?.addressInfo?.countryId || ''
})

const isShowBSrcPhoneEmailConfirmDialog = computed(() => {
  console.log('props.c2pInfo?.isShowBSrcPhoneEmailConfirmDialog >>>', props.c2pInfo)
  return props.c2pInfo?.isShowBSrcPhoneEmailConfirmDialog
})

const c2pPhoneAndEmailInfo = computed(() => {
  console.log('props.c2pInfo?.c2pPhoneAndEmailInfo >>>', props.c2pInfo?.c2pPhoneAndEmailInfo)
  return props.c2pInfo?.c2pPhoneAndEmailInfo || {}
})

// const scrollToSection = (el) => {
//   // console.log(el, 'el')
//   if (el) {
//     el?.scrollIntoView({ behavior: 'smooth' })
//   }
// }

// const handlerIsShowRandomDiscountDes = (val) => {
//   isShowRandomDiscountDes.value = val
// }

const handlePaymentSecurityClick = () => {
  if (!isUseNewPaymentSecurity.value) {
    return
  }
  visiblePrivacyDrawer.value = true
}

// const clearCardBin = () => {
//   paymentFormRef.value?.clearCardNumber()
// }

// const confirmNotUseCoupon = () => {
//   rootStore.mergeState('prePayCheckoutInfo', {
//     usableCouponList: []
//   })
//   changeShowCouponsDrawer(false)
//   emits('confirmNotUseCoupon')
// }

// const handlePaymentOptionsOper = (type) => {
//   emits('payment-options-oper', { type })
// }

const validateByPay = (): Trade_PayToolKit.ValidateByPayType & {
  saParams: any;
} => {
  let metric_scene = 'front_binding_card_dialog_visible'
  const { validateResult: preValidateResult, failReasonResults } = prePayForCheckoutValidator?.() || {}
  metric_scene = !preValidateResult ? prePayState.prePayCheckoutInfo?.billno ? 'front_binding_card_vaild_fail_scene' : 'front_binding_card_vaild_fail' : ''
  // console.log('validateByPay===', metric_scene, preValidateResult, props.currentPaymentInfo)
  return {
    validateResult: preValidateResult,
    metric_scene,
    scene: 'front_binding_card',
    saParams: !preValidateResult ? {
      result_reason: '1_12',
      payment_method_id: props.currentPaymentInfo?.payment_id,
    } : {},
    extraInfo: {
      failReasonResults,
      prePayUnPayParams: prePayState.prePayUnPayParams,
      prePayCheckoutInfo: prePayState.prePayCheckoutInfo,
    },
  }
}

const reset = () => {
  resetPrePayState()
  updateCardBinRoutingInfo({})
  paymentFormRef.value?.resetForm?.()
  cacheBin = ''
}
const handlePhoneEmailConfirmSubmit = (val) => {
  props?.c2pInfo?.handlePhoneEmailConfirmSubmit?.({
    val,
    cb: () => {
      props?.c2pInfo?.c2pNewCardPayFn?.()
    },
  })
}

const closeBSrcPhoneEmailConfirmDialog = () => {
  props?.c2pInfo?.closeBSrcPhoneEmailConfirmDialog?.()
}

const getAllCountryList = (cb) => {
  props?.c2pInfo?.getAllCountryList?.(cb)
}

const phoneValidateFn = (nowParams, cb) => {
  props?.c2pInfo?.phoneValidateFn?.(nowParams, cb)
}

// onMounted(() => {
//   prePayEventBus.showDefaultCardBinDiscount?.on(visible => {
//     operVisibleCardBinDiscount.value = !!visible
//   })

const handlePrePayAction = async ({ action, payload }: {action: any; payload: any}) => {
  debuggerLog('handlePrePayAction===', action, payload)
  switch (action) {
    // case 'showDefaultCardBinDiscount':
    //   prePayModalRef.value?.showDefaultCardBinDiscount?.(payload)
    //   break
    case 'visiblePayFailGuide':
      debuggerLog('visiblePayFailGuide===', payload)
      mergeState('prePayFailGuide', Object.assign({}, { visible: true }, payload))
      break
    // case 'startRearOrderFlow':
    //   await handlePrePayRearOrderFlow(payload)
    //   handlePrePayBinChangeForUnPay(prePayState.prePayUnPayParams?.cardBin?.substring?.(0, 8) || '')
    //   break
    case 'showBinFreezeFailConfig':
      // prePayModalRef.value?.showBinFreezeFailConfig?.(payload)
      break
    case 'closePrePayModal':
    visiblePrePayPopup.value = false
      // prePayStore.mergeState('visiblePrePay', false)
      // prePayStore.updateState('shouldSecondaryVerification', true)
      break
    default:
      break
  }
}

defineExpose({
  formValidator: () => paymentFormRef.value?.formValidator(),
  // installmentValidator: () => installmentRef.value?.validator(),
  // scrollToInstallment: () => scrollToSection(installmentRef.value?.$el),
  reset,
  handlerCouponsBin,
  validateByPay,
  handlePrePayAction,
})

</script>

<style lang="less">
.font-dpr(@font-size) {
  font-size: @font-size * 0.5;
}
.front-binding-pay__drawer {
  .sui-drawer__header {
    min-height: auto;
  }
  .pre-pay-header {
    line-height: 1.1;
    padding: .24rem 0 .18666667rem;
    &__title {
      .font-dpr(28px);
      font-weight: 590;
      font-family: SF Pro;
      color: var(--sui-color-gray-dark-3, #767676);
    }
    .all-encrypted {
      color: var(--sui-color-safety, #198055);
      .font-dpr(24px);
      word-wrap: break-word;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-right {
        color: #767676;
      }
    }
  }
  .pre-pay-container {
    min-height: 656/75rem;
  }
  .ml-12 {
    margin-left: 24/75rem;
  }
  // .S-drawer__normal-footer {
  //   margin-top: 0;
  //   height: auto;
  // }

  .sui-drawer__normal-footer {
    line-height: unset;
    margin-top: unset;
    height: unset;
  }

  .summary-price-wrapper {
    text-align: left;
  }

  .payment-discount-label + .co-branded-card-benefits{
    margin-top: 6 * 2/75rem;

  }
  .co-branded-card-benefits{
    padding:0 12 * 2/75rem;

    >div{
      display: inline-block;
      padding: 3 * 2/75rem 6 * 2/75rem;
      background: var(---sui_color_promo_bg, #fff6f3);
      border: .5px solid rgba(196, 74, 1, .3);
      font-size: 10 * 2/75rem;
      line-height: 12 * 2/75rem;
      color: var(---sui_color_promo_dark, #c44a01);

      >.icon{
        font-size: 12 * 2/75rem;
        color: #959595;
      }
    }



  }
}
</style>
