import type { PaymentItemProps } from './props'

export type PaymentItemSelectType = 'select-payment' | 'select' | 'select-token'

export interface ValidateByPayType {
  validateResult: boolean;
}

export interface PaymentItemsConfig {
  foldedPosition?: number;
  forceRememberCardTip?: string | null;
  checkoutForceRememberCardTip?: string | null;
}

export enum PPGA_CHECK_TYPE {
  CHECKED = '1',
  UNCHECKED = '0',
}

export interface BankInfo {
  grayBank: boolean;
  code: string;
  is_gray: number;
  name: string;
  logo: string;
  collect_email: '0' | '1';
  collect_phone_number: '0' | '1';
  // needCollectPhoneAndEmail?: '0' | '1' | '2';
}

export interface CollectInfoItem {
  phone: string;
  email: string;
  phoneCountryCode?: string;
  localPhone?: string;
}

interface PaymentRelatedInfo {
  email?: string;
  phoneNumber?: string;
}

interface RegexList {
  reg?: string;
  copywrite?: string;
}

interface Result {
  regex_list?: RegexList[];
  reverse_regex_list?: any[]; // 如果 reverse_regex_list 可能包含其他类型的数据，可以将 any[] 改为具体类型
}

export interface IGetPaymentRelatedInfoRes {
  code: string;
  msg: string;
  info: {
    paymentRelatedInfo?: PaymentRelatedInfo;
    phoneCheckRule?: {
      result?: Result;
    };
  }
}

export interface IGetCardBinDiscountArticleRes {
  code: string;
  data: {
    articleInfo: {
      conText: string;
      conName: string;
    }
  }
}
export interface IGetTelCountryListRes {
  code: string;
  msg: string;
  info: {
    country?: {
      item_cates?: {
        id: string;
        value: any;
        countrynum: string;
      }[]
    }
  }
}

export type CardTokenItem = PaymentItemProps['card_token_list'][0]

export enum LIST_CONTENT_CHANGE_TYPE {
  ADD_TOKEN = 'add_token',
  DELETE_TOKEN = 'delete_token',
}
