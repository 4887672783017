<template>
  <div
    v-if="tips"
    class="random-discount"
  >
    <Icon
      name="sui_icon_random_discount_16px"
      size="16px"
      :is-rotate="cssRight"
    />
    <span class="tip-content">
      {{ tips }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@shein-aidc/icon-vue3'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

defineProps({
  tips: {
    type: String,
    default: '',
  },
})

const { cssRight } = useAppConfigs()?.$envs || {}

</script>

<style lang="less" scoped>
.random-discount {
  display: flex;
  align-items: center;
  height: 80/75rem;
  background: #fff6f3;
  padding: 26/75rem 24/75rem;
  box-sizing: border-box;
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #c44a01;

  .tip-content {
    margin-left: 16/75rem;
  }
}
</style>
