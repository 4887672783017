<template>
  <SDialog
    v-model:visible="isShow"
    :show-close="true"
    :append-to-body="true"
    class="token-dialog"
    @close="closeDialog"
  >
    <div class="token-pay-del">
      <span>
        {{ language.BS_KEY_PAY_1016 }}
      </span>
    </div>

    <template #footer>
      <SButton
        :type="['H72PX']"
        width="45%"
        @click="closeDialog">
        {{ language.BS_KEY_PAY_1017 }}
      </SButton>
      <SButton
        :type="['primary', 'H72PX']"
        width="45%"
        @click="onClickConfirm">
        {{ language.BS_KEY_PAY_1018 }}
      </SButton>
    </template>
  </SDialog>
</template>

<script name="DeleteTokenDialog" setup lang="ts">
/**
 * 卡 token 删除组件
 */
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { useDeleteToken } from '../../../../hooks/useToken'
import { ref, watch } from 'vue'

interface DeleteTokenDialogProps {
  language: Record<string, string>
  isShowDelDialog: boolean
  deleteItem: Record<string, string>
}

const props = withDefaults(defineProps<DeleteTokenDialogProps>(), {
  language: () => ({}),
  isShowDelDialog: false,
  deleteItem: () => ({}),
})

const isShow = ref(false)

watch(() => props.isShowDelDialog, () => {
  isShow.value = props.isShowDelDialog
})

const emits = defineEmits(['change-token', 'close-delete-token-dialog'])

const closeDialog = () => {
    emits('close-delete-token-dialog')
}
const onClickConfirm = async () => {
    const res = await useDeleteToken({ id: props.deleteItem?.id })
    if (res && res.code == 0) {
        emits('change-token')
    } else {
        SToast(props.language.BS_KEY_PAY_1019)
    }

    closeDialog()
}
</script>

<style lang="less" scoped>
.token-dialog {
  .token-pay-del {
    text-align: center;
  }

  /deep/.sui-dialog__footer {
    display: flex;
    justify-content: space-evenly;
  }
}
</style>
