import { patternEmail } from '@shein/common-function'

export function useEmailValidate() {
  const validateEmail = (email: string) => {
    const nowEmail = email?.trim()
    // 邮箱正则 /^([\w-.]+)@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.)|(([\w-]+.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(]?)$/
    // signUp 为 true 时 /^(?=.{5,100}$)^[a-zA-Z0-9]+([-.]?([a-zA-Z0-9])+[-]?)*@([a-zA-Z0-9]+([_.-]?[a-zA-Z0-9]+)*.)([a-zA-Z]{2,6}|[0-9]{1,3}){1}$/
    return patternEmail({ email: nowEmail, signUp: true })
  }

  return {
    validateEmail,
  }
}
