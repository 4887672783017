<template>
  <div class="payment-form-item card-number-item">
    <BaseFormItem
      ref="baseFieldRef"
      v-model="fieldValue"
      inputmode="numeric"
      :placeholder="placeholder"
      :label="label"
      :required="true"
      :clearable="true"
      inputCustomClass="card-number-item__input"
      @change="handleValueInput"
      @blur="handleValueBlur"
      @clear="emits('clear')"
    >
      <template #input-pre>
        <div class="card-logo-blok">
          <CardLogo
            class="input-logo-pre"
            :src="cardLogo"
          />
          <slot name="prepend"></slot>
          <!-- <div
            v-show="isShowInstallmentTipsShow"
            class="card-tooltips"
          >
            {{ language.BS_KEY_PAY_1203 }}
            <span
              class="iconfont icon-close"
              @click="changeIsShowInstallmentTipsShow"
            ></span>
          </div> -->
        </div>
      </template>
    </BaseFormItem>
    <div class="discount-block">
      <slot name="discount-suffix"></slot>
      <!-- <div
        v-if="isShowBinCouponsDiscount"
        class="discount-item">
        <BinCouponsDiscount
          :payments-preferential-tips-item="paymentsPreferentialTipsItem" />
      </div>
      <div
        v-if="coBrandedCardBenefits"
        class="discount-item">
        <BinDiscountLabel
          class="mt-0"
          :text="coBrandedCardBenefits"
        />
      </div>
      <div
        v-if="binDiscountTokenInfo.binDiscountTip"
        class="discount-item wholeLine">
        <BinDiscountLabel
          class="mt-0"
          :text="binDiscountTokenInfo.binDiscountTip"
        />
      </div> -->
    </div>
    <div
      v-show="errorTips"
      class="error-invalid"
    >
      {{ errorTips }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { getCursortPosition, setCursortPosition } from '../utils/helpers'
import { ref, nextTick, computed } from 'vue'
// import { template } from '@shein/common-function'
import BaseFormItem from './BaseFormItem.vue'
// import BinDiscountLabel from '../BinDiscountLabel.vue'
import CardLogo from '../components/CardLogo.vue'
// import { cardNumberValidator } from '../../../../utils/validator'
// import { usePrePayStore } from '../../../../hooks/usePrePay'
// import BinCouponsDiscount from '../../payment-items/cell/BinCouponsDiscount.vue'
// import { usePaymentsStore } from '../../../../hooks/usePayments'
// import { CO_BRAND_LEVEL_LIST } from '../../../../common/constants'

// const CARD_BIN_COUPONS_TYPE = 4 // 卡bin优惠券

type Validator = (cardNumber: string) => {
  result: boolean
  failType?: string
  failReason?: string
  reasonType?: string
  message?: string
}
interface Props {
  modelValue: string; // 值
  errorMessage?: string; // 错误提示信息
  required?: boolean; // 是否必填
  label?: string; // 标签文本
  placeholder?: string; // 输入框占位文本
  clearable?: boolean; // 是否显示清除按钮
  formatter?: (value: string) => string; // 指定输入值格式化函数
  cardLogo: string;
  validator?: Validator; // 自定义校验规则
}


const props = withDefaults(defineProps<Props>(), {
  value: '',
  cardLogo: '',
  label: '',
})

const emits = defineEmits(['update:modelValue', 'blur', 'clear', 'change'])

// const prePayStore = usePrePayStore()
// const paymentsStore = usePaymentsStore()

const baseFieldRef = ref()
const fieldValue = ref(props.modelValue || '')
const errorTips = ref('')

// const isShowInstallmentTipsShow = computed(() => prePayStore?.isShowInstallmentTipsShow)

const nowCardNum = computed(() => fieldValue.value?.replace?.(/\s/g, '') || '')
// const nowCardBin = computed(() => props.cardBinRoutingInfo?.bin || '')

// 当前卡bin 支持的卡 bin 优惠券
// const nowUseCouponData = computed(() => {
//   return [
//     ...(paymentsStore?.couponListState?.cmpAddCouponCoupons || []),
//     ...(paymentsStore?.couponListState?.cmpUsableCoupons || []),
//     ...(paymentsStore?.couponListState?.cmpBestCombinationCoupons || []),
//   ]?.find(
//     item => item?.businessExtension?.order?.card?.cardCodes?.includes(nowCardBin.value)) || nowCardBin.value?.length === 0
// })

// const paymentsPreferentialTipsItem = computed(() => paymentsStore?.selectedPayment?.paymentsPreferentialTips?.find(item => item?.type == CARD_BIN_COUPONS_TYPE))
// const isShowBinCouponsDiscount = computed(() => paymentsPreferentialTipsItem.value && nowUseCouponData.value)

// 联名卡积分
// const coBrandedCardBenefits = computed(() => {
//   if (fieldValue.value.replaceAll(' ', '').length < 8) {
//     return null
//   }
//   const { coBrandLevel } = props.cardBinRoutingInfo || {}
//   const coBrandedCardIntegral = paymentsStore?.checkoutInfo?.coBrandedCardIntegral
//   const isCoBrandedCard = coBrandLevel && CO_BRAND_LEVEL_LIST.includes(coBrandLevel)
//   if (!isCoBrandedCard || !coBrandedCardIntegral) return null
//   return template(coBrandedCardIntegral, props.language.BS_KEY_PAY_1212)
// })

const handleValueInput = ev => {
  //获取光标位置
  let dom = ev?.target
  let txt = ev?.target?.value || ''
  let pos = getCursortPosition(dom)

  //格式化
  let num = txt?.replace(/\D/g, '') || ''
  num = num?.substring?.(0, 20) || ''
  fieldValue.value = ev.target.value = num.replace(/(\d{4})(?=\d)/g, '$1 ')
  emits('update:modelValue', fieldValue.value)
  emits('change', nowCardNum.value)
  if (!fieldValue.value) {
    errorTips.value = ''
  }

  nextTick(function () {
    //将光标设置在原光标前部分内容格式化后的长度处
    let sub = txt.substring(0, pos)
    let newSub = sub.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
    setCursortPosition(dom, newSub.length)
  })
}

const handleValueBlur = () => {
  validator()
  emits('blur', nowCardNum.value)
}

const hiddenErrorTips = () => {
  errorTips.value = ''
}

const validator = () => {
  const cardNumber = nowCardNum.value
  const validateResult = props.validator?.(cardNumber)
  if (validateResult?.result) {
    hiddenErrorTips()
  } else {
    errorTips.value = validateResult?.message || ''
  }
  return {
    result: validateResult?.result,
    failType: validateResult?.failType,
    failReason: validateResult?.failReason,
    reasonType: 'card_number',
  }
}

const reset = () => {
  fieldValue.value = ''
  errorTips.value = ''
}

defineExpose({
  validator,
  reset,
  focus: () => baseFieldRef.value.focus(),
  clear: () => baseFieldRef.value.clear(),
})

</script>

<style lang="less">
.payment-form-item {
  padding: 24/75rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24/75rem;
    right: 0;
    height: 1px;
    background-color: var(--sui_color_gray_light2, #e5e5e5);
  }
  &.no-underline {
    &:after {
      display: none;
    }
  }
  .error-invalid {
    font-size: 12px;
    line-height: 1;
    color: var(--sui_color_promo, #FA6338);
    margin-top: 8/75rem;
    transition: height 0.8s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .discount-block {
      padding-top: 8*2/75rem;
      .discount-item {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      margin-bottom: 8*2/75rem;

      &.wholeLine {
        display: block;
      }
      &:not(:last-of-type) {
        position: relative;
        padding-right: 20*2/75rem;
        &:after {
          content: '+';
          line-height: 1;
          width: 20*2/75rem;
          text-align: center;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .mt-0{
        margin: 0;
      }
    }
  }
}
.card-number-item {
  &__input {
    display: flex;
  }
  .input-logo-pre {
    margin-right: 8/75rem;
  }
}

.card-logo-blok {
  position: relative;
}
// .card-tooltips {
//   // max-width: 304*2/75rem;
//   width: 300*2/75rem;
//   position: absolute;
//   top: calc(100% + 0.24rem);
//   padding: 8*2/75rem;
//   font-size: 10px;
//   font-weight: 700;
//   color: #fff;
//   line-height: 1.2;
//   word-wrap: break-word;
//   background-color: rgba(0, 0, 0, 0.8);
//   border-radius: 4/75rem;
//   z-index: 3;

//   .icon-close {
//     position: absolute;
//     right: 0;
//     top: 0;
//     font-size: 20/75rem;
//     color: #fff;
//     padding: 8/75rem;
//   }

//   &:before{
//     content: '';
//     position: absolute;
//     top: -12/75rem;
//     left: 16/75rem;
//     border: 10/75rem solid transparent;
//     border-top: 0 solid transparent;
//     border-bottom: 12/75rem solid transparent;
//     border-bottom-color: #000;
//     opacity: 0.8;
//   }

// }
</style>
