<template>
  <div
    v-if="termsLink"
    class="signup-term-info"
    v-html="termsLink"
  >
  </div>
</template>

<script setup lang="ts" name="SignupTermInfo">
withDefaults(defineProps<{
  termsLink: string;
}>(), {
  termsLink: '',
})
</script>

<style lang="less">
.font-dpr(@font-size) {
    font-size: @font-size * 0.5;
}

.signup-term-info {
  margin-top: 4/37.5rem;
  .font-dpr(24px);
  line-height: 1;

  a {
      color: @sui_color_link;
      &:hover {
          color: @sui_color_link;
      }
  }
}
</style>
