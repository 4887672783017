import { getSource } from '../../../common/dataSource'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { ref, onMounted, computed } from 'vue'
import type { Ref } from 'vue'
import type { DS_ClickToPay } from '../../../types/index'
import { debuggerLog } from '../utils/index'

interface UseGetLang {
    isNoGetLang?: Boolean
}

export const useGetLang = (params: UseGetLang): {
    languageInfo: Ref<Partial<Record<DS_ClickToPay.LangKeys, string>>>
} => {
    const tempLang = ref<Partial<Record<DS_ClickToPay.LangKeys, string>>>({})

    const getLang = async () => {
        debuggerLog('getLang >>>>', params?.isNoGetLang)
        if (params?.isNoGetLang) return
        const appConfigs = useAppConfigs()
        const api = getSource({ appConfigs })
        const { getLanguageInfo } = api
        const allLanguageInfo = await getLanguageInfo()
        tempLang.value = allLanguageInfo || {}
    }

    const languageInfo = computed(() => tempLang.value || {})

    onMounted(() => {
        getLang()
    })

    return {
        languageInfo,
    }
}
