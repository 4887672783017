<template>
  <div class="installment-list-block">
    <div class="split-line"></div>
    <div class="list-content">
      <template v-if="!isHideInstallments">
        <div
          class="first-title"
        >
          {{ language.BS_KEY_PAY_1144 }}
        </div>
        <div
          v-for="(group, groupIndex) in tempInstallmentGroup"
          :key="groupIndex"
          class="list-group">
          <RenderByVNode :render="group.title" />
          <div class="list-block">
            <template v-for="(item, index) in filterShowInstallments(group)">
              <InstallmentItemNewStyle
                v-if="useNewStyle"
                :key="`new_${index}`"
                :language="language"
                :data="item"
                :selected="selectedStageNum === item.stage_num"
                @click="selectInstallment(item)"
              />
              <div
                v-else
                :key="index"
                class="installment-item"
                :class="[
                  {
                    'installment-item-selected': selectedStageNum === item.stage_num
                  }
                ]"
                @click="selectInstallment(item)"
              >
                <div
                  class="installment-percent"
                  :class="[isZeroRate(item) && 'installment-percent__zero']"
                >
                  {{ getInstallmentPercentTxt(item) }}
                </div>
                <div class="installment-month">
                  {{ getInstallmentMonthTxt(item) }}
                </div>

                <div
                  v-if="isZeroRate(item)"
                  class="interest"
                >
                  <span class="interest-text">
                    {{ language.BS_KEY_PAY_1072 }}
                  </span>
                  <div class="triangle-down"></div>
                </div>
              </div>
            </template>
          </div>
          <div
            v-if="group.overlimit && !group.isShowMore"
            class="view-more"
            @click="handlerMore(group)"
          >
            <span class="view-more-text">
              {{ language.BS_KEY_PAY_1145 }}
            </span>
            <Icon
              name="sui_icon_more_down_12px_2"
              size="12px"
              :is-rotate="cssRight"
              :class="{
                'icon-up': group.isShowMore
              }"
            />
          </div>
        </div>
      </template>
      <div
        v-else
        class="installment-unable"
      >
        <Icon
          name="sui_icon_info_12px_2"
          size="12px"
          :is-rotate="cssRight"
        />
        <div class="title">
          {{ language.BS_KEY_PAY_1202 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="AllInstallmentList" lang="ts">
/*
 * 支付方式组件: 路由卡分期分期选项抽屉分期列表
 *
 * */
import { ref, computed, h, watch, inject } from 'vue'
import { template } from '@shein/common-function'
import { type LangKeys } from '../../../common/languages'
import { type Trade_PayToolKit } from '@shein-aidc/types-trade'
import { Icon } from '@shein-aidc/icon-vue3'
import RenderByVNode from './RenderByVNode.tsx'
import InstallmentItemNewStyle from './InstallmentItemNewStyle.vue'
import { usePaymentsStore } from '../../../hooks/usePayments'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { debuggerLog } from '../../../utils'
import type { AS_PayToolkit } from '../../../types'
const { triggerNotice } = inject('analysisInstance') as AS_PayToolkit.AnalysisInstance

const NOW_PAY_CODE = 'routepay-cardinstallment'

interface AllInstallmentListProps {
  language: Record<LangKeys, string>;
  installmentOptions: Trade_PayToolKit.InstallmentGroup['installments'];
  isDisplay?: boolean;
  recommendInstallments?: Trade_PayToolKit.InstallmentGroup['recommendInstallments'];
}

const emit = defineEmits<{
  (event: 'select-installment', info: Trade_PayToolKit.InstallmentInfoItem | Record<string,never> ): void,
}>()

const props = withDefaults(defineProps<AllInstallmentListProps>(), {
  language: () => ({} as Record<LangKeys, string>),
  installmentOptions: () => ([] as Trade_PayToolKit.InstallmentGroup['installments']),
  isDisplay: false,
})

const { cssRight } = useAppConfigs()?.$envs || {}

const paymentStore = usePaymentsStore()

// 选中期数
const selectedStageNum = ref(0)
// 显示更多按钮的数量限制
const showViewMoreNum = ref(6)
// 是否显示错误提示
const isShowErrorTips = ref(false)

// 只有一期或不支持分期时，隐藏分期选项
const isHideInstallments = computed(() => {
  return (props.installmentOptions || [])?.length === 0 || (props.installmentOptions?.length === 1 && props.installmentOptions?.[0]?.stage_num == 1)
})

// useNewStyle 是分期选项列表分组和默认选中的前提条件
const useNewStyle = computed(() => {
  return paymentStore.abtInfo?.InstalUIUpgrade?.param?.InstalUIUpgradeStatus === 'on' && paymentStore.selectedPayment.code === NOW_PAY_CODE
})

type InstallmentGroup = Trade_PayToolKit.InstallmentGroup
type InstallmentInfoItem = InstallmentGroup['installments'][number]

const tempInstallmentGroup = ref<InstallmentGroup[]>([])
watch(() => [props.isDisplay, props.installmentOptions] as [boolean, InstallmentGroup['installments']], ([isDisplay, installmentOptions]) => {
  if (!isDisplay) return
  const installments: InstallmentGroup['installments'] = installmentOptions
  defaultSelectInstallment(installments)
  const hasZeroFee = installments?.some?.(({ installment_fee_numeric }) => {
    return installment_fee_numeric && Number(installment_fee_numeric) === 0
  })

  // 旧版分期列表 或者 无免息分期，均采用原有逻辑
  if (!useNewStyle.value || !hasZeroFee) {
    const oldShowMoreStatus = tempInstallmentGroup.value.find(group => group.type === 'old')?.isShowMore
    tempInstallmentGroup.value = [{
      type: 'old',
      title: () =>
        h(
          'div',
          {
            class: {
              title: true,
              'red-title': isShowErrorTips.value,
            },
          },
          props.language.BS_KEY_PAY_1071,
        ),
      installments: installments,
      isShowMore: typeof oldShowMoreStatus === 'boolean' ? oldShowMoreStatus : false,
      overlimit: installments.length > showViewMoreNum.value,
    }]
    return
  }

  const zeroFeeInstallments: InstallmentGroup['installments'] = []
  const feeInstallments: InstallmentGroup['installments'] = []
  const hasFee = installments?.some?.(({ installment_fee_numeric }) => {
    return installment_fee_numeric && Number(installment_fee_numeric) > 0
  })

  installments.forEach((item) => {
    const { installment_fee_numeric } = item
    const isZeroFee = installment_fee_numeric && Number(installment_fee_numeric) === 0

    if (isZeroFee) {
      zeroFeeInstallments.push(item)
    } else {
      feeInstallments.push(item)
    }
  })

  const tempInstallmentsGroup: InstallmentGroup[] = []

  // 免息分组
  if (zeroFeeInstallments.length) {
    tempInstallmentsGroup.push({
      type: 'zeroFee',
      title: () => {
        return h('div', {
          class: 'group-title',
          domProps: {
            innerHTML: hasFee ? props.language.BS_KEY_PAY_1150 : props.language.BS_KEY_PAY_1151,
          },
        })
      }, // 无免息手续费的情况下，显示All
      installments: zeroFeeInstallments,
    })
  }

  // 计息分组
  if (feeInstallments.length) {
    const hasRecommendTagIndex = feeInstallments.findIndex(item => item.default_render && item.recommend_reason)
    const oldShowMoreStatus = tempInstallmentGroup.value.find(group => group.type === 'fee')?.isShowMore
    tempInstallmentsGroup.push({
      type: 'fee',
      title: () => {
        return h('div', { class: 'group-title' }, props.language.BS_KEY_PAY_1152)
      },
      installments: feeInstallments,
      isShowMore: typeof oldShowMoreStatus === 'boolean' ? oldShowMoreStatus : hasRecommendTagIndex >= showViewMoreNum.value ? true : false, // 如果推荐分期在展示范围之外，且需要展示标签，则默认展示更多
      overlimit: feeInstallments.length > showViewMoreNum.value,
    })
  }

  tempInstallmentGroup.value = tempInstallmentsGroup
}, {
  immediate: true,
})

// 默认选中逻辑
function defaultSelectInstallment(installmentList: InstallmentInfoItem[]) {
  // 存在已选分期数时，保持选中或者清空选中
  if (selectedStageNum.value > 0) {
    const nowSelectedInstallment = (installmentList || []).find(item => item.stage_num === selectedStageNum.value)
    if (!nowSelectedInstallment) {
      selectInstallment()
    } else {
      selectInstallment(nowSelectedInstallment)
    }
    return
  }

  // 分期数为1时，需要默认选中分期数
  if (installmentList.length === 1) {
    selectInstallment(installmentList[0])
    return
  }

  // 新样式且卡路由分期才支持默认选中推荐分期
  const isRpci = paymentStore.selectedPayment.code === NOW_PAY_CODE
  if (isRpci && useNewStyle.value) {
    const recommendItem = (installmentList || []).find(item => item.default_choose)
    recommendItem && selectInstallment(recommendItem)
  }
}

// 展示overlimit的分期数,或者全展开
const filterShowInstallments = (group: InstallmentGroup) => {
  return group.overlimit && !group.isShowMore ? group.installments.slice(0, showViewMoreNum.value) : group.installments
}

const isZeroRate = item => item?.installment_fee_numeric <= 0

function selectInstallment(installment: InstallmentInfoItem | Record<string,never> = {}) {
  selectedStageNum.value = installment?.stage_num || 0
  validator()

  installment && emit('select-installment', installment)
  debuggerLog('selectInstallment===item===', installment)

  const { installment_fee_numeric } = installment
  const isZeroFee = installment_fee_numeric && Number(installment_fee_numeric) === 0

  triggerNotice({
      id: 'click_installment.comp_pay-toolkit',
      data: {
        instalment_num: selectedStageNum.value,
        payment_method: paymentStore.selectedPayment.code,
        isinstallmentRecommend: props.recommendInstallments === installment?.stage_num ? '1' : '0',
        isInstallmentFree: isZeroFee ? '1' : '0',
        installmentRecommendChosen: installment?.default_choose ? '1' : '0',
      },
  })
}

const getInstallmentPercentTxt = item => {
  return template(
    item.stage_num,
    item.rate || 0,
    props.language?.BS_KEY_PAY_1073,
  )
}

const getInstallmentMonthTxt = item => {
  return template(item.installment_amount, props.language?.BS_KEY_PAY_1074)
}

const handlerMore = (group: InstallmentGroup) => {
  group.isShowMore = !group.isShowMore
}

function validator() {
  // return +prePayUnPayParams.value?.installments > 0
  const valid = selectedStageNum.value > 0 || isHideInstallments.value
  isShowErrorTips.value = !valid
  return valid
}

const reset = () => {
  selectedStageNum.value = 0
  tempInstallmentGroup.value.forEach(group => {
    group.isShowMore = false
  })
}

defineExpose({
  validator,
  reset,
})
</script>

<style lang="less" scoped>
.nowrap-text() {
  // 超出一行展示省略号
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.installment-list-block {
  .split-line {
    background-color: #f6f6f6;
    height: 16/75rem;
  }

  .view-more {
    margin-top: 12 * 2/75rem;
    color: #767676;
    font-size: 14 * 2/75rem;
    font-family: SF UI Text;
    font-weight: 400;
    word-wrap: break-word;
    text-align: center;
    border-top: 0.5px #e5e5e5 solid;
    padding-top: 10 * 2/75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view-more-text {
    margin-right: 4/75rem;
  }

  .icon-up {
    transform: rotate(180deg);
  }

  .list-content {
    padding: 12 * 2/75rem 0;
  }

  .first-title {
    padding: 0 12 * 2/75rem;
    margin-bottom: 2 * 2/75rem;
    color: black;
    font-size: 14 * 2/75rem;
    line-height: 17 * 2/75rem;
    font-family: SF UI Text;
    font-weight: 700;
    word-wrap: break-word;
  }

    .list-group {

      &:not(:last-of-type) {
        margin-bottom: 18 * 2/75rem;
      }

      .group-title {
        padding: 0 12 * 2/75rem;
        margin-bottom: 2 * 2/75rem;
        color: #000;
        font-size: 12 * 2/75rem;
        line-height: 14 * 2/75rem;
        font-family: SF UI Text;
        word-wrap: break-word;
      }
    }

  .title {
    padding: 0 12 * 2/75rem;
    color: black;
    font-size: 12 * 2/75rem;
    line-height: 14 * 2/75rem;
    font-family: SF UI Text;
    font-weight: 400;
    word-wrap: break-word;
  }

  .red-title {
    color: #BF4123;
    font-size: 12 * 2/75rem;
    line-height: 14 * 2/75rem;
    font-family: SF UI Text;
    font-weight: 400;
    word-wrap: break-word;
  }

  .list-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 12 * 2/75rem;
  }

  .installment-item {
    width: 171 * 2/75rem;
    height: 54 * 2/75rem;
    padding: 12 * 2/75rem 8 * 2/75rem;
    background: #f6f6f6;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    margin-top: 12 * 2/75rem;
    position: relative;

    .interest {
      position: absolute;
      height: 17 * 2/75rem;
      padding: 2 * 2/75rem 4 * 2/75rem;
      background: #fa6338;
      right: -5/75rem;
      top: -17/75rem;
      color: rgba(255, 255, 255, 0.96);
      font-size: 11px;
      font-family: SF Pro;
      font-weight: 400;
      word-wrap: break-word;
    }

    .interest-text {
      display: inline-block;
      max-width: 141 * 2/75rem;
      .nowrap-text();
      line-height: 1;
    }

    .triangle-down {
      position: absolute;
      right: 0;
      bottom: -5px;
      width: 5px;
      height: 5px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/06/12/cc/17181845892a8bc8c0d85d631c447c42390dc668b3.png);
    }
  }

  .installment-percent {

    &__zero{
      color: #fa6338;
    }
    font-size: 12px;
    font-family: SF UI Text;
    font-weight: 600;
    word-wrap: break-word;
    line-height: 1;
    margin-bottom: 4/75rem;
  }

  .installment-month {
    color: black;
    font-size: 12px;
    font-family: SF UI Text;
    font-weight: 400;
    word-wrap: break-word;
    line-height: 1;
  }

  .installment-item-selected {
    border: 1.5px black solid;
  }

  .installment-unable {
    padding: 0 12 * 2/75rem 0 12 * 2/75rem;
    display: flex;
    align-items: center;
    .title {
      color: #666666;
      font-size: 11px;
      font-weight: 510;
      word-wrap: break-word;
      font-family: SF Pro;
    }
  }
}
</style>
