import { ref, reactive, onMounted } from 'vue'
import { useGetAllCountryInfo } from './useGetPhoneCountryNum'
import { useEmailValidate } from './useEmailValidate'
import { usePhoneValidate } from './usePhoneValidate'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { debuggerLog } from '../utils/index'

export const useGetPhoneEmail = ({ countryId, props }) => {
    const { validateEmail } = useEmailValidate()
    const appConfigs = useAppConfigs()

    const prevStageObj = ref({})
    const prevStageSelected = ref('')
    const countryCodes = ref([])

    const emailInfo = reactive({
        email: '',
        isFocus: false,
        errTip: '',
      })
      const phoneInfo = reactive({
        code: props?.areaInfo?.areaCode || '',
        phone: '',
        isFocus: false,
        errTip: '',
        id: '',
      })

      const phoneInputFocus = () => {
        phoneInfo.isFocus = true
        phoneInfo.errTip = ''
      }

      const EmailInputFocus = () => {
        emailInfo.isFocus = true
        emailInfo.errTip = ''
      }

      const getNum = (str) => {
        const regex = /\d+/g
        return (str?.match(regex) || []).join('')
      }

      const setAllCountryInfo = (countryInfo) => {
        countryCodes.value = countryInfo
        prevStageObj.value = countryInfo.reduce((prev, next) => {
          prev[next.value] = `+${getNum(next.countrynum)}`

          if (next.id == countryId) {
              prevStageSelected.value = next.value
              phoneInfo.code = getNum(next.countrynum)
              phoneInfo.id = next?.id || ''
          }
          return prev
        }, {})
    }

      const getAllCountryInfo = async () => {
        if (props?.countryList?.length > 0) {
          setAllCountryInfo(props?.countryList)
          return
        }
        if (props?.isPay) return
        const res = await useGetAllCountryInfo()
        debuggerLog('res >>>>', res)
        if (res?.code == 0) {
          setAllCountryInfo(res?.info?.country?.item_cates || [])
        }
      }

      const prevStageChange = value => {
        prevStageSelected.value = value
        phoneInfo.code = getNum(prevStageObj.value[value])
        debuggerLog('value >>>>', value, phoneInfo.code)
      }

      const reset = () => {
        emailInfo.email = ''
        emailInfo.errTip = ''
        phoneInfo.phone = ''
        phoneInfo.errTip = ''
      }

      const handlerEmailBlur = () => {
        emailInfo.isFocus = false

        if (!validateEmail(emailInfo.email)) {
          emailInfo.errTip = props?.languageInfo?.BS_KEY_SRC_1047 || ''
        }

        if (emailInfo.email?.length === 0) {
          emailInfo.errTip = props?.languageInfo?.BS_KEY_SRC_1046 || ''
        }
      }

      const handlerPhoneBlurCb = async (validateCb) => {
        const res = await usePhoneValidate({
            alias_type: 2,
            area_code: phoneInfo.code,
            alias: phoneInfo.phone,
            area_abbr: prevStageSelected.value,
        })

        if (res?.code !== 0) {
            phoneInfo.errTip = props?.languageInfo?.BS_KEY_SRC_1048 || res?.msg || ''
        } else {
          validateCb && validateCb(res)
        }
      }

      const handlerPhoneBlur = (validateCb) => {
        phoneInfo.isFocus = false

        handlerPhoneBlurCb(validateCb)
      }

      const setPhoneCode = (item) => {
        phoneInfo.code = getNum(item?.countrynum || '')
        phoneInfo.id = item?.id || ''
        prevStageSelected.value = item?.value || ''
      }

      onMounted(() => {
        if (appConfigs.$envs.project !== 'h5') {
          getAllCountryInfo()
        }
      })

    return {
        emailInfo,
        phoneInfo,
        phoneInputFocus,
        EmailInputFocus,
        prevStageObj,
        prevStageSelected,
        prevStageChange,
        reset,
        handlerEmailBlur,
        handlerPhoneBlur,
        countryCodes,
        setPhoneCode,
        setAllCountryInfo,
    }
}
