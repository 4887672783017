<template>
  <div class="payment-card-field no-underline">
    <BaseField
      ref="baseFieldRef"
      v-model="expireDateValue"
      inputmode="text"
      :placeholder="`${language.BS_KEY_PAY_1172}/${language.BS_KEY_PAY_1189}`"
      :label="language.BS_KEY_PAY_1173"
      :required="true"
      :readonly="true"
      @click="handleToggleVisibleDatePicker(true)"
    >
      <template #input-end>
        <Icon
          name="sui_icon_more_down_12px_2"
          size="12px"
          color="#959595"
          :is-rotate="cssRight"
          @click="handleToggleVisibleDatePicker(true)"
        />
      </template>
    </BaseField>
    <div
      v-show="errorTips"
      class="error-invalid"
    >
      {{ errorTips }}
    </div>
    <!--  -->
    <SLazyMount>
      <DatePicker
        v-model:modelValue="selectDate"
        v-model:visible="visibleDatePicker"
        :cancel-text="language.BS_KEY_PAY_1174"
        :confirm-text="language.BS_KEY_PAY_1175"
        @select="handleSelectDate"
      />
    </SLazyMount>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import BaseField from './BaseField.vue'
import DatePicker from './DatePicker.vue'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

function getCurrentDate() {
  const nd = new Date()
  return {
    year: nd.getFullYear(),
    month: nd.getMonth() + 1,
    day: nd.getDay() + 1,
  }
}

const pandingZero = val => +val < 10 ? `0${+val}` : val

const emits = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  language: {
    type: Object,
    default: () => ({}),
  },
})

const { cssRight } = useAppConfigs()?.$envs || {}

const baseFieldRef = ref()
const errorTips = ref('')
const visibleDatePicker = ref(false)
const selectDate = ref([+props.modelValue?.split('/')?.[0] || 0, +props.value?.split('/')?.[1] || 0])
const expireDateValue = ref('')

const limitMinDate = () => {
  const { year, month } = getCurrentDate()
  return [month, year]
}

const handleSelectDate = val => {
  selectDate.value = val
  const [month, year] = val
  expireDateValue.value = [pandingZero(month), year].join('/')
  validator()
  emits('update:modelValue', expireDateValue.value)
}

const handleToggleVisibleDatePicker = (visible) => {
  console.log('handleToggleVisibleDatePicker', visible)
  visibleDatePicker.value = !!visible
}

const validator = () => {
  const [ limitMinMonth, limitMinYear ] = limitMinDate()
  const [month, year] = selectDate.value || []
  let failType = ''
  errorTips.value = ''
  if (!month || !year || !expireDateValue.value) {
    failType = 'null'
    errorTips.value = props.language.BS_KEY_PAY_1176
  } else if (+year === limitMinYear && +month < limitMinMonth) {
    failType = 'time_expired'
    errorTips.value = props.language.BS_KEY_PAY_1177
  }

  return {
    result: !failType,
    failType: failType,
    failReason: null,
    reasonType: 'card_date',
  }
}

const reset = () => {
  expireDateValue.value = ''
  errorTips.value = ''
}

defineExpose({
  validator,
  reset,
  focus: () => baseFieldRef.value.focus(),
})

</script>

<style lang="less">
.payment-card-field {
  padding: 24/75rem;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 24/75rem;
    right: 0;
    height: 1px;
    background-color: var(--sui_color_gray_light2, #e5e5e5);
  }
  &.no-underline {
    &:after {
      display: none;
    }
  }
  .error-invalid {
    font-size: 12px;
    line-height: 1;
    color: var(--sui_color_promo, #FA6338);
    margin-top: 8/75rem;
    transition: height 0.8s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
